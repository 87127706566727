import React from 'react';
import { CardDeck, Col } from 'reactstrap';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Spinner from './Spinner';
import ErrorModal from './ErrorModal';
import MiniProfile from './MiniProfile';
import AuthComponent from './AuthComponent'

const GET_USERS_GQL_QUERY = gql`
  query getUsers($numschi: String!) {
    getUsers(numschiChildren: $numschi){
        numschi
    }
  }
`;

export default class ChildrenProfile extends AuthComponent {

    render() {
        const numschi = this.props.numschi;

        return (
            <div className="ChildrenProfile">
                <Query
                    query={GET_USERS_GQL_QUERY} variables={{ numschi: numschi }}
                    onCompleted={(data) => {
                        console.log("ChildrenProfile onCompleted:")
                        console.log(data.getUsers)
                    }}  >
                    {({ loading, error, data }) => {
                        if (loading) {
                            return (
                                <Spinner />
                            );
                        } else if (error) {
                            return (
                                <ErrorModal error={error} />
                            );
                        } else {
                            console.log("ChildrenProfile render:")
                            console.log(data.getUsers)
                            if (data.getUsers === undefined || data.getUsers.length === 0) {
                                return <div></div>
                            } else {

                                var sortedChildren = data.getUsers.sort(function (a, b) {
                                    let comparison = 0;
                                    let first = a.numschi.replace(/-10-/g, "A");
                                    first = first.replace(/-11-/g, "B");
                                    first = first.replace(/-12-/g, "C");
                                    first = first.replace(/-13-/g, "D");

                                    let second = b.numschi.replace(/-10-/g, "A");
                                    second = second.replace(/-11-/g, "B");
                                    second = second.replace(/-12-/g, "C");
                                    second = second.replace(/-13-/g, "D");

                                    if (first > second) {
                                        comparison = 1;
                                    } else if (first < second) {
                                        comparison = -1;
                                    }
                                    return comparison;
                                });

                                var childrenList = sortedChildren.map(function (child, index) {
                                    return (
                                        <Col sm="12">
                                            <MiniProfile key={child.numschi} numschi={child.numschi} num={index}></MiniProfile>
                                        </Col>
                                    );
                                });

                                return (
                                    <CardDeck>
                                        {childrenList}
                                    </CardDeck>
                                );
                            }
                        }
                    }}
                </Query>
            </div>
        );
    }

}


