import React from 'react';
import {
    Card, CardBody, CardTitle,
    CardText, Col, Row, Container
} from 'reactstrap';
import AuthComponent from '../components/AuthComponent'
import '../styles/LinkPage.css'

export default class LodgingPage extends AuthComponent {

    render() {
        const style = {
            width: '100%',
            height: '100%',
            frameborder: '0',
            border: "0",
            allowfullscreen: true
        }
        return (
            <Container className="LinkPage">
                <h4>ALLOGGI CONSIGLIATI PER IL RADUNO 2020</h4>
                <h6>Le prenotazioni vanno fatte direttamente da chi verrà.
                Allego un un’elenco degli alberghi di Pieve di Soligo e dintorni.
                </h6>
                <Row className="section-panel">
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>Albergo Leon D'Oro</CardTitle>
                                <CardText>Piazza Vittorio Emanuele, 2,
                                <br />31053 Pieve di Soligo TV
                                <br />0438 82036
                                <br /><a href="https://leondoro.wixsite.com/pievedisoligo" 
                                style={{cursor: 'pointer'}} target="_blank" rel="noopener noreferrer">Albergo Leon D'Oro</a></CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <CardBody>
                                <iframe title="leondoro" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2776.5892778393136!2d12.171547750942159!3d45.89952701220836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477917a1f26dd3ed%3A0x83289928d727c188!2sLeon+d&#39;Oro!5e0!3m2!1sen!2sit!4v1556018383696!5m2!1sen!2sit"
                                    style={style}>
                                </iframe>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="section-panel">
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>Airbnb:"Casa Fior", casa intera con 3 posti letto</CardTitle>
                                <CardText>San Fior (TV),
                                <br />circa 20 km da Pieve
                                <br />3 km dal casello dell'autostrada A28
                                <br /><a href="https://www.airbnb.it/rooms/37983563?source_impression_id=p3_1584898984_A4KYI4U83mz8j57X"
                                style={{cursor: 'pointer'}} target="_blank" rel="noopener noreferrer">Casa Fior</a></CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <CardBody>
                                <iframe title="casafior" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d44424.34213675096!2d12.332175206433567!3d45.90088470163408!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47796b3f3fa45fe9%3A0x407098715915ba0!2s31020%20San%20Fior%2C%20Province%20of%20Treviso%2C%20Italy!5e0!3m2!1sen!2sus!4v1584919308914!5m2!1sen!2sus"
                                    style={style}>>
                            </iframe>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="section-panel">
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle> Agriturismo "Le Pervinche"</CardTitle>
                                <CardText>Via Molinetto, 8A,
                                <br />31020 Refrontolo TV
                                <br />+39 347 228 9508
                                <br /><a href="http://www.lepervinche.it/" style={{cursor: 'pointer'}} target="_blank"
                                rel="noopener noreferrer" >lepervinche.it</a></CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <CardBody>
                                <iframe title="pervinche" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2775.244144595692!2d12.198156315398856!3d45.926419979109134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477917243e577b05%3A0x7396892f3064ab6f!2sAgriturismo%20Le%20Pervinche!5e0!3m2!1sen!2sus!4v1584919652034!5m2!1sen!2sus"
                                    style={style}>
                                </iframe>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}


/* Programma

Pernottamenti
Le prenotazioni vanno fatte direttamente da chi verrà; allego un un’elenco degli
alberghi di Pieve di Soligo e dintorni; in quel periodo, i prezzi partono dai 55 euro a
notte.
Nel 2010, per la prima volta, non è stato allestito un campeggio; se a qualcuno
interessasse prego di farmelo sapere nel giro di una settimana; se vi sarà un
numero di interessati sufficiente, verificherò se si può organizzare.
Pranzo
Il pranzo di sabato sarà da Lino a Solighetto; siamo riusciti a mantenere lo stesso
prezzo di cinque e di dieci anni fa, e cioè € 27,00 per gli adulti ed € 17,00 per i
bambini; avrei bisogno di sapere entro maggio quante persone verranno al pranzo.
Piatto ricordo e libro del Clan
Prego di comunicarmi, sempre entro maggio, quante persone desiderano
partecipare al pranzo del 5 settembre e quanti vogliono il piatto ricordo ed il libro
del Clan.
Per libro e piatto il costo dipenderà naturalmente dal numero di prenotazioni
(comunque dovrebbero essere circa 15 euro per il piatto e circa 5 euro per il libro).
Il libro del Clan contiene due sezioni principali: l’albero genealogico ed un
annuario con informazioni e contatti su ciascun nucleo familiare.
Per aggiornare l’albero genealogico ho bisogno che mi vengano comunicate le
variazioni che ci sono state negli ultimi cinque anni; quindi matrimoni e nascite,
ovvero separazioni e decessi, con le date; da quest’anno vorrei inserire nell’albero
genealogico anche le fotografie dei membri; chi desidera che appaia la propria foto
dovrebbe inviarmene una, in qualsiasi formato.
Per aggiornare l’annuario, ho poi bisogno, sempre entro maggio, di conoscere le
novità che riguardano ogni gruppo; per comodità ti invio la scheda dei nuclei
familiari del tuo gruppo come appariva dell'annuario del 2010; per aggiornare ed
integrare i dati avrò bisogno di conoscere:
- la composizione di ogni nucleo familiare convivente e l'indirizzo;
- per i componenti del nucleo, occupazione, eventuali interessi ed i contatti (numeri
di telefono ed indirizzi email od altro).
Da quest’anno, però, nell’annuario ci saranno solo coloro che forniranno dati
per l’aggiornamento o che chiederanno espressamente di essere nell’annuario;
quindi, anche se non ci fosse stata alcuna variazione rispetto al 2010, prego
comunque tutti i capifamiglia (od i singoli) di confermare che vogliono essere
presenti nell’annuario del Clan; se da qualcuno non riceverò nuovi dati o
comunque una richiesta di essere presente nell’annuario, intenderò che
preferisce non esservi e non lo inserirò nell’annuario.
Contatti
Prenotazioni ed informazioni possono essere spedite per posta a questo indirizzo:
Giuseppe Schiratti
piazza Balbi Valier, 5
31053 Pieve di Soligo
oppure inviate per email all'indirizzo:
clanschiratti2015@icloud.com
In ogni caso, potete chiamare al numero 0438 980073 in orario di ufficio. */
