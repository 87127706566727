import React, { Component } from 'react'
import {
    Button
} from 'reactstrap';

export default class TeresinaPolla extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: false
        }
    }
    showButton = () => {
        this.setState({ expanded: !this.state.expanded })
    }
    render() {
        return (
            <div><span>
                <h6>Tratto da una nota dello zio Tita (Giovanni Battista Schiratti)</h6>
                Teresina Schiratti in Polla nacque a Pieve di Soligo il 4 luglio 1896 da Tommaso e
                Maria, sesta di una bella nidiata. <br />
                Fin da bambina mostrò un carattere allegro e generoso, tanto che le dettero il nomignolo di "Schirattina"
                perché possedeva le medesime felici caratteristiche del papà.<br />
                Pur con l'abituale vivacità e allegria, sentì fin dalla prima giovinezza una grande
                attrattiva verso alcuni santi: Santa Teresa del Bambin Gesù, Santa Margherita Maria
                Alacoque e il beato Cottolengo, un innato bisogno di sacrificio e di donare, mettendo cuore
                e attività al servizio del prossimo, anche in piccole cose.<br />
                Sedicenne, il babbo la portò all'educandato delle Suore della Visitazione in San Vito
                al Tagliamento, non per conseguire alcun titolo di studio, ma per ricevere una cultura
                generale, abilità sul ricamo, la lingua francese e sani principi religiosi.<br />
                Teresina vi si trovò così bene che fu attratta dal vivo desiderio di farsi monaca in
                quello stesso educandato conventuale, ed infatti, col consenso dei genitori, ricevette la
                nomina a novizia.<br />
                Ma il Signore aveva disposto ben diversamente! Per Teresina, non la serena vita
                conventuale di preghiera e di umili servizi, ma il matrimonio ed i figli.<br />
                Colpita dunque, da novizia, da alcuni dolori reumatici, il papà credette opportuno
                riportarla per cure in famiglia, ove guarì. S'innamorò allora di un ottimo giovane e nel
                febbraio del 1926, trentenne, andò in sposa, a Pieve di Soligo al sig. Ruggero Polla che
                aveva un'avviata falegnameria a Pieve.<br />
                Anımati come erano i due sposi da una profonda stima reciproca, il loro fu un matrimonio
                felice, perché nutrito di una fede divinamente bella e vissuta, che li traeva spesso al di
                sopra della stessa felicità umana.<br />
                A Pieve nacquero Maria, Giovanni, Ettore ed Agnese; ma poi cominciarono anche
                difficoltà e dolori sempre accettati con fede da Teresa e Ruggero. Ettore a 3 mesi andò in
                cielo. Nel 1934 a causa della crisi economica la falegnameria venne chiusa; gli sposi si
                spostarono a Precotto di San Giovanni, nel milanese, ove frattanto nacquero Margherita,
                Cesarina ed Ester.<br />
                <Button onClick={this.showButton}>leggi il resto..</Button>
                {
                    this.state.expanded &&
                    <div>
                        Le entrate dell'impiego del marito erano modeste, quando fortunatamente questi
                        venne assunto in un posto di responsabilità della Società Motomeccanica di Milano e là si
                        portò con la famiglia.<br />
                        Nella sua Parrocchia a Milano, assunse, nonostante il carico di famiglia, la
                        presidenza delle Dame della San Vincenzo e della Congregazione del S.S.Sacramento e
                        si recava spesso nelle case più povere, vero angelo del Signore.<br />
                        La figlia Ester morì nel 1953 e quasi contemporaneamente morì anche il marito
                        Ruggero.<br />
                        Era continuo stupore dei genitori, dei fratelli, delle amiche e degli amici, vedere
                        Teresa costantemente serena, quasi che ogni pena offerta a Dio, le donasse una gioia
                        sempre più alta.<br />
                        Una parentesi gradita Dio le concesse, con l'eredità di un podere nel Friuli da parte
                        della zia Ginevra Loschi, podere che poi rivendette per gli scarsi redditi.<br />
                        Fu anche una bravissima cuoca e si era specializzata nella raccolta e
                        conservazione dei funghi; aveva organizzato una attività artigianale che le ha permesso di
                        far terminare gli studi ai figli.<br />
                        Il dolore tornò presto a battere a quella porta. Fu un dolore atroce, inenarrabile, il
                        più grande della sua vita: la catastrofe del Vajont del 1963, le rapì in brevi istanti due figlie,
                        due nipoti, un genero e tre cognati!<br />
                        Una cosa straziante che lei seppe ancora una volta offrire a Gesù ma il cuore cominciò a
                        patire immensamente. Colpita da un attacco di paralisi, venne accolta a Villa Mamertino in
                        Val Trompia e dalla sua fedele domestica Rosy. Era tanto commovente l'avanzare lento
                        della carrozzella ogni mattina, tra due ali di persone devote e ammirate, affinché a Teresa
                        fosse concesso per prima il cibo Eucaristico.<br />
                        La morte la colse tra le braccia di Gesù all'ospedale di Brescia il 14 agosto del 1966
                        assistita dai figli Giovanni, Agnese e Cesarina. Fu sepolta a Longarone nella tomba di
                        famiglia.
                    </div>
                }


            </span></div>
        )
    };
}
