import React from 'react';
import {
    Card, CardBody, CardTitle,
    CardText, Container
} from 'reactstrap';
import AuthComponent from '../components/AuthComponent'
import '../styles/LinkPage.css'

export default class InfoPage extends AuthComponent {

    render() {
        return (
            <Container className="LinkPage">
                <h4>DOMANDE FREQUENTI</h4>
                <Card>
                    <CardBody>
                        <CardTitle>Che cosa rappresenta il numero Schiratti e come calcolo il mio?</CardTitle>
                        <CardText>
                            Il numero Schiratti e' composto da uno o piu' numeri. Il primo numero rappresenta, dal piu'
                            vecchio al piu' giovane, il capostipite di prima generazione nato da Tommaso Schiratti e
                            Maria Burei: 1 per Giovan Battista, 2 per Emilia Corra', 3 per Pia Corna Pellegrini,
                            4 per Carolina Sartori, 5 per Teresina Polla e 6 per Giuseppe Schiratti.
                            Di conseguenza i numeri seguenti rappresentano l'ordine di nascita dei figli.<br />
                            Per esempio il numero Schiratti <i>6541</i> rappresenta:<br />
                            6 per Giuseppe Schiratti sesto figlio di Tommaso Schiratti (Antonio Schiratti non viene considerato)<br />
                            5 per Luisa Schiratti in Zonin quinta figlia di Giuseppe Schiratti<br />
                            4 per Francesca Zonin in Bjorge quarta figlia di Luisa Schiratti.<br />
                            1 per Julia Bjorge prima figlia di Francesca Zonin.<br />
                            Ci sono casi particolari dove il numero segue regole in piu':
                            -se sei il 10 figlio aggiungi -10- . Per esempio Cristina Schiratti 6-11- sue figlie 6-11-1 6-11-2
                            -se sei un parente acquisito aggiungi un 0. Per esempio Cleto Zonin 650 perche' Luisa Zonin 65
                            -se sei un primo o secondo partner aggiungi un 0 piu' una lettera dell'alfabeto a cominciare 
                            da 'a' per il primo partner 'b' per il secondo e via dicendo. Per esempio Nives Zanier prima 
                            moglie di Giuseppe Schiratti 6a ed Elena De Vittor (nonna Lina) 6b
                        </CardText>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardTitle>Dove modifico i miei dati inclusa la mia password?</CardTitle>
                        <CardText>
                            Puoi modificare i tuoi dati nel link 'il mio profilo', che si trova nel menu' in alto.
                            Il link naviga in una pagina dove e' visibile la tua foto, i tuoi dati, il tuo partner 
                            e i tuoi figli. Qui puoi caricare la tua foto dal cellulare o dal tuo pc. Puoi modificare 
                            i tuoi dati e la password. Puoi anche cambiare la foto ed i dati del tuo partner e di tutti i tuoi discendenti,
                            ma non dei tuoi predecessori o membri di altre famiglie. Puoi modificare solo la password che appartiene a te.
                            Non puoi modificare il tuo numero Schiratti ne' il tuo nome e cognome. 
                            Non puoi aggiungere un nuovo membro. 
                            Se vuoi aggiungere un nuovo membro acquisito o nascituro, o se vuoi cambiare dati di un tuo
                            predecessore per favore manda un email a Francesca Zonin: francesca@zonin.us
                        </CardText>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardTitle>E se non voglio fare vedere nessuno dei miei dati?</CardTitle>
                        <CardText>
                            Per eliminare tutti i tuoi dati, vai nel tuo profilo e rimuovi ogni dato e poi salva la scheda
                            vuota. Non puoi eliminare un membro. 
                            Per eliminare un membro contatta Francesca Zonin francesca@zonin.us
                        </CardText>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardTitle>Vorrei fare dei suggerimenti sul sito. A chi mi rivolgo?</CardTitle>
                        <CardText>
                            Ogni contributo e' bene accolto. Se vuoi aggiungere dei testi, o delle foto, se vuoi organizzare
                            un piccolo evento, o suggerire un alloggio, per favore contatta Francesca Zonin francesca@zonin.us
                        </CardText>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardTitle>Dove trovo tutte le informazioni pertinenti al raduno 2020?</CardTitle>
                        <CardText>
                            Nell'angolo in alto a destra del sito c'e' un link, 'Raduno 2020'. Qui puoi navigare alla 
                            pagina con links a: tutti gli eventi (data, ora, mappa del luogo), suggerimenti per alloggi,
                            prenotazioni per il pranzo, piatto e libro, ed anche una pagina per offrire il tuo aiuto per 
                            il raduno 2020.
                        </CardText>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}