import { Component } from 'react'
import { AUTH_TOKEN, CURR_NUMSCHI } from '../constants'

export default class AuthComponent extends Component {

    loggedIn() {
        return localStorage.getItem(AUTH_TOKEN) && localStorage.getItem(CURR_NUMSCHI);
    }

    componentWillMount() {
        if (!this.loggedIn()) {
            this.props.history.push("/login");
        }
    }

}


