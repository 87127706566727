
import React from 'react';

import { Formik, Field } from 'formik';

import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { AUTH_TOKEN, CURR_NUMSCHI, IS_ADMIN } from '../constants'
import * as yup from "yup";

import { customTextInput } from "./FormUtil";

import {
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Container,
  Col,
  Row,
  Button,
} from 'reactstrap';

import '../styles/Login.css';
import NonAuthComponent from './NonAuthComponent';

const LOGIN_MUTATION = gql`
  mutation LoginMutation($numschi: String!, $password: String!) {
    login(numschi: $numschi, password: $password) {
      token
      user {
        isAdmin
        numschi
      }
    }
  }
`

const GET_USERS_GQL_QUERY = gql`
    query {
    getUsers {
      numschi
      firstName
      lastName
      year
      marriedYear
      deathYear
      profession
      company
      phone
      email       
      street
      city
      zip
      country
      isAdmin
      photo
      canEdit
    }
}
`;

const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPasswordMutation($numschi: String!) {
    forgotpassword(numschi: $numschi) {
        numschi
        firstName
        lastName
        email
    }
  }
`;

const SIGNUP_MUTATION = gql`
  mutation SignupMutation($numschi: String!, $email: String!) {
    signup(numschi: $numschi, email: $email) {
        numschi
        firstName
        lastName
        email
    }
  }
`

const NumschiYupSchema = yup.object().shape({
  // clan: ^[1-9]+$
  // partner: ^[1-9]+0$
  // multipartner: ^[1-9]+0[A-Za-z]+$
  numschi: yup.string()
    //.matches(/^[1-9]+$|^[1-9]+0$|^[1-9]+0[A-Za-z]+$/, 'numero schiratti invalido')
    .required("inserisci"),
});

const EmailYupSchema = yup.object().shape({
  email: yup.string()
    .email("inserisci email valida")
    .required("inserisci"),
});

const PasswordYupSchema = yup.object().shape({
  password: yup.string()
    .required("inserisci"),
});

export default class Login extends NonAuthComponent {
  constructor(props) {
    super(props);

    this.state = Login.emptyState();
  }

  static emptyState() {
    return {
      currentForm: 'LOGIN', // switch between Login, ForgotPassword and SignUp
      serverMessage: null,
      isError: true, 
    }
  };

  render() {

    const currentForm = this.state.currentForm;


    var theMutation = FORGOT_PASSWORD_MUTATION;
    var theSchema = NumschiYupSchema;
    var formTitle = 'Password Dimenticata';
    if (currentForm === "LOGIN") {
      theMutation = LOGIN_MUTATION;
      theSchema = theSchema.concat(PasswordYupSchema);
      formTitle = 'Accedi';
    } else if (currentForm === "SIGNUP") {
      theMutation = SIGNUP_MUTATION;
      theSchema = theSchema.concat(EmailYupSchema);
      formTitle = 'Registrati';
    }


    return (
      <Container className="Login">
        <Row><Col id="title">{formTitle}</Col></Row>
        <Row>
          <Col>
            <Mutation
              mutation={theMutation}

              onCompleted={data => this._confirm(data)}
              onError={data => this._confirm(data)}
              refetchQueries={[
                { query: GET_USERS_GQL_QUERY, variables: { } },
              ]}
            >
              {(submitMutation, { loading, error }) => {
                return (
                  <Formik
                    initialValues={{
                      numschi: '',
                      password: '',
                      email: '',
                    }}
                    validationSchema={theSchema}
                    onSubmit={(values, { resetForm }) => {
                      console.log("Form onSubmit values:");
                      console.log(values);

                      submitMutation({
                        variables: {
                          numschi: values.numschi,
                          password: values.password,
                          email: values.email,
                        }
                      });
                      // resetForm();
                    }}>
                    {({
                      handleSubmit,
                      handleReset,
                      isValid,
                    }) => (
                        <div>
                          <Form onSubmit={handleSubmit}>
                            <FormGroup>
                              <Label for="numschi">Numero Schiratti</Label>
                              <Field name="numschi" type={'text'} component={customTextInput} />
                            </FormGroup>
                            {currentForm === "LOGIN" && <FormGroup>
                              <Label for="password">Password</Label>
                              <Field name="password" type={'password'} component={customTextInput} />
                            </FormGroup>}
                            {currentForm === "SIGNUP" && <FormGroup>
                              <Label for="email">Email</Label>
                              <Field name="email" type={'email'} component={customTextInput} />
                            </FormGroup>}
                            <FormGroup>
                              <Button color="primary" type='submit' disabled={!isValid}>Invia</Button>
                            </FormGroup>
                            <FormGroup>
                              { this.state.serverMessage &&
                                <FormFeedback style={{ display: 'block' }} valid={!this.state.isError}>{this.state.serverMessage}</FormFeedback>}
                            </FormGroup>
                          </Form>
                          {currentForm !== 'SIGNUP' && <div
                            className="pointer button"
                            onClick={() => {
                              handleReset(); this.setState({
                                currentForm: currentForm === 'LOGIN' ? 'FORGOT_PASSWORD' : 'LOGIN',
                                serverMessage: null,
                              });
                            }}
                          >
                            {currentForm === 'LOGIN'
                              ? 'Password dimenticata?'
                              : 'Password conosciuta?'}
                          </div>}
                          <div
                            className="pointer button"
                            onClick={() => {
                              handleReset(); this.setState({
                                currentForm: currentForm === 'SIGNUP' ? 'LOGIN' : 'SIGNUP',
                                serverMessage: null,
                              });
                            }}
                          >
                            {currentForm === 'SIGNUP'
                              ? 'Hai gia acceso?'
                              : 'Prima volta che accedi?'}
                          </div>
                        </div>)}
                  </Formik>);
              }}
            </Mutation>
          </Col>
        </Row>
      </Container>);
  }

  _confirm = async data => {
    if (data === null) {
      this.setState({ serverMessage: "Errore di comunicazione", isError: true });
    } else {
      console.log("_confirm data:");
      console.log(data);
      if (data.message != null) {
        // display error message
        data.message = data.message.replace('GraphQL error:', '').trim()
        this.setState({ serverMessage: data.message, isError: true });
      } else if (data.forgotpassword) {
        // process forgot password response
        let msg = "Una email e' stata spedita all'indirizzo " + data.forgotpassword.email + " con una password temporanea.\n" +
          "Usa questa password per accedere al sito. Ricordati di cambiarla quando modificherai il tuo profilo."
        this.setState({ serverMessage: msg, isError: false });
      } else if (data.login) {
        // process login response
        localStorage.setItem(IS_ADMIN, data.login.user.isAdmin)
        localStorage.setItem(AUTH_TOKEN, data.login.token)
        localStorage.setItem(CURR_NUMSCHI, data.login.user.numschi)
        this.props.history.push('/home');
      } else if (data.signup) {
        // process signup (register) response
        let info = "Un'email e' stata spedita all'indirizzo " + data.signup.email +
          ".\nNel messaggio troverai le istruzioni per finire di registrarti."
        // this.setState({ login: true });
        this.setState({ serverMessage: info, isError: false });
      }
    }
  }

}
