import React from 'react';
import {
    Button, Form, FormGroup, Label, Container, Modal, ModalHeader, ModalBody,
} from 'reactstrap';

import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import Spinner from './Spinner';
import ErrorModal from './ErrorModal';
import AuthComponent from './AuthComponent';
import '../styles/UpdateUser.css'
import * as yup from "yup";
import { Formik, Field } from 'formik';
import { customTextInput } from "./FormUtil";
import { GET_ALL_USERS_GQL_QUERY } from "./Queries";

const CREATE_USER_GQL_MUTATION = gql`
mutation CreateUser(
    $numschi: String!
    $email: String!
    $firstName: String!
    $lastName: String!) {
        createUser(numschi:$numschi,email:$email,firstName:$firstName,lastName:$lastName) {
            numschi
            firstName
            lastName
            email
        }
    }
`;

const YupSchema = yup.object().shape({
    numschi: yup.string()
        .min(2, "Too Short!")
        .max(15, "Too Long!")
        .required("Required"),
    firstName: yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    lastName: yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
    email: yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .email("valid email required"),
});

export default class CreateUserForm extends AuthComponent {
    constructor(props) {
        super(props);

        this.exit = this.exit.bind(this);

    }


    exit() {
        this.props.exitModal();
    }

    render() {
        const showform = this.props.showform ? true : false;

        return (
            <Modal isOpen={showform} toggle={this.exit}>
                <ModalHeader toggle={this.exit}>Crea un Membro</ModalHeader>
                <ModalBody>

                    <Mutation mutation={CREATE_USER_GQL_MUTATION}
                        onCompleted={(data) => {
                            console.log("the result:")
                            console.log(data)
                            this.exit();
                        }}
                        refetchQueries={[
                            { query: GET_ALL_USERS_GQL_QUERY, variables: {} },
                        ]}
                    >
                        {(SaveUser, { loading, error }) => {
                            if (loading) return (<Spinner />);
                            return (
                                <Container>
                                    {error && <ErrorModal error={error} />}
                                    <Formik
                                        initialValues={{
                                            numschi: "",
                                            firstName: "",
                                            lastName: "",
                                            email: "",
                                        }}
                                        validationSchema={YupSchema}
                                        onSubmit={(values, { resetForm }) => {
                                            console.log("Form onSubmit values:");
                                            console.log(values);

                                            SaveUser({
                                                variables: {
                                                    numschi: values.numschi,
                                                    firstName: values.firstName,
                                                    lastName: values.lastName,
                                                    email: values.email,
                                                }
                                            });
                                            // resetForm();
                                        }}>
                                        {({
                                            handleSubmit,
                                            handleReset,
                                            isValid,
                                            dirty,
                                        }) => (
                                                <Form onSubmit={handleSubmit}>
                                                    <FormGroup>
                                                        <Label for="numschi">Numschi</Label>
                                                        <Field name="numschi" type={'text'} component={customTextInput} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="firstName">Nome</Label>
                                                        <Field name="firstName" type={'text'} component={customTextInput} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="lastName">Cognome</Label>
                                                        <Field name="lastName" type={'text'} component={customTextInput} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="email">Email</Label>
                                                        <Field name="email" type={'email'} component={customTextInput} />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <div className="text-center">
                                                            <Button color="secondary" type='submit' disabled={!isValid || !dirty}>Modifica</Button>
                                                        </div>
                                                    </FormGroup>
                                                </Form>)}
                                    </Formik>
                                </Container>
                            );
                        }}
                    </Mutation>
                </ModalBody>
            </Modal>
        );

    }
}


