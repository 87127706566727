import React, { Component } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    Popover, PopoverHeader, PopoverBody,
    Input, ListGroup, ListGroupItem, Label
} from 'reactstrap';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Spinner from '../components/Spinner';
import ErrorModal from '../components/ErrorModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes, faToolbox, faQuestion } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faWhatsapp, faFacebookMessenger, faSkype } from "@fortawesome/free-brands-svg-icons"
import { NavLink, Link } from 'react-router-dom'
import '../styles/Header.css';
import logo from '../images/icon.png'
import { AUTH_TOKEN, CURR_NUMSCHI, IS_ADMIN } from '../constants'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import PhoneNumber from 'react-phone-number';

const GET_ALL_USERS_GQL_QUERY = gql`
    query {
    getUsers {
        numschi
        firstName
        lastName
        year
        marriedYear
        deathYear
        profession
        company
        phone
        email       
        street
        city
        zip
        country
        isAdmin
        photo
        canEdit
    }
}
`;

class Header extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.toggleP1 = this.toggleP1.bind(this);
        this.toggleP2 = this.toggleP2.bind(this);
        this.state = {
            isOpen: false,
            isP1Open: false,
            isP2Open: false,
            numschi: "",
            list: [],
            filtered: [],
            searchWord: "",
            isSearchOn: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggleSearch = this.toggleSearch.bind(this);
        this.resetSearch = this.resetSearch.bind(this);
        this.processSearch = this.processSearch.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.loginCheck = this.loginCheck.bind(this);
    }

    handleChange(event) {
        let currentList = [];
        let newList = [];
        this.state.searchWord = event.target.value;

        if (this.state.searchWord !== "") {
            currentList = this.state.list;
            newList = currentList.filter(item => {
                if (this.processSearch(item, this.state.searchWord) === true) {
                    return item.firstName + ' ' + item.lastName;
                } else {
                    return "";
                }
            });
        } else {
            newList = [];
        }
        this.setState({
            filtered: newList,
        });
    }

    processSearch(item, word) {
        //search in the names and last names or 10th child
        if (isNaN(word)) {
            //it can be the 10th child
            if (word.indexOf('-') !== -1) {
                if (item.numschi === word) {
                    return true;
                }
            }
            //test if second partner
            if (isNaN(word[0]) === false && isNaN(word[word.length - 1]) === true) {
                if (item.numschi === word) {
                    return true;
                }
            }
            //it is the first name
            if (item.firstName.toLowerCase() === word.toLowerCase()) {
                return true;
            }
            //it is the last name
            if (item.lastName.toLowerCase() === word.toLowerCase()) {
                return true;
            }
        } else {
            //it is the numschi
            if (item.numschi === word) {
                return true
            }
        }
        return false;
    }



    resetSearch(event) {
        this.setState({
            isOpen: false,
            isP1Open: false,
            isP2Open: false,
            numschi: "",
            list: [],
            filtered: [],
            searchWord: "",
            isSearchOn: false
        });
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    toggleP1() {
        this.setState({
            isP1Open: !this.state.isP1Open
        });
    }
    toggleP2() {
        this.setState({
            isP2Open: !this.state.isP2Open
        });
    }
    toggleSearch() {
        this.setState({
            isSearchOn: !this.state.isSearchOn
        });
    }

    handleLogout() {
        console.log("handleLogout, clear cookies and localstorage")
        this.props.cookies.remove("jsauth", {});
        localStorage.clear();
    }

    loginCheck() {
        console.log("render, loginCheck");
        let sessionCookie = this.props.cookies.get('jsauth');
        if (sessionCookie === undefined) {
            console.log("session cookie is undefined, go to /login page");
            localStorage.clear();
            return false;
        }
        // console.log("sessionCookie:");
        // console.log(sessionCookie);

        var json = atob(sessionCookie);
        console.log("session json from server:")
        console.log(json);

        var value = JSON.parse(json);
        // console.log(value);

        var expiration = new Date(value.expiration);
        // console.log(expiration.toLocaleString());

        var now = new Date();
        // console.log(now.toLocaleString());

        if (expiration < now) {
            console.log("session cookie has expired, go to /login page");
            localStorage.clear();
            return false;
        }

        localStorage.setItem(AUTH_TOKEN, sessionCookie);
        localStorage.setItem(CURR_NUMSCHI, value.numschi);
        localStorage.setItem(IS_ADMIN, value.isAdmin);

        return true;
    }

    render() {

        // check if logged in
        var loggedInNow = this.loginCheck();

        // console.log("header get data is admin");
        const isAdmin = localStorage.getItem(IS_ADMIN);

        return (
            <div className="Header">
                <Navbar color="inverse" light expand="md" >
                    <NavbarBrand href="/home" >
                        <span>
                            <img className="Logo" src={logo} alt="logo" />
                        </span>
                        {/* <span>Clan Schiratti</span> */}
                    </NavbarBrand>
                    {loggedInNow &&
                        <NavbarToggler onClick={this.toggle} />
                    }
                    {loggedInNow &&
                        (<Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="ml-auto" navbar>
                                <NavItem>
                                    <NavLink to={`/profile/${localStorage.getItem(CURR_NUMSCHI)}`}>Il mio profilo</NavLink>
                                </NavItem>
                                <NavItem className="navitem-right-tools">
                                    {(this.state.isSearchOn === false) && (<Label className="SearchNavLink" onClick={this.toggleSearch} ><FontAwesomeIcon icon={faSearch} /></Label>)}
                                    {(this.state.isSearchOn === true) && (
                                        <Query
                                            query={GET_ALL_USERS_GQL_QUERY}
                                            onCompleted={(data) => {
                                                console.log("Search user onCompleted");
                                                console.log(data);
                                            }}  >
                                            {({ loading, error, data }) => {
                                                if (loading) {
                                                    return (
                                                        <Spinner />
                                                    );
                                                } else if (error) {
                                                    return (
                                                        <ErrorModal error={error} />
                                                    );
                                                }
                                                console.log("Search user data")
                                                if (data.getUsers === undefined || (data.getUsers !== undefined && data.getUsers.length === 0)) {
                                                    this.state.list = []
                                                    return (<div></div>);
                                                } else {
                                                    this.state.list = JSON.parse(JSON.stringify(data.getUsers));

                                                    return (
                                                        <NavLink to="#" >
                                                            <inputGroup className="SearchNavLink">
                                                                <Input onChange={(e) => { this.handleChange(e) }} type="text" name="searchWord" id="searchWord"
                                                                    placeholder="Cerca persona" value={this.state.searchWord} />
                                                                <Label onClick={this.toggleSearch} ><FontAwesomeIcon icon={faTimes} /></Label>
                                                            </inputGroup>
                                                            <ListGroup>
                                                                {this.state.filtered.map(item => (
                                                                    <Link to={`/profile/${item.numschi}`} onClick={(e) => { this.resetSearch(e) }} >
                                                                        <ListGroupItem key={item.numschi}>
                                                                            {item.firstName} {item.lastName}
                                                                        </ListGroupItem>
                                                                    </Link>
                                                                ))}

                                                            </ListGroup>
                                                        </NavLink>
                                                    )
                                                }
                                            }}
                                        </Query>
                                    )}
                                </NavItem>
                                <NavItem>
                                    <NavLink to="/information">
                                        <FontAwesomeIcon icon={faQuestion} />                           
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="#" id="contattaci" onClick={this.toggleP1}>Contattaci
                                        <Popover placement="bottom" isOpen={this.state.isP1Open} target="contattaci" toggle={this.toggleP1}>
                                            <PopoverHeader>Contatta Francesca Zonin</PopoverHeader>
                                            <PopoverBody>
                                                <NavItem>
                                                    <span>al numero </span>
                                                    <PhoneNumber number="+14083073223" isLinked='true' />
                                                    <span> in </span>
                                                    <FontAwesomeIcon icon={faWhatsapp}></FontAwesomeIcon>
                                                    <span> o </span>
                                                    <FontAwesomeIcon icon={faSkype}></FontAwesomeIcon>
                                                </NavItem>
                                                <NavItem>
                                                    <span> fai un post su </span>
                                                    <a href="https://www.facebook.com/groups/2540757379517112/" >
                                                        <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
                                                    </a>
                                                </NavItem>
                                                <NavItem>
                                                    <span> manda un messaggio su </span>
                                                    <a href="https://www.facebook.com/francesca.zonin" >
                                                        <FontAwesomeIcon icon={faFacebookMessenger}></FontAwesomeIcon>
                                                    </a>
                                                </NavItem>
                                            </PopoverBody>
                                        </Popover>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="#" id="chi_siamo" onClick={this.toggleP2}>Chi siamo
                                    <Popover placement="bottom" isOpen={this.state.isP2Open} target="chi_siamo" toggle={this.toggleP2}>
                                            <PopoverHeader>Chi siamo</PopoverHeader>
                                            <PopoverBody>
                                                Siamo la famiglia Schiratti originari di Pieve di Soligo.
                                                Siamo tutti i discendenti dei sei fratelli e sorelle Schiratti e ci incontriamo ogni cinque anni.
                                                Ci piace tenerci in contatto ed e' per questo che abbiamo creato questo sito.
                                                <br />
                                                versione: 1.3
                                        </PopoverBody>
                                        </Popover>
                                    </NavLink>
                                </NavItem>                              
                                {isAdmin === 'true' &&
                                    (<NavItem>
                                        <NavLink to="/toolbox"><FontAwesomeIcon icon={faToolbox} /></NavLink>
                                    </NavItem>)}
                                <NavItem>
                                    <NavLink to="/" onClick={this.handleLogout} >Disconnetti</NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>)
                    }
                </Navbar>
            </div>
        );
    }
}
export default withCookies(Header);

