import React from 'react';
import {
  Container, Card, CardTitle, 
  CardImg, CardBody,
  Col, Row, CardText
} from 'reactstrap';

import AuthComponent from '../components/AuthComponent'
import '../styles/LinkPage.css'
import primoAlbero from '../images/raduniPassati/primoAlberoGenealogico1960.jpg';
import raduno1960 from '../images/raduniPassati/raduno1960.jpg';
import raduno1965_1 from '../images/raduniPassati/raduno1965.jpg';
import raduno1965_2 from '../images/raduniPassati/varie1.jpg';
import raduno1965_3 from '../images/raduniPassati/varie2.jpg';
import raduno1970_1 from '../images/raduniPassati/raduno1970.jpg';
import raduno1970_2 from '../images/raduniPassati/varie10.jpg';
import raduno1975 from '../images/raduniPassati/raduno1975.jpg';
import raduno1980_1 from '../images/raduniPassati/raduno1980.jpg';
import raduno1980_2 from '../images/raduniPassati/varie3.jpg';
import raduno1980_3 from '../images/raduniPassati/varie4.jpg';
import raduno1985_2 from '../images/raduniPassati/varie5.jpg';
import raduno1985_3 from '../images/raduniPassati/varie6.jpg';
// import raduno1990 from '../images/raduniPassati/raduno1990.jpg';
import raduno1995_1 from '../images/raduniPassati/raduno1995.jpg';
import raduno1995_2 from '../images/raduniPassati/varie7.jpg';
import raduno1995_3 from '../images/raduniPassati/varie8.jpg';
import raduno1995_4 from '../images/raduniPassati/varie9.jpg';
import raduno2015 from '../images/raduniPassati/raduno2015.jpg';
// import raduno2010 from '../images/raduniPassati/raduno2010.jpg';
import raduno2005 from '../images/raduniPassati/raduno2005.jpg';
import raduno2000 from '../images/raduniPassati/raduno2000.jpg';

export default class PastPage extends AuthComponent {
  render() {
    return (
      <Container className="LinkPage">
        <h4>RADUNI PASSATI</h4>
        <h6>Alcune Notizie sull'inizio del Clan Schiratti di Ugo Corrà</h6>
        <Row className="section-panel">
            <Col>
                <CardImg top width="100%" height="100%" src={primoAlbero} alt="Primo Raduno" />
            </Col>
            <Col>
                <Card>
                    <CardBody>
                        <CardText> 
Il primo contatto avuto con lo Zio Beppi fu nell'Agosto del 1958.  In quell'anno lavoravo presso la GTO di Zurigo, e da pochi giorni mi ero sposato con Nanny. 
Tra un lavoro ed un altro ero riuscito a far ritorno a Pieve di Soligo. L'idea mi aveva subito affascinato, anche se c'era da mettere in conto un non indifferente 
sforzo organizzativo. Immediatamente mettemmo al corrente della cosa lo Zio Tita, che ai quei tempi era venuto nel Veneto da Torino. 
Anche lui fu subito entusiasta del progetto e così i due fratelli intrapresero i primi propositi accollandosi anche le prime spese.<br /> 
La prima proposta dello Zio Tita fu di organizzarlo a Monza, nel giardino della Villa Reale per essere più centrali per i parenti che abitavano a Milano e 
nel resto della Lombardia. Per fortuna lo Zio Beppi resistette a questa ipotesi, lui volle fermamente che il raduno si svolgesse a Pieve.<br /> 
Il primo scoglio fu quello di dare un nome al raduno. Non si riusciva a trovare qualcosa di semplice ed immediato. L'intuizione venne alla Zia Emilietta Salvioni 
che propose la parola "Clan”. Suo fu anche il testo che compare all'inizio del libro del Clan - GLI ANTENATI - <br />
Lo Zio Tita aiutato dalla moglie di Paolo, Maria Grazia, presentò il primo Albero Genealogico, scritto a macchina su carta lucida, con uno bel disegno di uno 
scoiattolo, che divenne il simbolo della famiglia.<br /> 
Era un documento difficile da interpretare e lo Zio Beppi me lo fece rifare. Ne allego una copia del testo distribuito ai partecipanti.<br />  
A quel tempo il numero totale del Clan era di circa cento persone.<br /> 
Il primo pranzo fu fatto nella terrazza giardino dell'Hotel Leon D'Oro in Piazza a Pieve. Le presenze al Pranzo sono state circa 65 persone.<br />  
Anche nei successivi Clan si faceva l'Albero Genealogico a mano, però lo Zio Beppi già dal 1970, mi fece fare il libro del Clan dove dopo le prime pagine con 
“GLI ANTENATI”, faceva seguito: - le foto dei Nonni Tommaso e Maria - le foto di Nonna Maria con i 40 nipoti - la foto dei sei Capostipiti - gli Alberi Genealogici 
per famiglia. Ma la cosa che più interessava allo Zio Beppi era l'ANNUARIO. Questo era un testo che riportava notizie dettagliate su ogni singolo componente delle 
numerose famiglie (fatta eccezione per i più piccoli). Queste informazioni personali venivano aggiornate ad ogni successivo Clan fino al Clan del 2005, quando ho 
ceduto la staffetta ad un giovane Schiratti, Giuseppe.<br /> 
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        <br />
        <h6>Collezione di foto di raduni passati</h6>
        <Card>
          <CardBody>
            <CardTitle>Raduno Clan Schiratti 1960</CardTitle>
            <CardImg top src={raduno1960} alt="Raduno 1960" />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle>Raduno Clan Schiratti 1965</CardTitle>
            <CardImg top src={raduno1965_1} alt="Raduno 1965" />
            <CardImg top src={raduno1965_2} alt="Raduno 1965" />
            <CardImg top src={raduno1965_3} alt="Raduno 1965" />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle>Raduno Clan Schiratti 1970</CardTitle>
            <CardImg top src={raduno1970_1} alt="Raduno 1970" />
            <CardImg top src={raduno1970_2} alt="Raduno 1970" />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle>Raduno Clan Schiratti 1975</CardTitle>
            <CardImg top src={raduno1975} alt="Raduno 1975" />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle>Raduno Clan Schiratti 1980</CardTitle>
            <CardImg top src={raduno1980_1} alt="Raduno 1980" />
            <CardImg top src={raduno1980_2} alt="Raduno 1980" />
            <CardImg top src={raduno1980_3} alt="Raduno 1980" />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle>Raduno Clan Schiratti 1985</CardTitle>
            <CardText>Cugini Primi</CardText>
            <CardImg top src={raduno1985_2} alt="Raduno 1985" />
            <CardImg top src={raduno1985_3} alt="Raduno 1985" />
          </CardBody>
        </Card>
        {/* <Card>
          <CardBody>
            <CardTitle>Raduno Clan Schiratti 1990</CardTitle>
            <CardImg top src={raduno1990} alt="Raduno 1990" />
          </CardBody>
        </Card> */}
        <Card>
          <CardBody>
            <CardTitle>Raduno Clan Schiratti 1995</CardTitle>
            <CardImg top src={raduno1995_1} alt="Raduno 1995" />
            <CardText>Famiglia Pia Schiratti in Corna Pellegrini</CardText>
            <CardImg top src={raduno1995_2} alt="Raduno 1995" />
            <CardText>Cugini Primi</CardText>
            <CardImg top src={raduno1995_3} alt="Raduno 1995" />
            <CardText>Gruppo Acquisiti</CardText>
            <CardImg top src={raduno1995_4} alt="Raduno 1995" />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle>Raduno Clan Schiratti 2000</CardTitle>
            <CardImg top src={raduno2000} alt="Raduno 2000" />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle>Raduno Clan Schiratti 2005</CardTitle>
            <CardImg top src={raduno2005} alt="Raduno 2005" />
          </CardBody>
        </Card>
         {/* <Card>
          <CardBody>
            <CardTitle>Raduno Clan Schiratti 2010</CardTitle>
            <CardImg top src={raduno2010} alt="Raduno 2010" />
          </CardBody>
        </Card> */}
        <Card>
          <CardBody>
            <CardTitle>Raduno Clan Schiratti 2015</CardTitle>
            <CardImg top src={raduno2015} alt="Raduno 2015" />
          </CardBody>
        </Card>
        
      </Container>
    );
  }
}