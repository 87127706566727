import React from 'react';
import AuthComponent from '../components/AuthComponent'

export default class Preghiera extends AuthComponent {

    render() {
        return (
            <div className="pray-text">
                Signore Iddio onnipotente, Ti ringraziamo.<br />
                Ti ringraziamo per le grazie che ci hai concesso.<br />
                Ti ringraziamo anche per le prove alle quali hai voluto sottoporci
                nel Tuo amore.<br />
                Signore, Ti ringraziamo per averci permesso di poterci riunire qui oggi,
                in letizia, e di poter ricordare i nostri cari che ci hanno preceduto
                nel Tuo Regno, al quale tutti aspiriamo e speriamo.<br />
                Tieni la tua mano sul capo di questi bambini, virgulti del nostro ceppo,
                tanto cari al nostro cuore.<br />
                Fa che le loro orme sulla terra, siano di bontà e di fede;
                fa che mai lo spirito del male abbia a regnare in loro.<br />
                Benedici tutti o Signore, restaci accanto nella Tua bontà infinita.<br />
                Così sia.
            </div>
        );
    }
}