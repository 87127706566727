import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class AnnounceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: true
        };

        this.toggle = this.toggle.bind(this);
        this.setVisible = this.setVisible.bind(this);
        this.isDone = this.isDone.bind(this);
    }
    setVisible() {
        let pop_status = localStorage.getItem('pop_status');
        if (!pop_status) {
            localStorage.setItem('pop_status', 1);
        }
    }

    isDone() {
        let done = localStorage.getItem('pop_status');
        return done;
    }

    toggle() {
        this.setVisible();
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {
        if (this.isDone()) return null;
        const externalCloseBtn = <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.toggle}>&times;</button>;
        return (
            <div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} external={externalCloseBtn}>
                    <ModalHeader>{this.props.title}</ModalHeader>
                    <ModalBody>
                        {this.props.content}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}