import React from 'react'
import AuthComponent from '../components/AuthComponent'
import {
    Container, Card, CardBody,
    CardTitle, CardSubtitle
} from 'reactstrap';
import '../styles/Statistics.css';

import { VictoryBar, VictoryChart, VictoryAxis, VictoryTheme, VictoryGroup, VictoryLabel } from 'victory';

const ancestors = ['Tita', 'Emilia', 'Pia', 'Carolina', 'Teresina', 'Giuseppe']

export default class StatsFvsM extends AuthComponent {

    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            ancestor: [],
            oneFemale: 0,
            oneMale: 0,
            twoFemale: 0,
            twoMale: 0,
            threeFemale: 0,
            threeMale: 0,
            fourFemale: 0,
            fourMale: 0,
            fiveFemale: 0,
            fiveMale: 0,
            sixFemale: 0,
            sixMale: 0,
        };

        this.processData(this.props.users)
    }

    processData(users) {
        for (var i = 0; i < users.length; i++) {

            let ancestor = users[i].numschi[0]

            switch (ancestor) {
                case "1":
                    users[i].female ? this.state.oneFemale++ : this.state.oneMale++
                    break;
                case "2":
                    users[i].female ? this.state.twoFemale++ : this.state.twoMale++
                    break;
                case "3":
                    users[i].female ? this.state.threeFemale++ : this.state.threeMale++
                    break;
                case "4":
                    users[i].female ? this.state.fourFemale++ : this.state.fourMale++
                    break;
                case "5":
                    users[i].female ? this.state.fiveFemale++ : this.state.fiveMale++
                    break;
                case "6":
                    users[i].female ? this.state.sixFemale++ : this.state.sixMale++
                    break;
                default:
                    break;
            }
        }

    }

    render() {
        const { oneFemale, oneMale, twoFemale, twoMale, threeFemale, threeMale, fourFemale, fourMale, fiveFemale, fiveMale, sixFemale, sixMale } = this.state
        return (
            <Container className="Statistics">
                <Card>
                    <CardBody>
                        <CardTitle>Quanti uomini e quante donne nelle varie famiglie?</CardTitle>
                        <CardSubtitle>Distribuzione di donne e uomini nelle sei discendenze</CardSubtitle>
                        <VictoryChart domainPadding={20} theme={VictoryTheme.material}

                        >
                            <VictoryGroup offset={15}>
                                <VictoryBar name="area-F"
                                    data={[{ x: 1, y: oneFemale }, { x: 2, y: twoFemale }, { x: 3, y: threeFemale },
                                    { x: 4, y: fourFemale }, { x: 5, y: fiveFemale }, { x: 6, y: sixFemale }]}
                                    color="pink"
                                    labels={({ datum }) => datum.y}
                                    style={{ labels: {fill: "red", fontSize: 8} }}
                                    labelComponent={<VictoryLabel dy={15} />}
                                    alignment={"middle"}
                                    barWidth={15}
                                />
                                <VictoryBar name="area-M"
                                    data={[{ x: 1, y: oneMale }, { x: 2, y: twoMale }, { x: 3, y: threeMale },
                                    { x: 4, y: fourMale }, { x: 5, y: fiveMale }, { x: 6, y: sixMale }]}
                                    color="lightblue"
                                    labels={({ datum }) => datum.y}
                                    style={{labels: {fill: "blue", fontSize: 8} }}
                                    labelComponent={<VictoryLabel dy={15} />}
                                    alignment={"middle"}
                                    barWidth={15}
                                />
                            </VictoryGroup>
                            <VictoryAxis
                                label="Capostipiti"
                                tickValues={[1, 2, 3, 4, 5, 6]}
                                tickFormat={ancestors}
                                style={{
                                    axisLabel: {fontSize: 12, padding: 30}, 
                                    tickLabels: {fontSize: 8, padding: 5}}                                     
                                }
                            />
                            <VictoryAxis
                                dependentAxis
                                label="Numero di Persone"
                                style={{
                                    axisLabel: {fontSize: 12, padding: 30}, 
                                    tickLabels: {fontSize: 8, padding: 5}}                                      
                                }
                            />
                        </VictoryChart>
                    </CardBody>
                </Card>
            </Container>
        )
    }
}