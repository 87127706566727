import React from 'react'
import {
    Row, Col, UncontrolledCarousel,
    Button, CardImg, Jumbotron
} from 'reactstrap';
import { Link } from 'react-router-dom'
import AuthComponent from './AuthComponent'
import AnnounceModal from './AnnounceModal'
import '../styles/Home.css'
import crest from '../images/crest.png';
import fratelli from '../images/fratelliSchiratti.png';
import famosi from '../images/famosi.jpg';
import albero from '../images/albero.png';
import statistiche from '../images/statistiche.png';

const items = [
    {
        id: 1,
        src: '../images/raduniPassati/raduno1960.jpg',
        caption: 'Raduno 1960'
    },
    {
        id: 2,
        src: '../images/raduniPassati/raduno1965.jpg',
        caption: 'Raduno 1965'
    },
    {
        id: 3,
        src: '../images/raduniPassati/raduno1970.jpg',
        caption: 'Raduno 1970'
    },
    {
        id: 4,
        src: '../images/raduniPassati/raduno1975.jpg',
        caption: 'Raduno 1975'
    },
    {
        id: 5,
        src: '../images/raduniPassati/raduno1980.jpg',
        caption: 'Raduno 1980'
    },
    // {
    //     id: 6,
    //     src: '../images/raduniPassati/raduno1985.jpg',
    //     caption: 'Raduno 1985'
    // },
    // {
    //     id: 7,
    //     src: '../images/raduniPassati/raduno1990.jpg',
    //     caption: 'Raduno 1990'
    // },
    {
        id: 8,
        src: '../images/raduniPassati/raduno1995.jpg',
        caption: 'Raduno 1995'
    },
    {
        id: 9,
        src: '../images/raduniPassati/raduno2000.jpg',
        caption: 'Raduno 2000'
    },
    {
        id: 10,
        src: '../images/raduniPassati/raduno2005.jpg',
        caption: 'Raduno 2005'
    },
    // {
    //     id: 11,
    //     src: '../images/raduniPassati/raduno2010.jpg',
    //     caption: 'Raduno 2010'
    // },
    {
        id: 12,
        src: '../images/raduniPassati/raduno2015.jpg',
        caption: 'Raduno 2015'
    }
];

class Home extends AuthComponent {

    render() {

        return (

            <div className='Home' >
                <AnnounceModal
                    title="Attenzione! Raduno 2020 rimandato al 2021!"
                    content="Carissimi, 
mi dispiace dover annunciare che il Raduno 2020 verrà rimandato al 2021. 
Possiamo immaginare i vari problemi che si creeranno qualora avessimo il raduno quest'anno.
Per esempio non possiamo essere certi che legalmente potremo avere un raduno di più di 200 persone.
Inoltre non sappiamo se il ristornante Locanda Da Lino avrà lo spazio necessario per accomodarci tutti rispettando le distanze.
Fino a che non saremo tutti vaccinati non sara' possibile avere un vero raduno. 
Avere un raduno dove non potremo abbracciarci, baciarci e tenerci per mano fa tanta tristezza.
Spero che stiate tutti bene ed in salute. Vi mando un grande abbraccio virtuale che sarà 
sicuramente in persona il prossimo anno al Raduno 2021.">
                </AnnounceModal>
                <Row className="titleJumbotron" nogutters="true">
                    <Jumbotron>
                        <h1 className="display-3">Clan Schiratti</h1>
                        <p className="lead">Fondato nel 1960</p>
                        <hr className="my-2" />
                        <p>Questo e' il sito di tutti i discendenti della famiglia Tommaso Schiratti e Maria Burei</p>
                        <p className="lead">Riscopri le tue origini</p>
                    </Jumbotron>
                </Row>
                <Row className="section-panel" nogutters="true">
                    <Col className="image-panel" nogutters="true">
                        <CardImg top src={crest} alt="Family Crest" />
                    </Col>
                    <Col className="info-panel" nogutters="true">
                        <div className="inner-text">
                            <h4 className="title">I nostri avi</h4>
                            <div className="text">
                                <p>Da dove si e' originata la famiglia Schiratti?
                                        Chi erano i genitori dei nostri nonni e le loro famiglie?</p>
                                <p>In questa pagina troverai documenti, foto e alberi di famiglia dei nostri predecessori.</p>
                            </div>
                            <Button><Link to="/ancestors" className="inactive">Scopri</Link></Button>
                        </div>
                    </Col>
                </Row>
                <Row className="section-panel section-reverse">
                    <Col className="image-panel" nogutters="true">
                        <CardImg top src={fratelli} alt="I Fratelli Schiratti" />
                    </Col>
                    <Col className="info-panel" nogutters="true">
                        <div className="inner-text">
                            <h4 className="title">Gli antenati: fratelli e sorelle Schiratti</h4>
                            <div className="text">
                                <p>Chi erano i nostri nonni, figli di Tommaso Schiratti e Maria Burei?</p>
                                <p>In questa pagina li riconosciamo attraverso foto, memorie, elogi e articoli
                                di giornali.
                                </p>
                            </div>
                            <Button><Link to="/grandparents" className="inactive">Scopri</Link></Button>
                        </div>
                    </Col>
                </Row>
                <Row className="section-panel" nogutters="true">
                    <Col className="image-panel" nogutters="true">
                        <CardImg top src={famosi} alt="Personaggi Famosi" />
                    </Col>
                    <Col className="info-panel" nogutters="true">
                        <div className="inner-text">
                            <h4 className="title">Personaggi Famosi</h4>
                            <div className="text">
                                <p>Alcuni tra i nostri avi di animo generoso dedicarono la vita alle loro passioni,
                                diventando personaggi famosi della storia italiana.</p>
                            </div>
                            <Button><Link to="/famouspeople" className="inactive">Scopri</Link></Button>
                        </div>
                    </Col>
                </Row>
                <Row className="section-panel section-reverse" nogutters="true">
                    <Col className="image-panel" nogutters="true">
                        <CardImg top src={albero} alt="Albero di Famiglia" />
                    </Col>
                    <Col className="info-panel" nogutters="true">
                        <div className="inner-text">
                            <h4 className="title">L'Albero di Famiglia</h4>
                            <div className="text">
                                <p>Grafico completo dell'albero di famiglia a partire dai capostipidi.</p>
                                <p>Cerca di trovarti nell'albero a partire dal tuo antenato.</p>
                            </div>
                            <Button><Link to="/familytreepage" className="inactive">Scopri</Link></Button>
                        </div>
                    </Col>
                </Row>
                <Row className="section-panel" nogutters="true">
                    <Col className="image-panel" nogutters="true">
                        <CardImg top src={statistiche} alt="Statistiche sulla famiglia" />
                    </Col>
                    <Col className="info-panel" nogutters="true">
                        <div className="inner-text">
                            <h4 className="title">Le statistiche</h4>
                            <div className="text">
                                <p>Statistiche sulla famiglia</p>
                                <p>Qui sono raccolti i dati sul numero corrente di parenti, numero di nascite e decessi.</p>
                            </div>
                            <Button><Link to="/statistics" className="inactive">Scopri</Link></Button>
                        </div>
                    </Col>
                </Row>
                <Row className="section-panel section-reverse" nogutters="true">
                    <Col className="image-panel" nogutters="true">
                        {/* <SlideShow items={items}></SlideShow> */}
                        <UncontrolledCarousel items={items} indicators={false} controls={false} />
                    </Col>
                    <Col className="info-panel" nogutters="true">
                        <div className="inner-text">
                            <h4 className="title">Raduni passati</h4>
                            <div className="text">
                                <p>Storia di come e' nato il primo raduno e foto del primo albero genealogico.</p>
                                <p>Raccolta di foto di raduni passati, non solo della foto di famiglia ma anche di giochi e divertimenti.</p>
                                <p>Aiutaci ad aumentare la collezione con le tue foto.</p>
                            </div>
                            <Button><Link to="/pastmeetings" className="inactive">Scopri</Link></Button>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Home

                // Elder Letter or Keynote speech
                // Family Tree
                // Family Ancestors History
                // Family Photo Archive
                // Family Statistics
                // Family anecdotes
                // Search profile
                // My Profile
                // Events
                // Lodging information
                // Shopping Card
                // volunteering
