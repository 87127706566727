import React, { Component } from 'react'
import {
  Container,
  Jumbotron,
  Button,
} from 'reactstrap';
import {
  Link,
} from 'react-router-dom'
import '../styles/Splash.css';

class Splash extends Component {

  render() {
    return (
      <div className='Splash'>
        <Container>
          <h1 className="display-3">Benvenuti al Clan Schiratti!</h1>
          <p className="lead">
          <Button tag={Link} to="/login" color="primary">Comincia da qui</Button>
          </p>
        </Container>
        <Jumbotron>
          <p className="lead">In questo sito puoi conoscere la storia della tua famiglia, leggere storie sui tuoi antenati, conoscere nuovi cugini, comperare il piatto commemorativo... e molto di piu'. </p>
          <hr className="my-2" />
          <p>Comincia con il tuo profilo, dove puoi caricare la tua foto e modificare i tuoi dati. Controlla che il tuo posto sia nella posizione corretta dell'albero di famiglia.</p>
          <p className="lead"></p>
        </Jumbotron>
      </div>
    );
  }

}

export default Splash