import React from 'react';
import AuthComponent from '../components/AuthComponent';
import {
    Row, Col, Button, CardImg, Jumbotron
} from 'reactstrap';
import { Link } from 'react-router-dom'
import cimitero from '../images/cimitero.png';
import eventi from '../images/events.jpg';
import alloggi from '../images/pieve_piazza.png';
import volontariato from '../images/volontariato.png';
import prenota from '../images/prenota.png';
import newBorn from '../images/newBorn.jpg';
import '../styles/Home.css'

export default class Raduno2020Page extends AuthComponent {

    render() {
        return (
            <div className="Home">
                <Row className="titleJumbotron" nogutters="true">
                    <Jumbotron>
                        <h1 className="display-3">Benvenuti al Clan Schiratti 2021!</h1>
                        <p className="lead">Questo Raduno e' il 13esimo da quando fu iniziato nel lontano 1960</p>
                        <hr className="my-2" />
                        <p>In questa pagina troverai tutte le informazioni pertinenti al raduno.</p>
                        <p className="lead">Arrivederci a Tutti in Pieve di Soligo!
                                {/* <Button color="primary">Learn More</Button> */}
                        </p>
                    </Jumbotron>
                </Row>
                <Row className="section-panel section-reverse" nogutters="true">
                    <Col className="image-panel" nogutters="true">
                        <CardImg top src={newBorn} alt="I nuovi nati" />
                    </Col>
                    <Col className="info-panel" nogutters="true">
                        <div className="inner-text">
                            <h4 className="title">I Nuovi Nati</h4>
                            <div className="text">
                                <p>Un grande benvenuto a tutte le bambine ed i bambini nati negli ultimi 5 anni</p>
                            </div>
                            <Button><Link to="/newborn" className="inactive">Scopri</Link></Button>
                        </div>
                    </Col>
                </Row>
                <Row className="section-panel" nogutters="true">
                    <Col className="image-panel" nogutters="true">
                        <CardImg top src={cimitero} alt="Cimitero di Pieve di Soligo" />
                    </Col>
                    <Col className="info-panel" nogutters="true">
                        <div className="inner-text">
                            <h4 className="title">In memoriam</h4>
                            <div className="text">
                                <p>In questa pagina, ricordiamo i nostri cari che ci hanno lasciato dall'ultimo raduno.
                                    Manteniamo viva la memoria di chi non c'è più.
                                </p>
                            </div>
                            <Button><Link to="/inmemoriam" className="inactive">Scopri</Link></Button>
                        </div>
                    </Col>
                </Row>
                <Row className="section-panel section-reverse" nogutters="true">
                    <Col className="image-panel" nogutters="true">
                        <CardImg top src={eventi} alt="Eventi" />
                    </Col>
                    <Col className="info-panel" nogutters="true">
                        <div className="inner-text">
                            <h4 className="title">Eventi</h4>
                            <div className="text">
                                <p>Lista di tutti gli eventi dei tre giorni del raduno</p>
                                <p>Qui troverai la data con l'ora, indirizzo e google map di dove succederanno tutti gli eventi del raduno.</p>
                            </div>
                            <Button><Link to="/events" className="inactive">Scopri</Link></Button>
                        </div>
                    </Col>
                </Row>
                <Row className="section-panel" nogutters="true">
                    <Col className="image-panel" nogutters="true">
                        <CardImg top src={alloggi} alt="Alloggi" />
                    </Col>
                    <Col className="info-panel" nogutters="true">
                        <div className="inner-text">
                            <h4 className="title">Alloggi</h4>
                            <div className="text">
                                <p>Vuoi stare in un albergo o in un bed and breakfast?
                                Qui troverai informazioni utili per trovare un alloggio a Pieve di Soligo e dintorni.
                                    </p>
                            </div>
                            <Button><Link to="/lodging" className="inactive">Scopri</Link></Button>
                        </div>
                    </Col>
                </Row>
                <Row className="section-panel section-reverse" nogutters="true">
                    <Col className="image-panel" nogutters="true">
                        <CardImg top src={prenota} alt="Prenotazioni" />
                    </Col>
                    <Col className="info-panel" nogutters="true">
                        <div className="inner-text">
                            <h4 className="title">Prenotazioni</h4>
                            <div className="text">
                                <p>Vuoi prenotare il libro od il piatto commemorativo?</p>
                                <p>In questo link puoi prenotare questi e molto di piu'</p>
                            </div>
                            <Button><Link to="/booking" className="inactive">Scopri</Link></Button>
                        </div>
                    </Col>
                </Row>
                <Row className="section-panel" nogutters="true">
                    <Col className="image-panel" nogutters="true">
                        <CardImg top src={volontariato} alt="Volontariato" />
                    </Col>
                    <Col className="info-panel" nogutters="true">
                        <div className="inner-text">
                            <h4 className="title">Volontariato</h4>
                            <div className="text">
                                <p>Lista di vari lavori per rendere questo raduno un successo</p>
                                <p>Vuoi aiutarci? Registrati qui.</p>
                            </div>
                            <Button><Link to="/volunteer" className="inactive">Scopri</Link></Button>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}