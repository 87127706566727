import React from 'react';
import {
    Card, CardBody, CardTitle, CardImg,
    CardText, Col, Row, Container,
    Navbar, NavLink
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import scrollToComponent from 'react-scroll-to-component';
import AuthComponent from '../components/AuthComponent'
import '../styles/LinkPage.css'
//toniolo
import GiuseppeToniolo from '../docs/GiuseppeToniolo.js';
import ritrattoToniolo from '../images/Famosi/Giuseppe_Toniolo.jpg'
import ritrattoMariaToniolo from '../images/Famosi/Maria_Schiratti_ved_Toniolo.jpg'
import ritrattogToniolo from '../images/Famosi/330px-Toniolo.jpg'
import news1Toniolo from '../images/Famosi/chieraToniolo1.jpg'
import news2Toniolo from '../images/Famosi/chieraToniolo2.jpg'
//salvioni
import EmiliaSalvioni from '../docs/EmiliaSalvioni.js';
import ritrattoSalvioni from '../images/Famosi/EmiliaSalvioni.jpg'
import withSister from '../images/Famosi/1902_Emilia_con_Anna.jpg'
import pieveDiSoligo from '../images/Famosi/1915_porta_casa_di_Pieve_di_Soligo.jpg'
import setteCugine from '../images/Famosi/1915_sette_cugine_Schiratti_Toniolo_e_Salvioni.jpg'
import lastPicture from '../images/Famosi/1967_con_sorella_ultima_forografia.jpg'

export default class EventsPage extends AuthComponent {

    render() {
        return (
            <Container className="LinkPage">
                <div className='Top' ref={(div) => { this.TopSection = div; }}></div>
                <h4>PERSONAGGI FAMOSI</h4>
                <h6>Nella nostra famiglia ci sono stati vari personaggi che si sono distinti nelle loro professioni e
                sono diventati famosi nella storia italiana. In questa pagina li vogliamo ricordare per il loro
                contributo alla societa'.
                Le loro vite con il loro esempio hanno influenzato le vite dei nostri nonni, forse nelle loro scelte
                in famiglia e professionali. Consideriamo la possibilità che in parte abbiamo influenzato anche le nostre.
                </h6>
                <Navbar bsstyle="tabs" style={{ justifyContent: 'flex-start' }}>
                    <NavLink
                        className="scrolltoToniolo"
                        onClick={() => scrollToComponent(this.TonioloSection, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}>
                        Giuseppe Toniolo
                    </NavLink>
                    <NavLink
                        className="scrolltoSalvioni"
                        onClick={() => scrollToComponent(this.SalvioniSection, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}>
                        Emilia Salvioni
                    </NavLink>
                    <NavLink
                        className="scrolltoSchiratti"
                        onClick={() => scrollToComponent(this.SchirattiSection, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}>
                        Antonio Schiratti
                    </NavLink>
                </Navbar>
                <Row className="grandparents-row">
                    <Col sm={{ size: 5 }}>
                        <Card>
                            <CardImg top width="100%" src={ritrattoToniolo} alt="Giuseppe Toniolo" />
                        </Card>
                        <Card>
                            <CardImg top width="100%" src={ritrattoMariaToniolo} alt="Maria Schiratti vedova Toniolo" />
                        </Card>
                        <Card>
                            <CardImg top width="100%" src={ritrattogToniolo} alt="Maria Schiratti vedova Toniolo" />
                        </Card>
                    </Col>
                    <Col sm={{ size: 7, offset: 0.1 }}>
                        <Card>
                            <CardBody>
                                <div className='TonioloSection' ref={(div) => { this.TonioloSection = div; }}>
                                    <NavLink className="scrolltoTop" onClick={() => scrollToComponent(this.TopSection, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}>
                                        Ritorna <FontAwesomeIcon icon={faAngleUp} />
                                    </NavLink>
                                    <CardTitle>GIUSEPPE TONIOLO</CardTitle>
                                    <CardText><GiuseppeToniolo></GiuseppeToniolo></CardText>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <CardImg top width="50%" src={news1Toniolo} alt="Giuseppe Toniolo sui giornali" />
                        </Card>
                        <Card>
                            <CardImg top width="50%" src={news2Toniolo} alt="Giuseppe Toniolo sui giornali" />
                        </Card>
                    </Col>
                </Row>
                <br />
                <Row className="grandparents-row">
                    <Col sm={{ size: 5 }}>
                        <Card>
                            <CardImg top width="50%" src={ritrattoSalvioni} alt="Emilia Salvioni" />
                            <CardText>Emilia Salvioni</CardText>
                        </Card>
                        <Card>
                            <CardImg top width="50%" src={withSister} alt="Emilia ed Anna Salvioni - 1902" />
                            <CardText>Emilia ed Anna Salvioni - 1902</CardText>
                        </Card>
                        <Card>
                            <CardImg top width="50%" src={pieveDiSoligo} alt="Porta di casa a Pieve di Soligo - 1915" />
                            <CardText>Porta di casa a Pieve di Soligo - 1915</CardText>
                        </Card>
                        <Card>
                            <CardImg top width="50%" src={setteCugine} alt="Sette cugine Schiratti, Toniolo e Salvioni - 1915" />
                            <CardText>Sette cugine Schiratti, Toniolo e Salvioni - 1915</CardText>
                        </Card>
                        <Card>
                            <CardImg top width="50%" src={lastPicture} alt="Ultima foto con la sorella - 1967" />
                            <CardText>Ultima foto con la sorella - 1967</CardText>
                        </Card>
                    </Col>
                    <Col sm={{ size: 7, offset: 0.1 }}>
                        <Card>
                            <CardBody>
                                <div className='SalvioniSection' ref={(div) => { this.SalvioniSection = div; }}>
                                    <NavLink className="scrolltoTop" onClick={() => scrollToComponent(this.TopSection, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}>
                                        Ritorna <FontAwesomeIcon icon={faAngleUp} />
                                    </NavLink>
                                    <CardTitle>EMILIA SALVIONI</CardTitle>
                                    <CardText><EmiliaSalvioni></EmiliaSalvioni></CardText>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </Container>
        )
    }
}