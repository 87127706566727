import React from 'react';
import {
    Container, Jumbotron, Col, CardDeck
} from 'reactstrap';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Spinner from '../components/Spinner';
import ErrorModal from '../components/ErrorModal';
import MiniProfile from '../components/MiniProfile';
import AuthComponent from '../components/AuthComponent';
import '../styles/LinkPage.css'
import '../styles/MiniProfile.css'

const GET_ALL_USERS_GQL_QUERY = gql`
    query {
    getUsers {
        numschi
        firstName
        lastName
        photo
        year
        deathYear
    }
}
`;

export default class NewBornPage extends AuthComponent {
    constructor(props) {
        super(props);

        this.state = {
            users: "",
            sortedUsers: []
        };
        this.sortData = this.sortData.bind(this);
    }
    sortData(data) {
        //sort users
        var users = JSON.parse(JSON.stringify(data.getUsers));

        this.state.sortedUsers = users.filter(user => user.year >= 2015);
    }
    render() {
        return (
            <Container className="LinkPage NewBornPage">
                <Jumbotron>
                    <p className="lead"><b>A tutte le bambine ed i bambini nati negli ultimi cinque anni, benvenuti al Clan Schiratti!</b></p>
                    <hr className="my-2" />
                    <p>Vi auguriamo una vita felice, sana e piena di soddisfazioni.</p>
                </Jumbotron>
                <Container>
                    <Query
                        fetchPolicy='no-cache'
                        query={GET_ALL_USERS_GQL_QUERY}
                        onCompleted={(data) => {

                            this.setState({ data: data })
                        }}  >
                        {({ loading, error, data }) => {
                            if (loading) {
                                return (
                                    <Spinner />
                                );
                            } else if (error) {
                                return (
                                    <ErrorModal error={error} />
                                );
                            } else {
                                if (data.getUsers === undefined || (data.getUsers !== undefined && data.getUsers.length === 0)) {
                                    return <div></div>
                                } else {

                                    this.sortData(data);

                                    var list = this.state.sortedUsers.map(function (child, index) {
                                        return (
                                            <Col sm="3">
                                                <MiniProfile key={child.numschi} numschi={child.numschi}></MiniProfile>
                                            </Col>
                                        );
                                    });

                                    return (
                                        <CardDeck>
                                            {list}
                                        </CardDeck>
                                    );
                                }
                            }
                        }}
                    </Query>
                </Container>
            </Container>
        );
    }
}