import React, { Component } from 'react'
import { NavLink } from 'reactstrap'

export default class EmiliaSalvioni extends Component {
    render() {
        return (
            <div><span>
                <h4>Nota a cura di Amalia Corrà</h4>
Emilia Salvioni nacque a Bologna il 2 aprile 1895 da Rosa Schiratti, originaria di Pieve di Soligo e
cognata di Giuseppe Toniolo, e da Giovanni Battista Salvioni, professore di Statistica all’Università di Bologna.
Non aveva ancora due anni quando perdette la madre; mentre la sorella maggiore era in collegio, lei rimase sola col padre.
Nella solitudine e nell’isolamento forzato, maturò l’innata vivacissima fantasia e acuì lo spirito di osservazione.
Imparò a leggere a soli tre anni e i libri furono i suoi compagni di giochi e il suo rifugio. E subito imparò
anche a scrivere, trovando proprio nella scrittura il modo più facile e naturale per esprimersi e costruire il
suo mondo interiore. Interrotti gli studi regolari dopo la licenza ginnasiale, le fu impartita l’educazione propria
delle giovani della sua classe sociale: diploma di pianoforte, studio delle lingue classiche e moderne, lezioni di pittura,
a cui si dedicava durante le estati che continuò a trascorrere, insieme all’inseparabile sorella Anna, nella casa
acquistata dal padre a Pieve di Soligo, dove incontrava la numerosa parentela della famiglia della madre.
Nella sua geografia spirituale si possono identificare due centri, l’uno, Bologna, il luogo della cultura e del lavoro;
l’altro, Pieve di Soligo, il paese delle origini, il luogo degli affetti e delle vacanze che la fa sentire «veneta
per quattro quarti». Grazie al quotidiano contatto con l’enciclopedica cultura del padre, si formò una vasta e profonda cultura.
Proprio questa preparazione la mise nella condizione nel 1927, quando si trovò nella necessità di lavorare per vivere,
di accettare il posto di bibliotecaria presso l’Istituto Giuridico dell’Università di Bologna, occupazione che svolse
con dedizione e impegno per quarant’anni e che non le impedì di dedicare alla scrittura, tutte le ore libere.
Come scrittrice esordì nel 1918 pubblicando le prime novelle e firmò con lo pseudonimo Marina Vallauri, suggeritole dal padre,
i due romanzi usciti nel 1922 e nel 1923. Narratrice versatile e ricca di interessi, seppe destreggiarsi in generi letterari
e registri di scrittura diversi, passando dal romanzo impegnato di analisi sociale e d’introspezione psicologica
(Danaro; I nostri anni migliori), al «giallo» (Gli uomini sono cattivi), dall’indagine della situazione femminile in un
mondo di rivolgimenti sociali (Carlotta Varzi S.A.), a romanzi sentimentali e leggeri per «giovinette» (Sette belle ragazze);
la passione per gli studi storici e per la ricerca le permisero di cimentarsi anche nel genere della biografia
(Padre Matteo Ricci; La grande avventura di Francesco Saverio). Alla letteratura destinata ai giovani lettori dedicò
buona parte della sua produzione; il suo costante impegno fu premiato dal successo testimoniato dalle numerose edizioni
di alcuni suoi libri. Sollecitazioni da parte di case editrici e della stampa furono insolitamente copiose e intensa
fu sempre la sua collaborazione a riviste, periodici e quotidiani. Con grande abilità e sagacia Emilia riuscì a intessere
relazioni e collaborazioni con il mondo del giornalismo. Dagli esordi, nel 1910, fino al 1968, anno della scomparsa,
troviamo la sua firma in più di trenta diverse pubblicazioni, una copiosa produzione di novelle e racconti, articoli
di costume, letteratura, arte, cinema, teatro, in cui riflette in modo elegante e mai superficiale i suoi interessi poliedrici.
Fondò e diresse una rivista femminile quindicinale, Serena; fu membro della giuria in concorsi per opere teatrali e di narrativa.
Diresse dal 1952 al 1963, presso la casa editrice Cappelli, la Collana Azzurra, in cui venivano pubblicati romanzi
per signorine, che riscosse notevole successo di pubblico. Partecipò con piacere alla vita sociale della sua città,
sempre disposta a impegnarsi in attività culturali e benefiche. Fu una conferenziera ricercata e apprezzata per
l’interesse dei temi trattati e il modo brillante e arguto con cui li sviluppava. Mantenne vivaci relazioni col mondo
letterario, con il circolo delle scrittrici a lei contemporanee, con la società colta universitaria bolognese che ogni
sabato trovava nell’abitazione della scrittrice e nella sua figura di intellettuale un punto d’incontro.
Questa molteplice attività non passò inosservata agli occhi dei contemporanei e della critica. Nel 1932 fu segnalata
dall’Accademia Mondadori per la commedia La casa nuova, lo stesso accadde nel 1933 per una raccolta di versi e
per il romanzo Danaro, che la stessa Mondadori pubblicò nel 1934. Nel 1939 vinse il concorso «La Scuola Italiana Moderna»
col romanzo Lavorare per vivere; nel 1941 ricevette una segnalazione al concorso «Giornale d’Italia» per il
romanzo Carlotta Varzi S.A.; nel 1953 una giuria composta da Aldo Palazzeschi e Marino Moretti le conferì il secondo
premio al Concorso UECI, premio A. Manzoni, per il romanzo E intanto Erminia…Benché noti e autorevoli critici
(Francesco Casnati, Igino Giordani, Vincenzo Schilirò, Agostino Turla, Pietro Pancrazi) abbiano compreso e messo
in rilievo i pregi di sue singole opere, mancò una valutazione complessiva che desse conto di una produzione letteraria
così molteplice, ricca e interessante, e dopo la sua morte, avvenuta a Bologna, il 4 giugno 1968, fu presto dimenticata.
È sepolta a Pieve di Soligo nella tomba di famiglia. Sulla lapide è racchiuso il suo tragitto terreno in una sintesi
possibile solo alla poesia di Andrea Zanzotto: 'L'amore dello scrivere / in cui seppe trasfondere / la sua aperta
cristiana umanita' / raggiungendo i valori dell'arte / l'amore del libro / cui dedico' la sua cura costante /
quale bibliotecaria dell'istituto / giuridico dell'universita' bolognese / resero la sua vita umilmente altamente /
offerta alla luce dello spirito.'
<br />
                <br />

                <h4>Memorie di una nipote a cura di Nives Zonin</h4>
Quando eravamo piccole, nostra madre portava noi cinque sorelle, ogni settembre prima dell’inizio della scuola,
a Pieve di Soligo. Alloggiavamo alla Mussa che era del nonno Bepi e giocavamo tutto il giorno agli indiani,
godendoci la libertà di correre in un grande spazio per noi quasi sconfinato. Durante il mese di permanenza
a Pieve la mamma ci portava nel pomeriggio in visita ai parenti: c’era la “santola” della Mariolina,
la Drusi Boattin; la zia Lucia Bernardi che ci deliziava con i suoi cannoncini alla crema; le due zie Amalia e
Maria Corra’ e pure lo zio Checchi e la loro mamma, che ci raccontavano delle rappresentazioni teatrali che
facevano in famiglia; naturalmente c’era la passeggiata al mercato il sabato e visita in farmacia dal nonno;
e ancora la mamma ci portava dalle zie Salvioni nel pomeriggio. Era molto piacevole sostare in giardino dalle
zie Salvioni: avevano una “gloriette ” fatta con piante a cerchio e chiuse sulla
sommità, sopra una collinetta, dove si poteva entrare e sedersi su una panchina.
Mi ricordo anche le aiuole curate con la salvia splendida e le zinnie che a settembre erano al loro massimo splendore.
Ero piccola per apprezzare i loro discorsi colti e saggi, e tutto mi sembrava così ovvio e naturale... poi nella vita
mi sono resa conto che quelle erano giornate speciali. Nella casa c’era una camera misteriosa ed affascinante
completamente dipinta come una foresta, e c’era un’altra stanza al pianterreno con le quattro pareti piene zeppe
di libri e solamente una poltrona per sedersi a leggere. 
Mi ricordo che la zia Emilia era una persona pacata, con un viso dolce che metteva a proprio agio le persone
e mi ricordo anche della parlata emiliana.
Tutto questo ha lasciato sicuramente un segno nella mia
mente di ragazzina e sono grata per aver potuto leggere in seguito qualcuno dei libri scritti dalla zia Emilia. <br />
                <br />
                <i>Bibliografia: <br />
                <NavLink target="_blank" href="http://www.emiliasalvioni.it">http://www.emiliasalvioni.it</NavLink>
                <NavLink target="_blank" href="https://en.wikipedia.org/wiki/Emilia_Salvioni">https://en.wikipedia.org/wiki/Emilia_Salvioni</NavLink>
                </i>
                <br />
            </span></div>
        )
    };
}