import React from 'react';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle
} from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons'

import { Link } from "react-router-dom";
import '../styles/TreeNodeProfile.css';
import AuthComponent from './AuthComponent';
import squirrel from '../images/squirrel.png';

export default class TreeNodeProfile extends AuthComponent {
    constructor (props) {
        super(props);
        this.state = {expandIcon: this.props.user.expand};
        this.toggleButton = this.toggleButton.bind(this);
    }

    toggleButton() {
        this.props.user.expand = !this.props.user.expand;
        this.setState({expandIcon: this.props.user.expand});
        this.props.handleExpand(this.props.user);
    }

    formatNumschi(numschi) {
        var partnerN = '';
        var what = parseInt(numschi[numschi.length - 1], 10)
        if (isNaN(what)) {
            //multiple partners
            partnerN = numschi.substring(0, numschi.length - 2);
        } else if (what === 0) {
            //partner
            partnerN = numschi.substring(0, numschi.length - 1);
        }
        return partnerN
    }

    render() {
        // var prepend = "";
        // if (process.env.NODE_ENV === 'development') {
        //     prepend = "http://localhost:8080";
        // }
        const image = "" + this.props.user.photo;

        var pimage = "";
        var pnumschi = "";

        // const expIcon = this.state.expandIcon ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleUp} />;

        if (this.props.user && this.props.user.partners.length !== 0) {
            var me = this;
            var partnerList = this.props.user.partners.map(function (partner) {
                pimage = "" + partner.photo;
                pnumschi = me.formatNumschi(partner.numschi);
                return (
                    <Card key={partner.numschi}>
                        <Link to={`/profile/${partner.numschi}`}>
                            <CardBody>
                                <CardSubtitle>{pnumschi}&#10084;</CardSubtitle>
                                {partner.photo.length !== 0 && (<CardImg top width="100%" src={pimage} alt="Foto" />)}
                                {partner.photo.length === 0 && (<CardImg top width="100%" src={squirrel} alt="Foto" />)}
                                <CardTitle>{partner.firstName} {partner.lastName}</CardTitle>
                                <CardText>{partner.year !== -1 ? "" + partner.year : ""}{partner.deathYear !== -1 ? " - " + partner.deathYear : ""}</CardText>
                            </CardBody>
                        </Link>
                    </Card>);
            });
        }

        return (
            <div className="TreeNodeProfile">
                <Card key={this.props.user.numschi}>
                    <Link to={`/profile/${this.props.user.numschi}`}>
                        <CardBody>
                            <CardSubtitle>{this.props.user.numschi}</CardSubtitle>
                            {this.props.user.photo.length !== 0 && (<CardImg top width="100%" src={image} alt="Foto" />)}
                            {this.props.user.photo.length === 0 && (<CardImg top width="100%" src={squirrel} alt="Foto" />)}
                            <CardTitle>{this.props.user.firstName} {this.props.user.lastName}</CardTitle>
                            <CardText>{this.props.user.year !== -1 ? "" + this.props.user.year : ""}{this.props.user.deathYear !== -1 ? " - " + this.props.user.deathYear : ""}</CardText>
                        </CardBody>
                    </Link>
                    {/* {(this.props.user.numschi.length === 1 && this.props.user.numschi !== '0') &&
                        (<Button color="btn btn-light" onClick={(e) => { this.toggleButton(e) }} >{expIcon}</Button>)
                    } */}
                </Card>
                {partnerList}

            </div>
        );
    }

}


