import React from 'react'
import AuthComponent from '../components/AuthComponent'
import {
    Container, Card, CardBody,
    CardTitle, CardSubtitle, Input,
    InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import {
    VictoryLine, VictoryChart, VictoryTheme,
    VictoryAxis, VictoryLabel, VictoryLegend
} from 'victory';

const familyColor = ["#000", "#E38627", "#C13C37", "#6A2135", "#447295", "#2D5067", "#999999"];
const startYear = 1960;
const endYear = 2020;
const numYears = endYear - startYear;

export default class StatGrowth extends AuthComponent {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            birthYear: [[0], [1], [2], [3], [4], [5], [6]],
            deathYear: [[0], [1], [2], [3], [4], [5], [6]],
            accYear: [[0], [1], [2], [3], [4], [5], [6]],
            accSDYear: [[0], [1], [2], [3], [4], [5], [6]],
            accYearTotal: []
        };

        this.processData(this.props.users);
    }

    processData(users) {
        //init birth and death year array
        for (var a = 0; a <= 6; a++) {
            for (var y = 0; y <= numYears; y++) {
                this.state.birthYear[a].push({ year: startYear + y, count: 0 });
                this.state.deathYear[a].push({ year: startYear + y, count: 0 });
            }
        }

        for (var j = 0; j < users.length; j++) {
            let parent = parseInt(users[j].numschi[0])
            //collect birth
            let birthYear = parseInt(users[j].year)
            if (birthYear === -1) continue;
            if (birthYear <= startYear) {
                this.state.birthYear[0][1].count++;
                this.state.birthYear[parent][1].count++;
            } else {
                var index = this.state.birthYear[parent].findIndex(x => x.year === birthYear)
                if (index !== -1) {
                    this.state.birthYear[0][index].count++;
                    this.state.birthYear[parent][index].count++;
                }
            }
            //collect death
            let deathYear = parseInt(users[j].deathYear)
            if (deathYear === -1) continue;
            if (deathYear <= startYear) {
                this.state.deathYear[0][1].count++;
                this.state.deathYear[parent][1].count++;
            } else {
                var indexD = this.state.deathYear[parent].findIndex(x => x.year === birthYear)
                if (indexD !== -1) {
                    this.state.deathYear[0][indexD].count++;
                    this.state.deathYear[parent][indexD].count++;
                }
            }
        }

        //accumulation
        for (a = 0; a <= 6; a++) {
            for (var z = 0; z <= numYears; z++) {
                this.state.accYear[a].push({ x: startYear + z, y: 0 });
            }
        }

        for (a = 0; a <= 6; a++) {
            this.state.accYear[a][1].y = this.state.birthYear[a][1].count
            for (var w = 2; w < this.state.accYear[a].length; w++) {
                this.state.accYear[a][w].y = this.state.accYear[a][w - 1].y + this.state.birthYear[a][w].count;
            }
        }

        //senza deceduti
        for (a = 0; a <= 6; a++) {
            for (var d = 0; d <= numYears; d++) {
                this.state.accSDYear[a].push({ x: startYear + d, y: 0 });
            }
        }
        for (a = 0; a <= 6; a++) {
            this.state.accSDYear[a][1].y = this.state.accYear[a][1].y - this.state.deathYear[a][1].count
            for (var n = 1; n < this.state.accSDYear[a].length; n++) {
                this.state.accSDYear[a][n].y = this.state.birthYear[a][n].count - this.state.deathYear[a][n].count;
            }
        }



        this.state.accYear[0].splice(0, 1);
        this.state.accYear[1].splice(0, 1);
        this.state.accYear[2].splice(0, 1);
        this.state.accYear[3].splice(0, 1);
        this.state.accYear[4].splice(0, 1);
        this.state.accYear[5].splice(0, 1);
        this.state.accYear[6].splice(0, 1);
        this.state.accYearTotal = this.state.accYear[0];
    }

    handleClicks(event) {
        const target = event.target;
        var tmp = this.state.accYear;

        if (target.name === "senzaTotale") {
            if (target.checked === true) {
                tmp[0] = []
                this.setState({
                    accYear: tmp,
                });
            } else {
                tmp[0] = this.state.accYearTotal
                this.setState({
                    accYear: tmp
                });
            }
        }
    }

    render() {

        return (
            <Container className="Statistics">
                <Card>
                    <CardBody>
                        <CardTitle>Quanti parenti sono nati o si sono aggiunti al clan dal 1960?</CardTitle>
                        <CardSubtitle>Distribuzione dei nascituri ed acquisiti tra le famiglie</CardSubtitle>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <Input addon
                                        onClick={this.handleClicks.bind(this)}
                                        type="checkbox" name="senzaTotale" aria-label="Checkbox per senza totale" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <InputGroupText>Senza la curva del totale</InputGroupText>
                        </InputGroup>
                        {/* <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <Input addon
                                        onClick={this.handleClicks.bind(this)}
                                        type="checkbox" name="senzaDeceduti" aria-label="Checkbox per senza deceduti" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <InputGroupText>Senza i deceduti</InputGroupText>
                        </InputGroup> */}
                        <VictoryChart theme={VictoryTheme.material} name="familyGrowth"   
                            domain={this.state.accYear[0].length === 0 ? { x: [1960, 2020], y: [0, 160] } : { x: [1960, 2020], y: [0, 400] }}
                        >
                            <VictoryLegend
                                title="Capostipiti"
                                centerTitle
                                orientation="horizontal"
                                gutter={10}
                                style={{ border: { stroke: "black" }, title: { fontSize: 8 }, labels: { fontSize: 8 } }}
                                data={[
                                    { name: "Totale", symbol: { fill: familyColor[0] } }, { name: "Tita", symbol: { fill: familyColor[1] } },
                                    { name: "Emilia", symbol: { fill: familyColor[2] } }, { name: "Pia", symbol: { fill: familyColor[3] } },
                                    { name: "Carolina", symbol: { fill: familyColor[4] } }, { name: "Teresina", symbol: { fill: familyColor[5] } },
                                    { name: "Giuseppe", symbol: { fill: familyColor[6] } }
                                ]}
                            />
                            <VictoryLine
                                style={{
                                    data: { stroke: familyColor[0] },
                                    parent: { border: "1px solid #ccc" }
                                }}
                                interpolation="linear"
                                data={this.state.accYear[0]}
                                name="series-0"
                            />
                            <VictoryLine
                                style={{
                                    data: { stroke: familyColor[1] },
                                    parent: { border: "1px solid #ccc" }
                                }}
                                interpolation="linear"
                                data={this.state.accYear[1]}
                                name="series-1"
                            />
                            <VictoryLine
                                style={{
                                    data: { stroke: familyColor[2] },
                                    parent: { border: "1px solid #ccc" }
                                }}
                                interpolation="linear"
                                data={this.state.accYear[2]}
                                name="series-2"
                            />
                            <VictoryLine
                                style={{
                                    data: { stroke: familyColor[3] },
                                    parent: { border: "1px solid #ccc" }
                                }}
                                interpolation="linear"
                                data={this.state.accYear[3]}
                                name="series-3"
                            />
                            <VictoryLine
                                style={{
                                    data: { stroke: familyColor[4] },
                                    parent: { border: "1px solid #ccc" }
                                }}
                                interpolation="linear"
                                data={this.state.accYear[4]}
                                name="series-4"
                            />
                            <VictoryLine
                                style={{
                                    data: { stroke: familyColor[5] },
                                    parent: { border: "1px solid #ccc" }
                                }}
                                interpolation="linear"
                                data={this.state.accYear[5]}
                                name="series-5"
                            />
                            <VictoryLine
                                style={{
                                    data: { stroke: familyColor[6] },
                                    parent: { border: "1px solid #ccc" }
                                }}
                                interpolation="linear"
                                data={this.state.accYear[6]}
                                name="series-6"
                            />
                            <VictoryAxis
                                label="Anno di Nascita"
                                style={{
                                    axisLabel: { fontSize: 12, padding: 30 },
                                    tickLabels: { fontSize: 8, padding: 5 }
                                }
                                }
                                tickValues={[1960, 1965, 1970, 1975, 1980, 1985, 1990, 1995, 2000, 2005, 2010, 2015, 2020]}
                                tickFormat={(t) => "" + t}

                                tickLabelComponent={
                                    <VictoryLabel angle={-90} verticalAnchor="middle" textAnchor="end" />
                                }
                            />
                            <VictoryAxis
                                dependentAxis
                                label="Numero di Persone"
                                style={{
                                    axisLabel: { fontSize: 12, padding: 30 },
                                    tickLabels: { fontSize: 8, padding: 5 }
                                }
                                }
                                tickValues={[50, 100, 150, 200, 250, 300, 350, 400]}

                            />

                        </VictoryChart>
                    </CardBody>
                </Card>
            </Container>
        )
    }
}

