import React from 'react'
import AuthComponent from '../components/AuthComponent'
import {
  Container
} from 'reactstrap';

import gql from "graphql-tag";
import { Query } from "react-apollo";
import Spinner from '../components/Spinner';
import ErrorModal from '../components/ErrorModal';
import '../styles/LinkPage.css'
import StatsGrowth from '../components/StatsGrowth';
import StatsFamilyPie from '../components/StatsFamilyPie';
import StatsFvsM from '../components/StatsFvsM';

const GET_ALL_USERS_GQL_QUERY = gql`
  query {
    getUsers {
      numschi
      year
      deathYear
      city
	    zip 
      country
      alive
      female 
    }
  }
`;


export default class StatisticsPage extends AuthComponent {

  render() {

    return (

      <Container className="LinkPage">
        <h4>STATISTICHE SULLE FAMIGLIE DEL CLAN SCHIRATTI 2020</h4>
        <Query
          query={GET_ALL_USERS_GQL_QUERY}
          onCompleted={(data) => {
            console.log("Statistics onCompleted");
            console.log(data);
          }}  >
          {({ loading, error, data }) => {
            if (loading) {
              return (
                <Spinner />
              );
            } else if (error) {
              return (
                <ErrorModal error={error} />
              );
            } else {
              console.log("Statistics.js data")
              if (data.getUsers === undefined || (data.getUsers !== undefined && data.getUsers.length === 0)) {
                return <div></div>
              } else {

                return (
                  <Container>
                    <StatsFamilyPie users={data.getUsers} ></StatsFamilyPie>
                    <StatsFvsM users={data.getUsers} ></StatsFvsM>
                    <StatsGrowth users={data.getUsers} ></StatsGrowth>
                  </Container>
                );
              }
            }
          }}
        </Query>
      </Container>
    )
  }
}
