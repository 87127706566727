import React from 'react';
import OrgChart from 'react-orgchart';
import AuthComponent from './AuthComponent'
import AncestorProfile from './AncestorProfile'
import '../styles/AncestorsTree.css';

export default class AncestorsTree extends AuthComponent {
       
    render() {
        const MyNodeComponent = ({ node }) => {
            return (
                <div className="initechNode" >
                    <AncestorProfile user={node} withphoto={this.props.withphoto}></AncestorProfile>
                </div>
            );
        };

        var rowlist = this.props.members.sort(function (a, b) {
            let comparison = 0;
            let first = parseInt(a.numschi, 10);
            let second = parseInt(b.numschi, 10);
            if (first > second) {
                comparison = 1;
            } else if (first < second) {
                comparison = -1;
            }
            return comparison;
        });
        var sTree = new Tree();
        for (var i = 0; i < rowlist.length; i++) {
            sTree.add(rowlist[i]);
        }
        var familyTree = sTree.root;
        console.log(familyTree);
        return (
            <div className="AncestorsTree" id="initechOrgChart">
                <div>
                    <OrgChart tree={familyTree} NodeComponent={MyNodeComponent} />
                </div>
            </div>
        );
    }
}

function Node(data) {
    this.numschi = data.numschi;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.year = data.year || "";
    this.deathYear = data.deathYear || "";
    this.note = data.note || "";
    this.photo = data.photo;
    this.size = data.size;
    this.children = [];
    this.partners = [];
}

function Tree() {
    this.root = null;
}

function calculateParent(numschi) {
    var parentN = null;
    //capostipiti
    if (numschi !== '0' && numschi !== '00') {
        var hyphen = numschi.indexOf('-');
        if (hyphen !== -1) {
            //this is a family with more than 9 children
            parentN = numschi.substring(0, hyphen);
        } else {
            parentN = numschi.substring(0, numschi.length - 1);//65            
        }
        if (parentN === "") {
            parentN = "0";
        }
    }
    return parentN;
}

function getPartner(numschi) {
    //ancestor    
    if (numschi === '0') {
        return '';
    }
    //child ex 63-10
    var hyphen = numschi.indexOf('-');
    if (hyphen !== -1) {
        return '';
    }
    var partnerN = '';
    var what = parseInt(numschi[numschi.length - 1], 10)
    if (isNaN(what)) {
        //multiple partners
        partnerN = numschi.substring(0, numschi.length - 2);
    } else if (what === 0) {
        //partner
        partnerN = numschi.substring(0, numschi.length - 1);
    }
    return partnerN
}

Tree.prototype.add = function (data) {
    var node = new Node(data);
    //find out if it is a partner
    var partnerN = getPartner(data.numschi)
    if (partnerN !== '') {
        var partner = this.findBFS(partnerN);
        if (partner) {
            partner.partners.push(node);
        }
    } else {
        //it's a child, find parent
        var toNodeData = calculateParent(data.numschi)
        var parent = toNodeData ? this.findBFS(toNodeData) : null;
        if (parent) {
            // if ((parent.numschi === '0')) {
            //     parent.children.push(node);
            // } else if (parent.numschi.length === 1 && parent.expand === true) {
            parent.children.push(node);
            // } 
        } else {
            if (!this.root) {
                this.root = node;
            } else if (node.numschi[node.numschi.length - 1] === '0') {
                this.root.partner = node;
            } else {
                return 'Root node is already assigned';
            }
        }
    }
};

Tree.prototype.contains = function (data) {
    return this.findBFS(data) ? true : false;
};
Tree.prototype.findBFS = function (data) {
    var queue = [this.root];
    while (queue.length) {
        var node = queue.shift();
        if (node.numschi === data) {
            return node;
        }
        for (var i = 0; i < node.children.length; i++) {
            queue.push(node.children[i]);
        }
    }
    return null;
};
