import React from 'react';
import {
    Container, Row, Col,
    Card, CardImg, CardBody, CardTitle,
    InputGroup, InputGroupAddon, InputGroupText, Input
} from 'reactstrap';
import arrigoniMembers from '../members/ArrigoniMembers'
import schirattiMembers from '../members/SchirattiMembers'
import AuthComponent from '../components/AuthComponent'
import AncestorsTree from '../components/AncestorsTree';
import '../styles/LinkPage.css';
import '../styles/AncestorsPage.css';
import scoiattolo from '../images/scoiattolo.jpg';
import farmacia from '../images/Capostipiti/farmaciaSchiratti.jpeg';
import SchirattiMariaeRosa from '../images/Capostipiti/SchirattiMariaeRosa.jpg';
import NascitaEmiliaArrigoni from '../images/Capostipiti/nascitaEmiliaArrigoni.jpg';
import AntenatiBurei from '../images/Capostipiti/antenatiBortoloBurei.jpg';
import Schiratti1927 from '../images/Capostipiti/Schiratti1927.jpg';
import Schiratti1927T from '../images/Capostipiti/Schiratti1927-tagged.jpg';
import Schiratti1928 from '../images/Capostipiti/Schiratti1928.jpg';
import Schiratti1928T from '../images/Capostipiti/Schiratti1928-tagged.jpg';
import Schiratti1931 from '../images/Capostipiti/Schiratti1931.jpg';
import Schiratti1931T from '../images/Capostipiti/Schiratti1931-tagged.jpg';

export default class AncestorsPage extends AuthComponent {
    constructor(props) {
        super(props);
        this.props = props;
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            photo1927: true,
            photo1928: true,
            photo1931: true,
        };
    }

    handleClick(event) {
        if (event === undefined) return;

        switch (event.target.name) {
            case "photo1927": this.setState({ photo1927: !this.state.photo1927 });
                break;
            case "photo1928": this.setState({ photo1928: !this.state.photo1928 });
                break;
            case "photo1931": this.setState({ photo1931: !this.state.photo1931 });
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <div>
                <Container className="LinkPage">
                    <h4>I NOSTRI ANTENATI</h4>
                    <h6>Da ricerche di geneologia sulla nostra famiglia, il primo capostipite si chiamava
                    Tomaso Carniello detto "Schiratto" e veniva dalla Carnia, cioè le Alpi Carniche
                    nel confine tra Carinthia e Friuli. Egli abitava a Pers,
                    un piccolo centro abitato dove risiede l'omonimo cimitero,
                    e costituisce una frazione del comune di Majano, in provincia di Udine, in Friuli.
                    A Nord di Majano c'è un villaggio che si chiama Borgo Schiratti.
                    Quindi i nostri antenati, venuti dalla Carnia, si sono portati dietro
                    il nome di questo animaletto dei boschi, vivace ed allegro: schiratt che vuol
                    dire scoiattolo, ed è simbolo di attività e di vitalità, sempre in movimento.
                    Il bel motto latino che appare sotto lo scoiattolo dice bene la caratteristica della
                    nostra casata: “Fervet opus”, tradotta letteralmente "Ferve il lavoro", cioè non si
                    pone tempo tra il dire e il fare, “Si lavora con ardore”
                    (da Virgilio, Georgiche, IV, 169; Virgilio adopera la frase per illustrare la
                    laboriosità delle api che dovrebbe essere d'esempio anche per gli uomini.).
                    </h6>
                    <Row className="section-panel">
                        <Col>
                            <Card>
                                <CardImg top width="100%" src={scoiattolo} alt="Card image cap" />
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardBody>
                                    <iframe title="majano" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d22091.444580479798!2d13.081154!3d46.201889!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477a2fdb1eba60f5%3A0x554e71026356cb45!2s33030%20Borgo%20Schiratti%2C%20Province%20of%20Udine%2C%20Italy!5e0!3m2!1sen!2sus!4v1585092473954!5m2!1sen!2sus" width="600" height="450" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <h4>COME SIAMO ARRIVATI A PIEVE DI SOLIGO</h4>
                    <h6>Nel 1485, <b>Tomaso Carniello</b> detto "Schiratto" si trasferì da Pers in Friuli
                    a Valdobbiadene in provincia di Treviso.
                    Da lui, nacque <b>Giovanni Battista Schiratti</b>, da cui <b>Tomaso</b>,
                    da cui <b>Giovanni Battista</b>, da cui <b>Benedetto</b>, da cui <b>Francesco</b>,
                    da cui <b>Tomaso</b>, da cui <b>Giovanni Battista</b>, da cui <b>Antonio</b>.<br />
                        <b>Antonio Schiratti</b> si sposò con <b>Emilia Arrigoni</b>.<br />
                    Nel 1836 si trasferirono da Valdobbiadene a Pieve di Soligo.
                    Dalla loro unione nacquero 8 figli, di cui il sesto era <b>Tommaso Schiratti</b>.<br />
                        <b>Tommaso Schiratti</b> si sposò con <b>Maria Burei</b> e dalla loro unione nacquero i nostri nonni.
                    Da qui segue la nostra discendenza come si può vedere
                    nel seguente albero di famiglia che parte da Antonio Schiratti.
                    </h6>
                    <h4>L'ALBERO DELLA FAMIGLIA SCHIRATTI</h4>
                    <h6>L'albero descrive i capostipiti dei fratelli e sorelle Schiratti, a partire da
                        Antonio Schiratti ed Emilia Arrigoni.</h6>
                </Container >
                <Container className="AncestorsPage">
                    <AncestorsTree members={schirattiMembers} withphoto={true}></AncestorsTree>
                </Container>
                <Container className="LinkPage">
                    <Row>
                        <h4>CHI ERANO GLI ZII DEI NOSTRI NONNI</h4>
                        <h6>Queste descrizioni sono tratte dal libro di Margherita Corna Pellegrini in Radaelli.
                            E' una raccolte di memorie di vari membri della famiglia Schiratti. Se avete una copia a 
                            disposizione, v'incoraggio a leggerlo. 
                            In particolare le seguenti descrizioni sono tratte dai capitoli scritti
                            con devozione ed amore da Giuseppe Schiratti nella realtà del diciannovesimo secolo.
                        </h6>
                    </Row>
                    <div className="newspaper">
                        <h5>Teresa Schiratti</h5>
                        <h5>1839-1907</h5>
                        Teresa era la primogenita di otto fratelli. Leggeva molto, era molto studiosa ed avrebbe desiderato andare oltre
                        le scuole superiori. Fu molto di aiuto alla sua mamma nelle cure famigliari. La sua mamma ripeteva spesso che Teresa
                        avrebbe dovuto essere un uomo. Si prese cura della sorella Pia durante la sua lunga e penosa malattia e ne soffrì molto
                        quando essa morì. Le piaceva molto andare a Pisa dalla sorella Maria, sposata con il Prof. Toniolo, dove aveva opportunità di 
                        attendere conferenze, frequentare ambienti intellettuali e la cultura. Era molto amata dai suoi nipoti.<hr />
                        <h5>Giobatta Schiratti</h5>
                        <h5>1841-1865</h5>
                        Giobatta Schiratti fu l'avventuriero della famiglia Schiratti. Studiò farmacia a Treviso e poi entrò a far pratica in farmacia Schiratti
                        secondo le regole austriache. Essendo vissuto durante il periodo delle guerre d'indipendenza e l'unificazione d'Italia, come tanti
                        giovani dell'epoca pieni di fervore, fuggì dal Veneto per arruolarsi tra gli italiani nella primavera del 1958. Essendo troppo giovane per
                        l'esercito, dovette lavorare
                        come aiutante farmacista a Montecchio presso Modena. Dopo il 1860, post-unificazione, si arruolò per combattere i briganti napoletani,
                        che lottavano ancora per il ripristino del regno Borbone. In quella vita nomade, Giobatta di ammalò di miliaria. Completò il diploma di
                        farmacia a Firenze, dove s'innamorò di una certa signorina Italia. Incorraggiato dal suo datore di lavoro, studiò a Pisa per
                        conseguire la laurea in Chimica. Nell'autunno del 1864, dopo aver sudato, prese freddo e di conseguenza si ammalò di polmonite.
                        il padre Antonio Schiratti attraverso pratiche lunghe ed umilianti, riuscì a rimpatriare il figlio. Giobatta morì a Pieve di Soligo
                        ammaraggiato e coscente che il suo paese era ancora sotto il dominio austriaco.<hr />
                        <h5>Renato Schiratti</h5>
                        <h5>1844-1906</h5>
                        Renato Schiratti era il terzogenito degli Schiratti. Entrò a nove anni nel collegio di S.Caterina a Venezia dove conobbe Giuseppe
                        Toniolo. Insieme a lui, studiò all'Università di Padova nella facoltà di legge, dove si laureò. Subito si dedicò alla carriera di notaio,
                        prima come apprendista a Treviso, e poi come notaio a Pieve di Soligo, dove vi rimase per il resto della vita. Amava viaggiare
                        anche nei paesini meno noti dell'Italia per riscoprirne l'arte. Attraverso le sue ricerche di genealogia, compilò l'elenco cronologico
                        della nostra famiglia che risale al 1485. Fu a lungo membro della Giunta Amministrativa Comunale. Fu uno dei primi a finanziare la
                        costruzione della chiesa di Pieve. La sua casa era il centro di ritrovo di cultura e conversazione, dove i frequentatori partivano
                        con il desiderio di ritornare e la soddisfazione di aver imparato qualche cosa.  Fu ricordato come un amico, un consigliere fido dal cuore
                        buono e generoso.<hr />
                        <h5>Gaetano Schiratti</h5>
                        <h5>1845-1906</h5>
                        Gaetano Schiratti, come suo fratello Renato, frequentò il collegio di S.Caterina a Venezia. Si laureò a Padova in legge.
                        Affascinato dalle teorie di cooperazione, nel 1884 fondò la Banca Popolare di Depositi e Prestiti a Pieve di Soligo per combattere la
                        piaga degli usurai. La Banca prosperò e questo aiutò ad instituire altre banche nei paesi limitrofi. Nel 1885 queste banche furono tutte
                        consorziate per facilitare il lavoro ed aver maggior credito presso la Banca. Aprì uno studio di legge a Pieve di Soligo,
                        facendosi ben presto una larga clientela. Nel 1884 fondò la Latteria di Soligo, e nel 1887 ne sorse un'altra a Pieve di Soligo.
                        Dal 1885 fino al 1890, fu sindaco e poi Consigliere Provinciale, infine Deputato per tre legislature. Gaetano fece
                        costruire un acquedotto sfruttando la scoperta di una sorgente naturale di acqua potabile. A Pieve di Soligo, una strada
                        porta il suo nome.<hr />
                        <h5>Pia Schiratti</h5>
                        <h5>1847-1875</h5>
                        Pia Schiratti, era stata nominata tale, quando l'Italia attendeva da Papa Pio IX la libertà e l'unificazione. Aveva gli occhi
                        celesti ed i capelli crespi e neri, ed era bella e graziosa. Aveva diviso i fratelli in due categorie: i quattro di talento erano Teresa,
                        Gaetano, Renato e Rosa, mentre i quattro di buon senso erano Tita, Pia, Tomaso e Maria.
                        Piena di energia, il padre le affidò l'amministrazione della campagnia, con la cultura dei bachi da seta, l'orto, l'apicoltura ed il pollaio.
                        A Pia piaceva leggere libri di racconti di viaggi e poesia, e quando si ammalò di petto, forse cancro al seno, sognava di trovarsi
                        in paradiso in compagnia di Alessandro Manzoni.<hr />
                        <h5>Tomaso Schiratti</h5>
                        <h5>1849-1934</h5>
                        "Dal nonno Tomaso ho imparato ad amare la vita". E' così che Margherita Radaelli inizia il capitolo dedicato a Tomaso Schiratti.
                        Per il fatto che il suo nonno era cieco, Margherita sin da bambina gli leggeva il giornale e vari libri classici come I Promessi Sposi
                        o scientifici sulle popolazioni di Sumatra e Giava. Diceva che era un bravo ballerino e quando faceva danzare le dame, le candele
                        si spegnevano dagli svolazzi delle gonne. Si era laureato a Padova in Chimica ed aveva ereditato la farmacia Schiratti da suo padre Antonio.
                        Aveva vissuto l'unificazione dell'Italia ed era un grande patriota.<hr />
                        <h5>Maria Schiratti</h5>
                        <h5>1852-1929</h5>
                        Maria trascorse l'infanzia tra le angosce politiche, la fuga del fratello Tita e le difficoltà economiche del padre, nelle
                        ristrettezze di una famiglia ormai numerosa. Fece i suoi studi in casa, poi presentandosi a Treviso per gli esami di maestra.
                        Nel 1878 sposò il prof. Giuseppe Toniolo che aveva studiato a Venezia con i suoi fratelli. Visse il resto della sua vita a Pisa,
                        aiutando il marito in tutte le sue aspirazioni. Nel 1904 fondò insieme al marito l'Associazione per la Protezione della Giovane,
                        in un edificio vicino alla stazione centrale di Pisa, dove solo chi era "dell'ambiente", poteva accedere.
                        Recentemente sono state scoperte quattro relazioni inedite di Maria Schiratti Toniolo rimaste chiusi in una cassapanca della
                        casa famiglia di Pisa. Erano degli anni in cui si diffondeva il cristianesimo sociale, l’ambiente in cui nacque l’Acisjf  in Italia,
                        dapprima a Torino nel 1902, per poi a Roma ed a Pisa. In questi scritti Maria Schiratti Toniolo si rivolgeva al cardinale
                        Maffi, e con lucidità, stigmatizzava il fenomeno della tratta delle bianche, con analisi sociologiche che sono ancora oggi attualissime.
                        Da questi studi è stato scritto un libro "Maria Schiratti Toniolo e la casa della giovane di Pisa" a cura di Donatella Marcesini,
                        la presindente di Acisjf.<hr />
                        <h5>Rosa Schiratti</h5>
                        <h5>1856-1897</h5>
                        Rosa era la piu' giovane ed era molto unita alla sua sorella Maria, anche se era nata quattro anni dopo. Era portata per lo studio
                        ed assidua nei suoi progetti, tanto che potè fare insieme alla sorella maggiore gli studi e prendere insieme il diploma di maestra.
                        Nel 1888, si sposò con il dott. Giovanni Battista Salvioni, noto professore dell'Università di Bologna. La prima dichirazione di amore 
                        che le fece, fu: "Signorina, vuol fare un salto nel vuoto cone me?" Dal matrimonio nacquero due bambine, Anna ed Emilia. Qust'ultima in seguito
                        divenne una famosa scrittrice. Rosa ebbe piu' volte dolori articolari, e spesso andava a Pisa dalla sorella Maria per curarsi. Nell'autunno del 1896,
                        mentre era incinta del terzogenito, ebbe nuovi disturbi artritici. Nel febbraio del 1897 improvvisamente spirò per un attacco al cuore.
                        prima di poter dare alla luce il figlio. Anna ed Emilia rimasero orfane giovani. Emilia descrisse questo periodo nel suo libro "Angeliche 
                        colline" <hr />
                    </div>
                    <br />
                    <Row className="section-panel">
                        <Col>
                            <Card>
                                <CardTitle>Le sorelle piu' giovani di Tommaso Schiratti, Maria e Rosa</CardTitle>
                                <CardImg top width="100%" src={SchirattiMariaeRosa} alt="Schiratti Maria e Rosa" />
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardTitle>Certificato di Nascita di Emilia Arrigoni</CardTitle>
                                <CardImg top width="100%" src={NascitaEmiliaArrigoni} alt="Certificato di Nascita di Emilia Arrigoni" />
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Famiglia Schiratti - 1927</CardTitle>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <Input addon onClick={(e) => { this.handleClick(e) }} type="checkbox" name="photo1928" aria-label="Checkbox per senza nomi" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <InputGroupText>Senza i nomi sulla foto</InputGroupText>
                                    </InputGroup>
                                    {this.state.photo1928 && <CardImg top width="100%" src={Schiratti1928T} alt="Famiglia Schiratti - 1928" />}
                                    {!this.state.photo1928 && <CardImg top width="100%" src={Schiratti1928} alt="Famiglia Schiratti - 1928" />}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Famiglia Schiratti - 1928</CardTitle>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <Input addon onClick={(e) => { this.handleClick(e) }} type="checkbox" name="photo1927" aria-label="Checkbox per senza nomi" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <InputGroupText>Senza i nomi sulla foto</InputGroupText>
                                    </InputGroup>
                                    {this.state.photo1927 && <CardImg top width="100%" src={Schiratti1927T} alt="Famiglia Schiratti - 1927" />}
                                    {!this.state.photo1927 && <CardImg top width="100%" src={Schiratti1927} alt="Famiglia Schiratti - 1927" />}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Famiglia Schiratti - 1931</CardTitle>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <Input addon onClick={(e) => { this.handleClick(e) }} type="checkbox" name="photo1931" aria-label="Checkbox per senza nomi" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <InputGroupText>Senza i nomi sulla foto</InputGroupText>
                                    </InputGroup>
                                    {this.state.photo1931 && <CardImg top width="100%" src={Schiratti1931T} alt="Famiglia Schiratti - 1931" />}
                                    {!this.state.photo1931 && <CardImg top width="100%" src={Schiratti1931} alt="Famiglia Schiratti - 1931" />}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <h4>L'ALBERO DELLA FAMIGLIA ARRIGONI</h4>
                    <h6>L'albero descrive i capostipiti di Emilia Arrigoni, sposa di Antonio Schiratti,
                    madre di Tommaso Schiratti.</h6>
                </Container>
                <Container className="AncestorsPage">
                    <AncestorsTree members={arrigoniMembers} withphoto={false} ></AncestorsTree>
                </Container>
                <br />
                <Container className="LinkPage">
                    <h4>L'ALBERO DELLA FAMIGLIA DI MARIA BUREI</h4>
                    <h6>Maria Burei era moglie di Tommaso Schiratti. Questo albero descrive la sua discendenza fino a
                        suo padre, Bortolo Giovanni Battista Burei</h6>
                    <Card>
                        <CardImg top width="100%" src={AntenatiBurei} alt="Certificato di Nascita di Emilia Arrigoni" />
                    </Card>
                    <br />
                    <Row className="section-panel" style={{ marginTop: '50px' }}>
                        <Col>
                            <Card>
                                <CardImg top width="100%" src={farmacia} alt="Farmacia Schiratti" />
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardBody>
                                    <h4>LA FARMACIA SCHIRATTI</h4>
                                    <h6>La farmacia Schiratti fu fondata nel 1835 da Antonio Schiratti.
                                    Di seguito, la farmacia venne ereditata da generazione in generazione, da padre in figlio. </h6>
                                    <h6>1a generazione: Antonio Schiratti - nato nel 1809</h6>
                                    <h6>2a generazione: Tommaso Schiratti - nato nel 1849</h6>
                                    <h6>3a generazione: Giuseppe Schiratti - nato nel 1899</h6>
                                    <h6>4a generazione: Francesco Schiratti - nato nel 1940</h6>
                                    <h6>5a generazione: Samuele Schiratti - nato nel 1975</h6>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}