import React, { Component } from 'react'
import {
    Button
} from 'reactstrap';
export default class EmiliaCorra extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: false
        }
    }
    showButton = () => {
        this.setState({ expanded: !this.state.expanded })
    }
    render() {
        return (
            <div>
                <span>
                    <h6>Elogio scritto per Emilia</h6>
                    Il giorno 4 ottobre 1974 è deceduta a Pieve di Soligo, all'età di 82 anni, Emilia Schiratti Corrà,
                    lasciando il più vivo rimpianto in chi aveva avuto la fortuna di conoscerla.<br />
                    Donna di profonda e squisita sensibilità sociale, di salda fede cristiana, corrispose pienamente
                    fino dalla giovinezza agli insegnamenti che le venivano dalle tradizioni familiari ed in particolare
                    dalla sua viva amicizia con lo zio, il sociologo Giuseppe Toniolo.<br />
                    Dotata di un temperamento forte, aperto e generoso, incapace di restare indifferente di fronte ai
                    problemi degli altri e sempre disposta a soccorrere chiunque le chiedesse aiuto, impegnò le risorse
                    di una non comune forza d'animo e di una viva intelligenza unita ad una profonda bontà,
                    nell'incessante volontà cristiana di fare il bene.<br />
                    Nel paese in cui fu profuga, durante la prima guerra mondiale, si mise subito a disposizione
                    della parrocchia e dell'ospedale.
                    Ritornata nella sua Pieve, devastata dalla guerra, cominciò a prodigarsi con slancio in ogni attività
                    che potesse contribuire non solo a ripristinare le condizioni precedenti al disastro bellico, ma a
                    favorire lo sviluppo ed il progresso della comunità di cui si sentiva sempre più partecipe e responsabile.<br />
                    Sposata e madre successivamente di quattro figli, profuse i suoi tesori di cuore per il bene della
                    propria famiglia, non trascurando però anche di prodigarsi all'elevazione degli animi recandosi spesso
                    nei paesi del Quartier del Piave e della Vallata, per tenere delle conferenze domenicali, quale delegata
                    dell'Azione Cattolica.<br />
                    Durante la seconda guerra mondiale, ebbe il grande dolore di vedere deportato in un campo di concentramento
                    in Germania, il figlio Nino, allora ancora quindicenne; nonostante la mancanza di notizie sue per moltissimi mesi,
                    il suo coraggio e la fede in Dio non vennero meno.<br />
                    Finita la guerra e ritornato il figlio, Emilia si occupò ancora validamente per ricostruire e risanare,
                    per quanto a lei possibile, i mali della guerra e lenire i dolori di tante povere madri e vedove.
                    Tutti quelli che bussavano alla sua porta, ebbero sempre una parola di conforto, un aiuto tangibile,
                    una speranza ed una prova di solidarietà umana.<br />
                    La famiglia la ricorderà sempre ed il suo esempio di solerte bontà, la sua fede salda, la volontà di
                    prodigarsi sempre per il bene degli altri, siano di sprone alle giovani generazioni che devono tenere
                    alto nei cuori gli ideali di una sana e feconda vita cristiana per il bene di tutti e la pace dei loro spiriti.
                    <p />
                    <h6>Tratto dai ricordi di Ugo Corrà: Notizie su mio padre, Francesco Corrà detto 'Checchi'</h6>
                    Non so che cosa facesse il bisnonno Francesco Corrà. Mi risulta che avesse due figli; uno Sebastiano, che faceva
                    il commesso nel Negozio di Tessuti di Giuseppe Polin a Montebelluna, loro paese di origine; l’altro faceva il cuoco
                    ed in gioventù emigrò in Lombardia, a Bergamo. Non so se ci fossero delle sorelle.<br />
                    <Button onClick={this.showButton}>leggi il resto..</Button>
                    {
                        this.state.expanded &&
                        <div>
                            Il negozio doveva essere abbastanza grosso ed oltre ai clienti locali faceva i mercati dei dintorni. La Nonna Isabella
                            Amalia Dalla Riva era vedova di Giuseppe Polin, con due figli,  la zia Laura e lo zio Ugo. Il nonno Sebastiano se la sposò
                            e con ella ebbe un figlio, il mio Papà, Francesco detto ‘Checchi’.<br />
                            A Montebelluna il negozio ebbe dei problemi, e la famiglia Corrà si trasferì a Pieve di Soligo, aprendo un negozio,
                            sempre di Tessuti e continuando a fare i mercati. Con il Nonno c’era la Nonna, la zia Laura ed il piccolo Francesco.
                            Lo zio Ugo visse  a Pieve e si sposò,  ma nel 1917 venne a mancare.<br />
                            Francesco ero un bel ragazzo, alto ed elegante di natura. Aveva fatto delle scuole, non so bene quali, ma aveva imparato
                            a scrivere molto bene, con una calligrafia che sembrava stampata, sapeva esprimersi in modo civile ed aveva un certo successo.
                            Non sopportava la vita di Pieve di Soligo ed era emigrato in Lombardia dove assieme ad un amico aveva creato un ditta
                            di rappresentanze, sempre nel campo tessile. Lavorava per Il Cotonificio di Albizzate e la sua zona di vendita era
                            tutto il Veneto fin sulle zone alpine.  Le macchine erano poco diffuse, ci si spostava in treno e con le carrozze
                            ed i cavalli. Avendo la sede del lavoro a Milano,  Francesco approfittava della vicinanza della famiglia Corrà di Bergamo,
                            dove lo Zio cuoco aveva fatto carriera, si era sposato ed aveva avuto un figlio  e quattro  figlie. Francesco fece la corte
                            alla cugina Amalia e la sposò; vissero però tra Pieve e Bergamo.<br />
                            Nel frattempo era iniziata la Prima Guerra Mondiale che travolse la vita di moltissime persone. Il mio Papà fu richiamato,
                            come soldato di fanteria e si fece quattro anni di Guerra. Finì con il grado di Sergente, ma grazie alla bella calligrafia
                            fu attivo in Fureria e questo molto probabilmente gli salvò la vita.<br />
                            La moglie Amalia restò a Bergamo per tutto il periodo.  Dopo il disastro di Caporetto tutto il Quartier del Piave fu occupato dalla truppe
                            Austro-Ungariche, per circa un anno e mezzo. <br />
                            La guerra italiana era ferma sul fiume Piave.<br />
                            Il Nonno Sebastiano, la Nonna Amalia e la zia Laura subirono tutta l’occupazione che fu molto pesante.<br />
                            Parte della Famiglia Schiratti fece in tempo a scappare, in carrozza, fin oltre il Piave attraverso il Ponte di Vidor.
                            Quindi proseguì in treno e si rifugiò in quel di Pisa, presso i Toniolo. Gli Schiratti abitarono anche in Pontedera dove
                            le figlie fecero le crocerossine.<br />
                            Finita la guerra il Papà Checchi fu congedato e tornò a Pieve. Purtroppo trovò una situazione  disastrosa; l’invasione aveva
                            distrutto quasi totalmente la vita del paese. Le case erano vuote, mancava di  tutto.<br />
                            Riportò a Pieve  la moglie Amalia, che purtroppo soffriva di cuore, e poco dopo si spense. Non avevano figli.<br />
                            Il suo socio di Milano era riuscito ad evitare l’arruolamento ed approfittando  dell’ assenza di Checchi si era impadronito
                            della Società. Per il papà deve essere stato un colpo terribile!<br />
                            Cercò aiuto nelle sue conoscenze d'anteguerra per ricominciare l’attività del negozio di tessuti in Pieve. Dei Grossisti
                            di Treviso, i sigg. Sammartini lo aiutarono, ma pretesero che restasse lui a gestire l’attività, considerando il Nonno
                            Sebastiano di settanta anni, troppo anziano. Dovette accettare e così ricominciò la sua vita in quel di Pieve. Nel periodo,
                            aveva circa  trentaquattro anni  ed era l’unico sostegno della famiglia.<br />
                            Ebbe, senza dubbio, dei buoni risultati, aiutato anche dalle famiglie Corrà di Bergamo che mandarono parte dei mobili
                            per arredare l’abitazione dove viveva con i Nonni e la Zia Laura.<br />
                            Il successo ottenuto si può dedurre dal fatto che la famiglia Schiratti  concesse “la mano” di Emilia nel 1926.<br />
                            La Mamma Emilia era rimasta l’ultima dei figli di Nonno Tommaso da sposare. Aveva avuto una buone educazione nel Collegio per
                            Signorine a Colbertaldo, in Friuli. Parlava anche il francese ed era stata a Vienna con il Nonno Tommaso. Era positiva e
                            sufficientemente energica, cercava la soluzione, ed era particolarmente buona, e profondamente religiosa, come la maggior
                            parte della famiglia Schiratti.<br />
                            A casa era molto attiva, soprattutto come guardarobiere, cuciva molto bene, ed aveva fantasia nel realizzare i lavori.
                            Per la cucina era più brava la Zia Teresina.<br />
                            Si unirono in matrimonio nel 1926, il papà aveva quarant'anni e la mamma  trenta  cinque, Nel 1927 nacque Sebastiano
                            detto Nino, nel 1929 arrivai io, nel 1932 nacque Amalia e nel 1935 Maria Laura.<br />
                            Purtroppo non ho ricordi del Nonno Sebastiano, della Nonna Amalia e del Nonno Tommaso perché devono essere mancati nei
                            primissimi anni della mia vita. La Nonna Maria la ricordo bene anche nella sua rigidità.<br />
                        </div>
                    }
                </span>
            </div>
        )
    };
}