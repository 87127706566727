import React from 'react';
import AuthComponent from '../components/AuthComponent';
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import Spinner from '../components/Spinner';
import ErrorModal from '../components/ErrorModal';
import CommonStore from '../components/CommonStore';
import { ColoredLine } from '../components/ColoredLine';
import ristorante from '../images/locandaDaLino.jpg';
import piatto from '../images/piatto.jpg';
import libro from '../images/libro.jpg';
import '../styles/LinkPage.css';

import { CURR_NUMSCHI } from '../constants'

import {
    Container, Card, CardBody,
    CardTitle, CardSubtitle, CardImg,
    Input, FormGroup, Form,
    Label, Row, Col, Button
} from 'reactstrap';

const GET_BOOKING_QUERY = gql`
  query getBooking($numschi: String!) {
    getBooking(numschi: $numschi)
  }
`
const SET_BOOKING_MUTATION = gql`
  mutation setBooking($numschi: String!, $booking: [Int!]!) {
    setBooking(numschi: $numschi, booking: $booking) {
        firstName
        lastName
        numschi
    }
  }
`
const PREZZI = { ADULT: 27, CHILD: 17, DISH: 15, BOOK: 5 };

export default class BookingPage extends AuthComponent {
    constructor(props) {
        super(props);

        this.state = BookingPage.emptyState();
        this.currentNumschi = localStorage.getItem(CURR_NUMSCHI);

        this.processData = this.processData.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    static emptyState() {
        return {
            countAdult: 0,
            countChild: 0,
            countDish: 0,
            countBook: 0,
            mybooking: [],
            isMounted: false,
        }
    };

    processData(booking) {

        this.setState({
            countAdult: booking[0],
            countChild: booking[1],
            countDish: booking[2],
            countBook: booking[3],
        });
    }

    handleChange(event) {
        if (event.target && event.target.name) {
            this.setState({
                [event.target.name]: parseInt(event.target.value)
            });
        }
    }

    componentWillUnmount() {
        this.setState({ isMounted: false })
    }
    componentDidMount() {
        this.setState({ isMounted: true }, () => {
            CommonStore.getApolloClient().query({
                fetchPolicy: 'no-cache',
                query: GET_BOOKING_QUERY,
                variables: {
                    numschi: this.currentNumschi
                }
            })
                .then(data => {
                    console.log(data.data.getBooking);
                    if (this.state.isMounted) {
                        this.processData(data.data.getBooking)
                    }
                })
                .catch(error => console.error(error));
        })
    }

    render() {

        return (
            <Container className="LinkPage" >
                <h4>PRENOTAZIONI PER IL CLAN SCHIRATTI 2020</h4>
                <h6>Vuoi prenotare il pranzo da Lino o il piatto commemorativo?</h6>
                <h6>Qui puoi prenotare cliccando sui prodotti. Alla fine di questa pagina, 
                    conferma premendo il bottone sotto la tabella con il totale dei costi.
                    Le <u>prenotazioni</u> vengono salvate sotto il tuo nome e le puoi cambiare 
                    quando vuoi, <u>fino alla fine di maggio</u> prima del raduno.<br /> 
                    Al primo di giugno la lista completa
                    verra' innoltrata a Giuseppe Schiratti che provvedera' alla fabbricazione
                    dei prodotti (libro e piatto) e prenotera' tutti i pranzi da Lino</h6>
                <Mutation mutation={SET_BOOKING_MUTATION}
                    onCompleted={(data) => {
                        console.log("the result:")
                        console.log(data)
                        this.componentDidMount();
                    }}

                >
                    {(setBooking, { loading, error }) => {
                        if (loading) return (<Spinner />);
                        return (
                            <div>
                                {error && <ErrorModal error={error} />}
                                <Form onSubmit={event => {
                                    event.preventDefault();
                                    this.state.mybooking = [this.state.countAdult, this.state.countChild, this.state.countDish, this.state.countBook]
                                    var userInput = {                            
                                            numschi: this.currentNumschi,
                                            booking: this.state.mybooking
                                    }
                                    setBooking({
                                        variables: userInput
                                    });
                                }}>
                                    <Row className="section-panel">
                                        <Col>
                                            <Card>
                                                <CardImg width="100%" src={ristorante} alt="Card image cap" />
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <CardTitle>Pranzo da Lino</CardTitle>
                                                    <CardSubtitle>Quando? Sabato 5 Settembre, 2020</CardSubtitle>
                                                    <CardSubtitle>Dove? Locanda da Lino di Solighetto</CardSubtitle>
                                                    <CardSubtitle>Prezzo? {PREZZI.ADULT} EUR per adulto e {PREZZI.CHILD} EUR per bambino</CardSubtitle>
                                                    <FormGroup>
                                                        <Label for="countAdult">Numero di pasti per adulti</Label>
                                                        <Input type="number" name="countAdult" id="countAdult"
                                                            onChange={(e) => { this.handleChange(e) }} value={this.state.countAdult} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="countChild">Numero di pasti per bambini</Label>
                                                        <Input type="number" name="countChild" id="countChild"
                                                            onChange={(e) => { this.handleChange(e) }} value={this.state.countChild} />
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="section-panel">
                                        <Col>
                                            <Card>
                                                <CardImg width="100%" src={piatto} alt="Card image cap" />
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <CardTitle>Piatto Commemorativo</CardTitle>
                                                    <CardSubtitle>Prezzo? {PREZZI.DISH} EUR</CardSubtitle>
                                                    <CardSubtitle>Il piatto verra consegnato Sabato pomeriggio</CardSubtitle>
                                                    <FormGroup>
                                                        <Label for="countDish">Numero di piatti</Label>
                                                        <Input type="number" name="countDish" id="countDish"
                                                            onChange={(e) => { this.handleChange(e) }} value={this.state.countDish} />
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="section-panel">
                                        <Col>
                                            <Card>
                                                <CardImg width="100%" src={libro} alt="Card image cap" />
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <CardTitle>Libro del Clan Schiratti 2020</CardTitle>
                                                    <CardSubtitle>Prezzo? {PREZZI.BOOK} EUR</CardSubtitle>
                                                    <CardSubtitle>Il libro verra' consegnato Sabato pomeriggio</CardSubtitle>
                                                    <FormGroup>
                                                        <Label for="countBook">Numero di libri</Label>
                                                        <Input type="number" name="countBook" id="countBook"
                                                            onChange={(e) => { this.handleChange(e) }} value={this.state.countBook} />
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <ColoredLine color="#f1efd9" />
                                    <Row>
                                        <Card className='confirmPanel'>
                                            <CardBody>
                                                <CardTitle>
                                                    Carrello
                                                </CardTitle>
                                                <Row>
                                                    <Col><CardSubtitle>Prodotti</CardSubtitle></Col><Col><CardSubtitle>Totale</CardSubtitle></Col>
                                                </Row>
                                                {(this.state.countAdult > 0) && (<Row>
                                                    <Col><CardSubtitle>Numero di pasti per adulti: {this.state.countAdult}</CardSubtitle ></Col>
                                                    <Col>{this.state.countAdult * PREZZI.ADULT} EUR</Col>
                                                </Row>)}
                                                {(this.state.countAdult > 0) && (<Row>
                                                    <Col><CardSubtitle>Numero di pasti per bambini: {this.state.countChild}</CardSubtitle ></Col>
                                                    <Col>{this.state.countChild * PREZZI.CHILD} EUR</Col>
                                                </Row>)}
                                                {(this.state.countAdult > 0) && (<Row>
                                                    <Col><CardSubtitle>Numero di piatti: {this.state.countDish}</CardSubtitle ></Col>
                                                    <Col>{this.state.countDish * PREZZI.DISH} EUR</Col>
                                                </Row>)}
                                                {(this.state.countAdult > 0) && (<Row>
                                                    <Col><CardSubtitle>Numero di libri: {this.state.countBook}</CardSubtitle ></Col>
                                                    <Col>{this.state.countBook * PREZZI.BOOK} EUR</Col>
                                                </Row>)}
                                                <Row><Col><CardSubtitle>Totale</CardSubtitle></Col>
                                                    <Col>
                                                        {(this.state.countAdult * PREZZI.ADULT) +
                                                            (this.state.countChild * PREZZI.CHILD) +
                                                            (this.state.countDish * PREZZI.DISH) +
                                                            (this.state.countBook * PREZZI.BOOK)
                                                        } EUR
                                                        </Col>
                                                </Row>
                                                <Button className='confirmButton' color="secondary" type="submit">Conferma</Button>
                                            </CardBody>
                                        </Card>
                                    </Row>
                                </Form>
                            </div>
                        );
                    }
                    }
                </Mutation>
            </Container>
        );


    }
}