import React from 'react';
import {
    Container, Row, Col
} from 'reactstrap';
import AuthComponent from '../components/AuthComponent';
import gql from "graphql-tag";
import { Query, ApolloProvider } from "react-apollo";
import OrgChart from 'react-orgchart';
import 'react-orgchart/index.css';
import GrandProfile from '../components/GrandProfile';
import '../styles/FamilyTreePage.css';
import TreeNodeProfile from '../components/TreeNodeProfile';
import CommonStore from '../components/CommonStore';
import Spinner from '../components/Spinner';
import ErrorModal from '../components/ErrorModal';


const GET_ALL_USERS_GQL_QUERY = gql`
    query {
    getUsers {
        numschi
        firstName
        lastName
        photo
        year
        deathYear
    }
}
`;

const grandParents = [
    {
        "numschi": "1",
        "firstName": "Giovanni Battista",
        "lastName": "Schiratti",
        "year": -1,
        "deathYear": -1,
        "photo": "TitaO"
    },
    {
        "numschi": "2",
        "firstName": "Emilia",
        "lastName": "Corrà",
        "year": -1,
        "deathYear": -1,
        "photo": "EmiliaO"
    },
    {
        "numschi": "3",
        "firstName": "Pia",
        "lastName": "Corna Pellegrini",
        "year": -1,
        "deathYear": -1,
        "photo": "PiaO"
    },
    {
        "numschi": "4",
        "firstName": "Carolina",
        "lastName": "Sartori",
        "year": -1,
        "deathYear": -1,
        "photo": "CarolinaO"
    },
    {
        "numschi": "5",
        "firstName": "Teresina",
        "lastName": "Polla",
        "year": -1,
        "deathYear": -1,
        "photo": "TeresinaO"
    },
    {
        "numschi": "6",
        "firstName": "Giuseppe",
        "lastName": "Schiratti",
        "year": -1,
        "deathYear": -1,
        "photo": "GiuseppeO"
    },]

export default class FamilyTreePage extends AuthComponent {
    constructor(props) {
        super(props);

        this.state = {
            users: "",
            sortedUsers: [],
            selected: null,
            trees: []
        };
        this.sortData = this.sortData.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.initTrees = this.initTrees.bind(this);
    }

    sortData(data) {
        //sort users
        var users = JSON.parse(JSON.stringify(data.getUsers));

        this.state.sortedUsers = users.sort(function (a, b) {
            let comparison = 0;
            let first = a.numschi.replace(/-10-/g, "A");
            first = first.replace(/-11-/g, "B");
            first = first.replace(/-12-/g, "C");
            first = first.replace(/-13-/g, "D");

            let second = b.numschi.replace(/-10-/g, "A");
            second = second.replace(/-11-/g, "B");
            second = second.replace(/-12-/g, "C");
            second = second.replace(/-13-/g, "D");

            if (first > second) {
                comparison = 1;
            } else if (first < second) {
                comparison = -1;
            }
            return comparison;
        });
    }

    initTrees() {

        if (this.state.trees.length !== 0) return;

        let sTree = [new Tree(), new Tree(), new Tree(), new Tree(), new Tree(), new Tree()];

        for (var i = 0; i < this.state.sortedUsers.length; i++) {
            var ancestor = this.state.sortedUsers[i].numschi[0];

            if (ancestor && ancestor !== '0' && ancestor !== '00') {
                var which = parseInt(ancestor, 10);
                sTree[which - 1].add(this.state.sortedUsers[i]);
            }
        }
        this.state.trees = sTree;

    }

    handleSelection(num) {
        this.setState({ selected: num });
    }

    render() {

        const apolloClient = CommonStore.getApolloClient();
        const MyNodeComponent = ({ node }) => {
            return (
                <div className="initechNode" >
                    <TreeNodeProfile user={node}
                    // handleExpand={(node) => {
                        // var what = node.expand
                        // alert("I have been clicked");
                        // me.render();
                    // }}
                    >
                    </TreeNodeProfile>
                </div>
            );
        };
        console.log("about to return state.users: " + this.state.users);
        return (

            <div className="FamilyTreePage" id="initechOrgChart">
                <Container>
                    <ApolloProvider client={apolloClient}>
                        <Query
                            fetchPolicy='no-cache'
                            query={GET_ALL_USERS_GQL_QUERY}
                            onCompleted={(data) => {

                                this.setState({ data: data })
                            }}  >
                            {({ loading, error, data }) => {
                                if (loading) {
                                    return (
                                        <Spinner />
                                    );
                                } else if (error) {
                                    return (
                                        <ErrorModal error={error} />
                                    );
                                } else {
                                    if (data.getUsers === undefined || (data.getUsers !== undefined && data.getUsers.length === 0)) {
                                        return <div></div>
                                    } else {

                                        this.sortData(data);
                                        this.initTrees();

                                        return (
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <GrandProfile user={grandParents[0]} getSelect={this.handleSelection} ></GrandProfile>
                                                    </Col>
                                                    <Col>
                                                        <GrandProfile user={grandParents[1]} getSelect={this.handleSelection}></GrandProfile>
                                                    </Col>
                                                    <Col>
                                                        <GrandProfile user={grandParents[2]} getSelect={this.handleSelection}></GrandProfile>
                                                    </Col>
                                                    <Col>
                                                        <GrandProfile user={grandParents[3]} getSelect={this.handleSelection}></GrandProfile>
                                                    </Col>
                                                    <Col>
                                                        <GrandProfile user={grandParents[4]} getSelect={this.handleSelection}></GrandProfile>
                                                    </Col>
                                                    <Col>
                                                        <GrandProfile user={grandParents[5]} getSelect={this.handleSelection}></GrandProfile>
                                                    </Col>
                                                </Row>
                                                <div>
                                                    {(this.state.selected) && (<OrgChart tree={this.state.trees[this.state.selected - 1].root} NodeComponent={MyNodeComponent} />)}
                                                </div>
                                            </div>
                                        );
                                    }
                                }
                            }}
                        </Query>
                    </ApolloProvider>
                </Container>

            </div>
        );
    }
}

function Node(data) {
    this.numschi = data.numschi;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.photo = data.photo || "";
    this.year = data.year || "";
    this.deathYear = data.deathYear || "";
    this.children = [];
    this.partners = [];
    this.expand = true;
}

function Tree() {
    this.root = null;
}

function calculateParent(numschi) {
    var parentN = null;
    //capostipiti
    if (numschi !== '0' && numschi !== '00') {
        //already excluded all partners like 6-10-0 
        //this is a family with more than 9 children
        var hyphen = numschi.indexOf('-');
        if (hyphen !== -1) {
            //6-10- or 6-10-1
            if(numschi[numschi.length -1] === '-') {
                parentN = numschi.substring(0, numschi.length - 4);
            } else {
                parentN = numschi.substring(0, numschi.length - 1);
            }            
        } else {
            parentN = numschi.substring(0, numschi.length - 1);//65            
        }
    }
    return parentN;
}

function getPartner(numschi) {
    //ancestor    
    if (numschi === '0') {
        return '';
    }

    var partnerN = '';
    var what = parseInt(numschi[numschi.length - 1], 10)
    if (isNaN(what)) {
        //tenth kid 6-10- not a partner 6-10-0
        if (numschi[numschi.length - 1] === '-') {
            return '';
        } else {
            //multiple partners 610a
            partnerN = numschi.substring(0, numschi.length - 2);
        }
    } else if (what === 0) {
        //partner of a tenth kid 6-10-0 last chars are - and 0 exactly
        //not tenth child 6-10
        if (numschi[numschi.length - 2] === '-') {
            partnerN = numschi.substring(0, numschi.length - 1);
        } else {
            //partner
            partnerN = numschi.substring(0, numschi.length - 1);
        }
    }
    return partnerN
}

Tree.prototype.add = function (data) {
    var node = new Node(data);
    //find out if it is a partner
    var partnerN = getPartner(data.numschi)
    if (partnerN !== '') {
        var partner = this.findBFS(partnerN);
        if (partner) {
            partner.partners.push(node);
        }
    } else {
        //it's a child, find parent
        var toNodeData = calculateParent(data.numschi)
        var parent = toNodeData ? this.findBFS(toNodeData) : null;
        if (parent) {
            parent.children.push(node);
        } else {
            if (!this.root) {
                this.root = node;
            } else if (node.numschi[node.numschi.length - 1] === '0') {
                this.root.partners.push(node);
            } else {
                return 'Root node is already assigned';
            }
        }
    }
};
Tree.prototype.remove = function (data) {
    if (this.root.numschi === data.numschi) {
        this.root = null;
    }

    var queue = [this.root];
    while (queue.length) {
        var node = queue.shift();
        for (var i = 0; i < node.children.length; i++) {
            if (node.children[i].numschi === data.numschi) {
                node.children.splice(i, 1);
            } else {
                queue.push(node.children[i]);
            }
        }
    }
};
Tree.prototype.contains = function (data) {
    return this.findBFS(data) ? true : false;
};
Tree.prototype.findBFS = function (data) {
    var queue = [this.root];
    while (queue.length) {
        var node = queue.shift();
        if (node.numschi === data) {
            return node;
        }
        for (var i = 0; i < node.children.length; i++) {
            queue.push(node.children[i]);
        }
    }
    return null;
};
Tree.prototype._preOrder = function (node, fn) {
    if (node) {
        if (fn) {
            fn(node);
        }
        for (var i = 0; i < node.children.length; i++) {
            this._preOrder(node.children[i], fn);
        }
    }
};
Tree.prototype._postOrder = function (node, fn) {
    if (node) {
        for (var i = 0; i < node.children.length; i++) {
            this._postOrder(node.children[i], fn);
        }
        if (fn) {
            fn(node);
        }
    }
};
Tree.prototype.traverseDFS = function (fn, method) {
    var current = this.root;
    if (method) {
        this['_' + method](current, fn);
    } else {
        this._preOrder(current, fn);
    }
};
Tree.prototype.traverseBFS = function (fn) {
    var queue = [this.root];
    while (queue.length) {
        var node = queue.shift();
        if (fn) {
            fn(node);
        }
        for (var i = 0; i < node.children.length; i++) {
            queue.push(node.children[i]);
        }
    }
};
Tree.prototype.print = function () {
    if (!this.root) {
        return console.log('No root node found');
    }
    var newline = new Node('|');
    var queue = [this.root, newline];
    var string = '';
    while (queue.length) {
        var node = queue.shift();
        string += node.toString() + ' ';
        if (node === newline && queue.length) {
            queue.push(newline);
        }
        for (var i = 0; i < node.children.length; i++) {
            queue.push(node.children[i]);
        }
    }
    console.log(string.slice(0, -2).trim());
};
Tree.prototype.printByLevel = function () {
    if (!this.root) {
        return console.log('No root node found');
    }
    var newline = new Node('\n');
    var queue = [this.root, newline];
    var string = '';
    while (queue.length) {
        var node = queue.shift();
        string += node.toString() + (node !== '\n' ? ' ' : '');
        if (node === newline && queue.length) {
            queue.push(newline);
        }
        for (var i = 0; i < node.children.length; i++) {
            queue.push(node.children[i]);
        }
    }
    console.log(string.trim());
};

