import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Splash from './Splash'
import Home from './Home'
import Raduno2020Page from '../pages/Raduno2020Page'
import Login from './Login'
import Toolbox from './Toolbox'
import Profile from './Profile'
import UpdateUser from './UpdateUser'
import UpdateUserAdmin from './UpdateUserAdmin'
import FamilyTreePage from '../pages/FamilyTreePage'
import AncestorsPage from '../pages/AncestorsPage'
import GrandParentsPage from '../pages/GrandParentsPage'
import PastPage from '../pages/PastPage'
import InMemoriamPage from '../pages/InMemoriamPage'
import EventsPage from '../pages/EventsPage'
import LodgingPage from '../pages/LodgingPage'
import StatisticsPage from '../pages/StatisticsPage'
import VolunteerPage from '../pages/VolunteerPage'
import BookingPage from '../pages/BookingPage'
import InfoPage from '../pages/InfoPage'
import FamousPeoplePage from '../pages/FamousPeoplePage'
import NewBornPage from '../pages/NewBornPage'
import '../styles/Main.css'

// import Roster from './Roster'
// import Schedule from './Schedule'

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"

const Main = () => (
  <main>
    <Switch>
      <Route exact path='/' component={Splash}/>
      <Route exact path='/login' component={Login}/>
      <Route path='/home' component={Home} />
      <Route path='/raduno' component={Raduno2020Page} />
      <Route path="/profile/:id" component ={Profile}  />
      <Route path='/toolbox' component={Toolbox}/>
      <Route path='/updateUser/:id' component={UpdateUser}/>
      <Route path='/updateUserAdmin/:id' component={UpdateUserAdmin}/>
      <Route path='/ancestors' component={AncestorsPage} />
      <Route path='/familytreepage' component={FamilyTreePage} />
      <Route path='/grandparents' component={GrandParentsPage} />
      <Route path='/pastmeetings' component={PastPage} />
      <Route path='/inmemoriam' component={InMemoriamPage} />
      <Route path='/events' component={EventsPage} />
      <Route path='/lodging' component={LodgingPage} />
      <Route path='/statistics' component={StatisticsPage} />
      <Route path='/volunteer' component={VolunteerPage} />
      <Route path='/booking' component={BookingPage} />
      <Route path='/information' component={InfoPage} />
      <Route path='/famouspeople' component={FamousPeoplePage} />
      <Route path='/newborn' component={NewBornPage} />
      {/* <Route path='/schedule' component={Schedule}/> */}
    </Switch>
  </main>
)

export default Main