import React from 'react'
import {
    Container, Form, Table,
    Button, FormGroup, Label, Input
} from 'reactstrap';
import { Link } from 'react-router-dom'
import gql from "graphql-tag";
import { Mutation, Query } from "react-apollo";
import '../styles/Toolbox.css';
import CreateUserForm from './CreateUserForm';
import ListBackupsModal from './ListBackupsModal';
import AuthComponent from './AuthComponent'
import Spinner from './Spinner';
import ErrorModal from './ErrorModal';


const IMPORT_USERS_GQL_MUTATION = gql`
mutation ImportUsers( $members: String!) {
        importUsers(members:$members) {
            numschi
            firstName
            lastName
        }
    }
`;

const GET_ALL_USERS_GQL_QUERY = gql`
    query {
    getUsers {
        numschi
        firstName
        lastName
        email
    }
}
`;

class Toolbox extends AuthComponent {
    constructor(props) {
        super(props);
        this.toggleNewUserForm = this.toggleNewUserForm.bind(this);
        this.toggleListBackupsModal = this.toggleListBackupsModal.bind(this);
        this.turnOffModals = this.turnOffModals.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            showNewUserForm: false,
            showListBackupsModal: false,
            users: ""
        };
    }

    toggleNewUserForm() {
        this.setState({ showNewUserForm: !this.state.showNewUserForm });
    }

    toggleListBackupsModal() {
        this.setState({ showListBackupsModal: !this.state.showListBackupsModal });
    }

    turnOffModals = () => {
        this.setState({ showListBackupsModal: false, showNewUserForm: false, showUpdateUserForm: false });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleRowSelect(key) {
        console.log("handleRowSelect: key " + key);
    }

    render() {
        console.log("about to return state.users: " + this.state.users);

        return (
            <div className='Toolbox'>
                <h4>Admin Page</h4>
                <Container>
                    <Mutation mutation={IMPORT_USERS_GQL_MUTATION} fetchPolicy='no-cache' onCompleted={(data) => {
                        console.log("the result:")
                        console.log(data)
                    }}>
                        {(UploadUsers, { loading, error }) => {
                            if (loading) return (<Spinner />);
                            return (
                                <div>
                                    {error && <ErrorModal error={error} />}
                                    <Form onSubmit={event => {
                                        event.preventDefault();

                                        // this.setState({ submitClicked: true });
                                        // var runningState = { ...this.state };
                                        // runningState['submitClicked'] = true;
                                        console.log("about to submit");

                                        console.log(this.state.users);
                                        var info = { members: this.state.users }
                                        console.log(info);
                                        UploadUsers({
                                            variables: info
                                        });

                                    }}
                                    >
                                        <FormGroup>
                                            <Label for="exampleText">Import All Users</Label>
                                            <Input type="textarea" name="users" id="users" onChange={(e) => { this.handleChange(e) }}
                                                value={this.state.users} />
                                        </FormGroup>
                                        <div className="text-center">
                                            <Button color="primary" type="submit">Submit</Button>
                                        </div>
                                    </Form>
                                </div>)
                        }}
                    </Mutation>
                </Container>
                <Container>
                    <CreateUserForm showform={this.state.showNewUserForm} exitModal={this.turnOffModals} />
                    <Button color="primary" onClick={() => this.toggleNewUserForm()} >Add User</Button>
                    <br />
                    <ListBackupsModal showform={this.state.showListBackupsModal} exitModal={this.turnOffModals} />
                    <Button color="primary" onClick={() => this.toggleListBackupsModal()} >Archived Backups</Button>
                    <Query
                        fetchPolicy='no-cache'
                        query={GET_ALL_USERS_GQL_QUERY}
                        onCompleted={(data) => {

                            this.setState({ data: data })
                        }}  >
                        {({ loading, error, data }) => {
                            if (loading) {
                                return (
                                    <Spinner />
                                );
                            } else if (error) {
                                return (
                                    <ErrorModal error={error} />
                                );
                            } else {
                                if (data.getUsers === undefined || (data.getUsers !== undefined && data.getUsers.length === 0)) {
                                    return <div></div>
                                } else {
                                    var rowList = data.getUsers.map(function (rowData) {
                                        return (
                                            <tr key={rowData.numschi}>
                                                <th scope="row" >
                                                    <Link to={`/updateUserAdmin/${rowData.numschi}`} >{rowData.numschi}</Link>
                                                </th>
                                                <td>{rowData.firstName}</td>
                                                <td>{rowData.lastName}</td>
                                                <td>{rowData.email}</td>
                                            </tr>
                                        );
                                    });

                                    return (
                                        <div>
                                            <Table striped>
                                                <thead>
                                                    <tr>
                                                        <th>Numschi</th>
                                                        <th>Nome</th>
                                                        <th>Cognome</th>
                                                        <th>Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {rowList}
                                                </tbody>
                                            </Table>
                                        </div>
                                    );
                                }
                            }
                        }}
                    </Query>
                </Container>
            </div>
        )
    };
}

export default Toolbox


