import React from 'react';
import {
    Card, CardText, CardBody,
    CardTitle, CardImg, Button
} from 'reactstrap';

import AuthComponent from './AuthComponent';
import '../styles/GrandProfile.css';
import TitaO from '../images/TitaSchiratti/Tita-oval.jpg';
import EmiliaO from '../images/EmiliaCorra/Emilia-oval.jpg';
import PiaO from '../images/PiaCornaPellegrini/Pia-oval.jpg';
import CarolinaO from '../images/CarolinaSartori/Carolina-oval.jpg';
import TeresinaO from '../images/TeresinaPolla/Teresina-oval.jpg';
import GiuseppeO from '../images/GiuseppeSchiratti/Giuseppe-oval.jpg';

export default class GrandProfile extends AuthComponent {

    getImage(numschi) {
        var image;
        switch (numschi) {
            case "1":
                image = TitaO;
                break;
            case "2":
                image = EmiliaO;
                break;
            case "3":
                image = PiaO;
                break;
            case "4":
                image = CarolinaO;
                break;
            case "5":
                image = TeresinaO;
                break;
            case "6":
                image = GiuseppeO;
                break;
            default: image = null;
                break;
        }
        return image;
    }

    render() {

        const image = this.getImage(this.props.user.numschi);

        return (
            <div className="GrandProfile" >
                <Card key={this.props.user.numschi}>
                    <CardBody>
                        <Button onClick={()=>this.props.getSelect(this.props.user.numschi)}>
                            <CardImg top width="100%" src={image} alt="Foto" />
                        </Button>
                        <CardTitle>{this.props.user.firstName}</CardTitle>
                        <CardTitle>{this.props.user.lastName}</CardTitle>
                        <CardText>{this.props.user.year !== -1 ? "" + this.props.user.year : ""}
                            {this.props.user.deathYear !== -1 ? " - " + this.props.user.deathYear : ""}</CardText>
                    </CardBody>
                </Card>
            </div>
        );
    }
}


