import React, { Component } from 'react'
import {
    Button
} from 'reactstrap';
export default class TitaSchiratti extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: false 
        }
    }

    showButton = () => {
        this.setState({ expanded: !this.state.expanded })
    }
    render() {
        return (
            <div><span>
                <h6>Tratto da IL GAZZETTINO</h6>
                    PIEVE DI SOLIGO. Scomparso a 104 anni, Giovanni Battista Schiratti insegnò a non invecchiare.<br /> 
                    Fu tra i fondatori del periodico "L'Azione" di Pieve di Soligo, del periódico diocesano "L'Azione" 
                    negli anni precedenti la prima guerra mondiale. <br />
                    Già anziano s'innamorò dell'alpinismo, che coltivò, 
                    da socio del CAI, con assiduità e passione fino alla bella età di 97 giungendo regolarmente a toccare quota duemila.<br />
                    Sposato, due figli, la sua è stata una ricetta di vita semplice e lineare, sottolineata da frasi del tipo: 
                    "ama i poveri e rispetta le donne". Ma chỉ vuol saperne di più può certo andare a leggere l'opera che scrisse 
                    al compimento del centesimo anno, quando diede alle stampe il libro "Come diventare centenari continuando a fare 
                    gite in montagna". <br />
                    Se ne è andato a 104 anni, dopo una vita intensa in cui ha dato e ricevuto molto.
                    Giovanni Battista Schiratti, ingegnere, appartenente ad una ragguardevole famiglia di Pieve, ha lasciato il suo 
                    nome legato a quei valori di socialità e solidarietà di cui oggi paiono smarriti simbolo e sígnificato.<br />
                    Nipote ex-mater di Giuseppe Toniolo, si formò alla luce degli insegnamenti dello zio, capofila di quel pensiero 
                    cattolico che andava permeando di sé fasce sempre più larghe di popolazione in una fase di rivolgimenti storici 
                    decisivi per il nostro paese. Alla luce di cio' si può dire che Schiratti è stato un uomo eclettico pur nella 
                    coerenza ai principi basilari nei confronti dei quali è rimasto sempre coerente. Memorabile, tra le altre cose, 
                    la sua partecipazione da "pioniere" alla fondazione B.D.D.<br />
                <br />
                    <h6>Il testo che segue è tratto dal libro dello "Zio Tita" di Enrico Vaglieri – pag. 84 – l'inventario che di sé stesso 
                        ha fatto lo Zio a 83 anni circa.</h6> 
                    Riporto quello che Tita ha scritto nella prima pagina del secondo volume del suo diario, all'età di quasi ottantatré anni.
                    Inizio oggi, 1 gennaio 1974, il "Diario degli ultimi anni" della vita che il Signore mi avrà concesso, segnando via via in questo secondo volumetto le circostanze, liete o tristi, più notevoli. Sono e sarò sempre riconoscente a Dio dei tanti suoi doni e mi auguro di morire coi nomi di Gesù e di Maria sulle labbra e nel cuore.
                    Queste candide pagine, vecchio mio, oggi ti doni, perché l'ultime note qui tu vi segni dei tuoi dì venturi! Il diario è il mio confidenza, "vero amico" e gli parlo e scrivo con piena sincerità.
                    Incominciamo, Giuseppina ed io, l'anno nuovo, con un brindisi cui si associa nel pomeriggio anche Tom, mentre Paolo con Maria Grazia e figlioli si trovano a Bardonecchia nell'appartamento testé affittato, a fianco del mio.
                    Il 4 gennaio vado lassù e faccio la solita salita alla Jafferau a 2.785 metri, coi ramponi e le racchette; la neve è alta circa un metro ma tiene abbastanza bene; sono partito da Bardonecchia con un pesante nebbione ma a Fregiusia mi hanno assicurato che
                    da quota 2.400 in su avrei trovato il sole; come infatti avvenne.
                <br />
                <Button onClick={this.showButton}>leggi il resto..</Button>
                {
                    this.state.expanded && 
                    <div>
                        La discesa dalla vetta a Bardonecchia è stata un po' faticosa perché affondavo troppo, e alle sei col buio sono rientrato nell'alloggio e poco dopo col treno ritornai a Torino. Una bella passeggiata!
                        Altro ritorno a Bardonecchia con salita alla Madonnina del Colemion (quanto mi è cara!) il 12 gennaio, e di lassù a punta Seba, con discesa a Chesal dove al bar il signor Guido mi riserva la solita buona accoglienza; continuo la discesa fino a Melezet dove anche il signor Giulio mi fa i complimenti e mi offre un bicchiere di Barbera e infine, però in pulmino, vado a Bardonecchia e di là a Torino.
                        Il 15 gennaio 1974 compio gli ottantatré anni, vado alla Messa per ringraziare il Signore e poi si brinda alla mia salute.
                        Ma m'è venuto il desiderio di fermare un istante la mente,con uno sguardo sommario, su questi anni passati e fare un riepilogo, dai diversi aspetti, sempre a lode di Dio e immensa riconoscenza a Lui.
                        <br />
                        Mi piace qui riportare a esempio:
                        <br />
                        a) I miei genitori - Quale immensa e immeritata grazia ebbi mai col nascere in una famiglia che più retta, cristiana, caritatevole e soave non si potrebbe immaginare; e anche benestante. Mi domando spesso quale merito potrei attribuirmi per alcune opere buone di carità, per l'amore allo studio, per l’assoluta castità prematrimoniale, eccetera, provenendo da un ceppo così sano - e longevo. Cosa sarebbe stato di me se fossi venuto al mondo in una famiglia di perversi, ubriaconi, miserabili o simili? É la vecchia storia dei talenti del Vangelo! Papà e mamma, per i quarantacinque anni della loro vita insieme quaggiù, sempre innamoratissimi, lo zio Gaetano tutto per gli altri; zio Renato e zia Teresa, due santi; zia Maria e zio Giuseppe Toniolo (ora proclamato dalla Chiesa "servo di Dio" ) - benché con noi solo d'autunno - esempi magnifici di bontà e di fede vissuta. E quale fioritura, da simile ceppo, anche dei fratelli e sorelle, dei tanti nipoti e pronipoti! Quando penso a ciascuno di loro, e sono tanti, non ho che da compiacermene.
                        <br />
                        b) La mia salute - Mentre purtroppo quella di Giuseppina, specie in questi ultimi anni, lascia molto a desiderare, il Signore ha voluto concedermi una salute tale per cui sino a oggi, da quando sono venuto al mondo, mai fui colpito da alcuna seria malattia. Rarissime influenze di un paio di giorni, qualche raffreddore, tosse, mal di gola, presto scomparsi. Appetito normale e vorrei dire migliorato con l'età. Pressione sempre a centoventi, pulsazioni a sessanta, per cui affronto i 4.000 e più metri, in montagna (come lo scorso anno sul Monte Rosa - seconda volta) e posso trattenere il respiro sino a centoquaranta secondi. Rarissimo il sudore. Fortunatamente non ho mai fumato sigarette o altro. Sono sempre stato abbastanza parco nel cibo e quasi mai mi sono interessato alla qualità dei cibi. [Racconta un amico di Tita, che, dopo qualche anno che si conoscevano, Tita compiva 70 anni. Un giorno disse che stava partendo per Uscio, in Liguria, per una settimana di cura disintossicante. «Come mai?» gli chiese l'amico. Risposta: «Vedi, Tino, io non ricordo di essere stato male, eppure qualcosa bisogna pur avere. Così ogni 10 anni faccio questa cura per disintossicare l'organismo». Tita, da quando ha compiuto 80 anni fino a poco tempo fa, ha seguito una dieta quasi giornaliera a base di soia. n.d.r.]
                        <br />
                        c) il mio carattere - In generale sono stato quasi sempre sereno e ottimista, anche se qualche volta m'è costata cara. (Oggi però, 15 gennaio '74, vedo le cose di questo mondo piuttosto nere). Non credo di aver mai avuto nemici. Cerco di usare una certa umiltà, anche se talvolta, in casa, male interpretata. Molti conoscenti mi stimano e mi vogliono bene, e non parliamo poi di tutto il clan Schiratti e dei nipoti per i quali lo zio Tita è sinonimo di allegria e di bontà. In fondo, il carattere che Dio mi ha donato è accettabile, anche perché sempre concreto; non ho mai ballato o preso in
                        mano le carte da gioco, per principio, né ricordo di essermi mai arrabbiato.
                        <br />
                        d) I miei principi religiosi - Dal giorno del battesimo a oggi, mi sono sempre tenuto fermo a quella fede cristiana cattolica instillata dai miei genitori, senza mai alcun dubbio o flessione, neanche durante il periodo universitario o nelle disgrazie.
                        Una fede semplice, quasi da contadinello, ma ben radicata non solo nel cuore ma anche nel cervello. Quante volte ho ringraziato il Signore di questo immenso dono e con quale fervore ho praticato per quasi cinquant'anni il primo Venerdì del mese e i Sabato di Maria.
                        Non ho mai pensato al pericolo dell'inferno perché ho sempre avuto l'anima colma di aspirazione al Paradiso, non come premio (che non merito molto) ma per potermi accostare lassù all' Essere Amato, a Maria, ai miei genitori, a Antonio, Maria Valentina, Teresina, Giuseppina, Gina, gli zii santi, eccetera.
                        Coerente coi miei principi, da studente, nel 1912, ho lanciato l'idea con Ceschelli e Concini di fondare il settimanale l'Azione a Vittorio Veneto, e nello stesso anno fondai a Pieve un club operaio cattolico, con monsignor Pierobon.
                        <br />
                        e) Carità - Il signore e i miei genitori mi hanno sempre inspirato l'amore al prossimo e specialmente ai poveri. Mi sono iscritto nel 1909 a Padova alla San Vincenzo e dopo tanti lustri mi trovo ancora, per grazia di Dio, in piena attività caritativa. E così, oltre alla gioia del donare, mi sono confermato che Dio compensa largamente anche quaggiù i doni fatti in suonome!
                        <br />
                        f) Lavoro - Ho sempre amato fortemente il lavoro anche a non più giovane età. Dopo alcuni anni di professione, passai al commercio (laterizi, piastrelle smaltate e, successivamente, prodotti dentali), sempre godendo la fiducia di ditte e di banche. Rammento soprattutto la fiducia della Schei di Vienna nel commettermi l'acquisto di lingotti d'oro per loro dal 1942 al'45. Per troppa bontà qualche volta sono rimasto ingannato
                        <br />
                        g) Servizio militare e guerra '15-18 - Durante gli studi a Padova, prestai servizio al V Genio Militare e divenni Caporale; poi nella guerra mondiale feci quattro anni ininterrotti al fronte da Sottotenente a Maggiore. Prima in Cadore, poi sul Grappa e in Trentino. Dio mi ha sempre assistito.
                        <br />
                        h) Studente - Nel campo degli studi, ho dato molte soddisfazioni ai miei genitori, perché dotato di volontà, di buona memoria. A diciotto anni entrai all'Università e a ventitré, nel luglio del quinto anno, dopo nove esami filati e la laurea (con altri cinque compagni su cento) ero ingegnere civile. Mi servì molto il romitaggio di Miane. Nel liceo a Udine, 1908, a diciassette anni, la presidenza
                        dell'Università Popolare di quella città mi invitò a tenere alcune lezioni tecniche, su aviazione, motori a scoppio e telegrafo senza fili. Mandai a casa il giornale che ne riferiva!
                        <br />
                        i) Castità - Una cosa mi è molto cara: non ho mai messo piede in un tabarin o casa equivoca o entrato in intimità con donna, all'infuori di mia moglie. Come ciò sia avvenuto, Dio solo lo sa; io non me ne faccio un gran merito. C'è stata qualche simpatia, nei limiti della mia onestà, e due volte fui sul punto di cadere, ma il Sacro Cuore mi ha salvato.
                        La mia longevità sana, penso sia anche frutto di un tale comportamento, ispirato dalla mamma.
                        <br />
                        l) Hobby - La montagna (e la sua vita semplice), cavalcare e la cultura; questa mi ha portato al desiderio di conoscere, di viaggiare (com'è nello spirito degli Schiratti) cercando di unire l'utile al dilettevole, non sempre fortunato. È frutto di un dinamismo che ho nel sangue. Amo molto anche la bicicletta, che uso tuttora ogni giorno.
                        <br />
                        m) Morte - Giuseppina mi diceva che la lapide, che ho posto dieci anni fa sul mio loculo a Pieve, è solo per scaramanzia; ma non è vero. Il pensiero della morte, cioè di quel passaggio all'eternità beata (se così piacerà a Dio) mi ha sempre commosso e affascinato. Vorrei solo che Maria Valentina mi ottenesse di non morire improvvisamente, o da solo, in montagna, ma sul mio letto anche con qualche settimana di dolori.
                        <br />In conclusione di tutta questa mia chiacchierata interiore sui vari lati di una lunga esistenza, ritorno al punto di partenza e cioè che la mia vita, dalla nascita, a Pieve, sino a oggi, non fu un insieme di circostanze, liete o tristi, che dipendessero talvolta da me, pur senza merito o colpa; ma fu invece il piano ordinato dalla Costante presenza di Dio in me, e la Sua Volontà in ogni momento dell'esistenza. E sarà così anche per i pochi anni che Dio mi vorrà concedere quaggiù!
                                            Questa è la visione d'insieme del mio viaggio sino a oggi, su questa terra.
                    </div>
                }

            </span></div>
        )
    };
}
