import { ApolloClient } from 'apollo-client';
// import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context'
import { AUTH_TOKEN } from '../constants'
const { createUploadLink } = require('apollo-upload-client')

var uriGQL = "/query";
var uploadLinkOptions = { uri: uriGQL }
if (process.env.NODE_ENV === "development") {
  uriGQL = "http://localhost:8080" + uriGQL;
  uploadLinkOptions = { uri: uriGQL, credentials: 'include' }
}
console.log("gql uri is: " + uriGQL)

var elink = createUploadLink(uploadLinkOptions);

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(AUTH_TOKEN)
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ''
        }
    }
})

const userCache = new InMemoryCache({
    dataIdFromObject: object => object.numschi || null
});

const client = new ApolloClient({
    link: authLink.concat(elink),
    cache: userCache,
    connectToDevTools: true,
});

export default class CommonStore {
    static getApolloClient() {
        return client;
    }
}
