import React, { Component } from 'react';
import SiteSwitcher from './SiteSwitcher'
import Header from './Header'
import Main from './Main'
import CommonStore from './CommonStore';
import { ApolloProvider } from "react-apollo";

class App extends Component {

    render() {
        const apolloClient = CommonStore.getApolloClient();

        return (
            <ApolloProvider client={apolloClient}>
                <SiteSwitcher></SiteSwitcher>
                <Header></Header>
                <Main></Main>
            </ApolloProvider>

        )
    }
}

export default App;
