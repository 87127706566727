import React from 'react';
import * as yup from "yup";
import {
  FormFeedback,
  Input,
  Label
} from 'reactstrap';
import { Field } from 'formik';


import gql from "graphql-tag";

export const LoginYupSchema = yup.object().shape({
  numschi: yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  password: yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

export const customTextInputNoTouch = ({ field, form: { touched, errors }, ...props }) => (
  <div>
    <Input
      invalid={errors[field.name] ? true : false}
      {...field}
      {...props} />
    {errors[field.name] && <FormFeedback>{errors[field.name]}</FormFeedback>}
  </div>
);


export const customTextInput = ({ field, form: { touched, errors }, ...props }) => (
  <div>
    <Input
      invalid={!!(touched[field.name] && errors[field.name])}
      {...field}
      {...props} />
    {touched[field.name] && errors[field.name] && <FormFeedback>{errors[field.name]}</FormFeedback>}
  </div>
);

export const customTextInputWithRef = ({ field, form: { values, touched, errors }, ...props }) => (
  <div>
    <Input
      invalid={!!(touched[field.name] && errors[field.name])}
      disabled={!(values[props.reference].includes(true))}
      {...field}
      {...props} />
    {touched[field.name] && errors[field.name] && <FormFeedback>{errors[field.name]}</FormFeedback>}
  </div>
);

export function CheckboxUrl(props) {
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <Label check>
          <Input
            type="checkbox"
            {...props}
            checked={field.value.includes(props.value)}
            onChange={() => {
              if (field.value.includes(props.value)) {
                const nextValue = field.value.filter(
                  value => value !== props.value
                );
                form.setFieldValue(props.name, nextValue);
              } else {
                const nextValue = field.value.concat(props.value);
                form.setFieldValue(props.name, nextValue);
              }
            }}
          />
          <a href={props.url}>{props.value}</a>
        </Label>
      )}
    </Field>
  );
}

export function Checkbox(props) {
  return (
    <Field name={props.name}>
      {({ field, form, touched }) => (
        <Label check>
          <Input
            type="checkbox"
            {...props}
            checked={field.value.includes(props.value)}
            onChange={() => {
              if (field.value.includes(props.value)) {
                const nextValue = field.value.filter(
                  value => value !== props.value
                );
                form.setFieldValue(props.name, nextValue);
              } else {
                const nextValue = field.value.concat(props.value);
                form.setFieldValue(props.name, nextValue);
              }
            }}
          />
          {props.label}
        </Label>
      )}
    </Field>
  );
}

export function CheckboxWithRef(props) {
  return (
    <Field name={props.name}>
      {({ field, form, touched }) => (
        <Label check>
          <Input
            type="checkbox"
            {...props}
            checked={field.value.includes(props.value)}
            onChange={() => {
              if (field.value.includes(props.value)) {
                const nextValue = field.value.filter(
                  value => value !== props.value
                );
                form.setFieldValue(props.name, nextValue);
              } else {
                const nextValue = field.value.concat(props.value);
                form.setFieldValue(props.name, nextValue);
              }
              const newValue = field.value.includes(props.value);
              if (newValue) {
                form.setFieldValue(props.reference, "");
              }
            }}
          />
          {props.label}
        </Label>
      )}
    </Field>
  );
}


export const SETTINGS_CONSTRAINTS_GQL_QUERY = gql`
{
  instance {
    eventVersion
    constraints {
      id
      validationType
      validations {
        type
        params
      }
    }
  }
}
`

// from: https://codesandbox.io/s/clever-snyder-1u410?fontsize=14
export function createYupSchema(schema, config) {
  const { id, validationType, validations = [] } = config;
  if (!yup[validationType]) {
    return schema;
  }
  let validator = yup[validationType]();
  validations.forEach(validation => {
    const { params, type } = validation;
    if (!validator[type]) {
      return;
    }
    console.log(type, params);
    validator = validator[type](...params);
  });
  schema[id] = validator;
  return schema;
}
