import { Component } from 'react'
import { AUTH_TOKEN, CURR_NUMSCHI } from '../constants'

export default class NonAuthComponent extends Component {

    loggedIn() {
        return localStorage.getItem(AUTH_TOKEN) && localStorage.getItem(CURR_NUMSCHI);
    }

    componentWillMount() {
        console.log("NonAuthComponent componentWillMount");
        if (this.loggedIn()) {
            console.log("NonAuthComponent redirect to home");
            this.props.history.push("/home");
        }
    }

}


