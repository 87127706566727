import React from 'react';
import {
    Card, CardText, CardBody,
    CardTitle, CardImg
} from 'reactstrap';
import AuthComponent from './AuthComponent';
import '../styles/AncestorProfile.css';

import TommasoSchiratti from '../images/Capostipiti/TommasoSchiratti.jpg';
import MariaBurei from '../images/Capostipiti/MariaBurei.jpg';
import GaetanoSchiratti from '../images/Capostipiti/GaetanoSchiratti.jpg';
import RenatoSchiratti from '../images/Capostipiti/RenatoSchiratti.jpg';
import TeresaSchiratti from '../images/Capostipiti/TeresaSchiratti.jpg';
import MariaSchiratti from '../images/Capostipiti/MariaSchirattiToniolo.jpg';
import GiuseppeToniolo from '../images/Capostipiti/GiuseppeToniolo.jpg';
import EmiliaSalvioni from '../images/Famosi/EmiliaSalvioni.jpg';


export default class AncestorProfile extends AuthComponent {
    constructor(props) {
        super(props);
        this.getImage = this.getImage.bind(this);
    }
    getImage(numschi) {
        var image;
        switch (numschi) {
            case "6":
                image = TommasoSchiratti;
                break;
            case "60":
                image = MariaBurei;
                break;
            case "4":
                image = GaetanoSchiratti;
                break;
            case "3":
                image = RenatoSchiratti;
                break;
            case "1":
                image = TeresaSchiratti;
                break;
            case "7":
                image = MariaSchiratti;
                break;
            case "70":
                image = GiuseppeToniolo;
                break;
            case "81":
                image = EmiliaSalvioni;
                break;
            default: image = null;
                break;
        }
        return image;
    }

    render() {
            const user = this.props.user;
            var _this = this;
            if (user.partners.length !== 0) {
                var partnerList = user.partners.map(function (partner) {
                    const pimage = _this.getImage(partner.numschi);
                    return (
                        <Card key={partner.numschi}  className={partner.size}>
                            <CardBody>
                                {_this.props.withphoto && pimage && <CardImg top width="100%" src={pimage} alt={partner.firstName} />}
                                <CardTitle>{partner.firstName}</CardTitle>
                                <CardTitle>{partner.lastName}</CardTitle>
                                <CardText>{partner.year !== -1 ? "" + partner.year : ""}
                                    {partner.deathYear !== -1 ? " - " + partner.deathYear : ""}</CardText>
                                <CardText>{partner.note}</CardText>
                            </CardBody>
                        </Card>
                    );
                });
            }

        const image = this.getImage(this.props.user.numschi);
        return (
            <div className="AncestorProfile" >
                <Card key={this.props.user.numschi} className={this.props.user.size}>
                    <CardBody>
                        {this.props.withphoto && image && <CardImg top width="100%" src={image} alt={this.props.user.firstName} />}
                        <CardTitle>{this.props.user.firstName}</CardTitle>
                        <CardTitle>{this.props.user.lastName}</CardTitle>
                        {<CardText>{this.props.user.year !== -1 ? "" + this.props.user.year : ""}
                            {this.props.user.deathYear !== -1 ? " - " + this.props.user.deathYear : ""}</CardText>}
                        <CardText>{this.props.user.note}</CardText>
                    </CardBody>
                </Card>
                {partnerList}
            </div>
        );
    }
}


