import React from 'react';
import AuthComponent from '../components/AuthComponent';
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import Spinner from '../components/Spinner';
import ErrorModal from '../components/ErrorModal';
import CommonStore from '../components/CommonStore';
import { ColoredLine } from '../components/ColoredLine';
import '../styles/LinkPage.css';

import { CURR_NUMSCHI } from '../constants'

import {
    Container, Card, CardText, CardBody,
    CardTitle, CardSubtitle, Input,
    FormGroup, Form,
    Label, Row, Col, Table, Button
} from 'reactstrap';

const GET_VOLUNTEERS_QUERY = gql`
  query getVolunteers {
    getVolunteers {
        firstName
        lastName
        numschi
        volunteering
    }    
  }
`
const SET_VOLUNTEER_MUTATION = gql`
  mutation setVolunteering($numschi: String!, $volunteering: [String!]!) {
    setVolunteering(numschi: $numschi, volunteering: $volunteering) {
        firstName
        lastName
        numschi
        volunteering
    }
  }
`
const EVENTS = [{ type: 'FADE', count: 10 }, { type: 'MESS', count: 4 }, { type: 'LINO', count: 3 }, { type: 'MUSS', count: 10 }, { type: 'PROS', count: 4 }];

export default class VolunteerPage extends AuthComponent {
    constructor(props) {
        super(props);

        this.state = VolunteerPage.emptyState();
        this.currentNumschi = localStorage.getItem(CURR_NUMSCHI);

        this.processData = this.processData.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
    }

    static emptyState() {
        return {
            countFade: 0,
            countMessa: 0,
            countLino: 0,
            countMussa: 0,
            countProse: 0,
            volontariFade: [],
            volontariMessa: [],
            volontariLino: [],
            volontariMussa: [],
            volontariProse: [],
            myvolunteering: [],
            isMounted: false,
        }
    };

    processData(volunteers) {
        let cFade = 0,
            cMessa = 0,
            cLino = 0,
            cMussa = 0,
            cProse = 0,
            vFade = [],
            vMessa = [],
            vLino = [],
            vMussa = [],
            vProse = [];

        for (var index = 0; index < volunteers.length; index++) {
            let volunteer = { numschi: volunteers[index].numschi, firstName: volunteers[index].firstName, lastName: volunteers[index].lastName };
            if (volunteers[index].volunteering.indexOf(EVENTS[0].type) !== -1) {
                vFade.push(volunteer);
                cFade++;
            }
            if (volunteers[index].volunteering.indexOf(EVENTS[1].type) !== -1) {
                vMessa.push(volunteer);
                cMessa++;
            }
            if (volunteers[index].volunteering.indexOf(EVENTS[2].type) !== -1) {
                vLino.push(volunteer);
                cLino++;
            }
            if (volunteers[index].volunteering.indexOf(EVENTS[3].type) !== -1) {
                vMussa.push(volunteer);
                cMussa++;
            }

            if (volunteers[index].volunteering.indexOf(EVENTS[4].type) !== -1) {
                vProse.push(volunteer);
                cProse++;
            }
        }

        this.setState({
            countFade: cFade,
            countMessa: cMessa,
            countLino: cLino,
            countMussa: cMussa,
            countProse: cProse,
            volontariFade: vFade,
            volontariMessa: vMessa,
            volontariLino: vLino,
            volontariMussa: vMussa,
            volontariProse: vProse
        });
    }

    handleConfirm(event) {
        if (event.target && event.target.name) {
            this.state.myvolunteering.push(event.target.name);            
        }
    }

    componentWillUnmount() {
        this.setState({ isMounted: false })
    }
    componentDidMount() {
        this.setState({ isMounted: true }, () => {
            CommonStore.getApolloClient().query({
                fetchPolicy: 'no-cache',
                query: GET_VOLUNTEERS_QUERY,
            })
                .then(data => {
                    console.log(data.data.getVolunteers);
                    if (this.state.isMounted) {
                        this.processData(data.data.getVolunteers)
                    }
                })
                .catch(error => console.error(error));
        })
    }

    render() {

        return (
            <Container className="LinkPage" >
                <h4>VOLONTARI PER IL CLAN SCHIRATTI 2020</h4>
                <h6>Vuoi dedicare del tempo per rendere il prossimo raduno un successo?</h6>
                <h6>Iscriviti qui cliccando sugli eventi</h6>
                <Mutation mutation={SET_VOLUNTEER_MUTATION}
                    onCompleted={(data) => {
                        console.log("the result:")
                        console.log(data)
                        this.componentDidMount();
                    }}
                    // refetchQueries={[{ query: GET_VOLUNTEERS_QUERY }]}
                >
                    {(setVolunteering, { loading, error }) => {
                        if (loading) return (<Spinner />);
                        return (
                            <div>
                                {error && <ErrorModal error={error} />}
                                <Form onSubmit={event => {
                                    event.preventDefault();
                                    var userInput = {
                                        numschi: this.currentNumschi,
                                        volunteering: this.state.myvolunteering
                                    }
                                    setVolunteering({
                                        variables: userInput
                                    });
                                }}>
                                    <Row className="section-panel">
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <CardTitle>Cena dai Ricci</CardTitle>
                                                    <CardSubtitle>Quando? Venerdi' 4 Settembre, 2020</CardSubtitle>
                                                    <CardSubtitle>Dove? Le Fade</CardSubtitle>
                                                    <CardText>Aiuta a preparare la porchetta, imparando dal famoso cuoco, Luca Ricci.
                                                        Aiuta a tagliare verdure, lavare piatti, allestire la sala e pulire alla fine dell'evento.
                                                    </CardText>
                                                    <CardText>Max Volontari: {EVENTS[0].count}</CardText>
                                                    <FormGroup check>
                                                        <Label check>
                                                            {(this.state.countFade <= EVENTS[0].count) &&
                                                                (<Input type="checkbox" name={EVENTS[0].type} onClick={(e) => { this.handleConfirm(e) }} />)}
                                                            {(this.state.countFade > EVENTS[0].count) && (<Input type="checkbox" name={EVENTS[0].type} disabled />)}
                                                            Clicca qui
                                                        </Label>
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <CardTitle>Lista dei volontari per la Cena dai Ricci</CardTitle>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Numschi</th>
                                                                <th>Nome</th>
                                                                <th>Cognome</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.volontariFade.map(function (user, index) {
                                                                return (
                                                                    <tr key={user.numschi}>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>{user.numschi}</td>
                                                                        <td>{user.firstName}</td>
                                                                        <td>{user.lastName}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="section-panel">
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <CardTitle>Santa Messa e Cimitero</CardTitle>
                                                    <CardSubtitle>Quando? Sabato 5 Settembre, 2020</CardSubtitle>
                                                    <CardSubtitle>Dove? Chiesa di San Martino, Pieve di Soligo</CardSubtitle>
                                                    <CardText>Aiuta il parroco ad allestire la chiesa. Leggi dei brani durante la messa.
                                                        Accompagna un anziano durante la passeggiata al cimitero.
                                                    </CardText>
                                                    <CardText>Max Volontari: {EVENTS[1].count}</CardText>
                                                    <FormGroup check>
                                                        <Label check>
                                                            {(this.state.countMessa <= EVENTS[1].count) &&
                                                                (<Input type="checkbox" name={EVENTS[1].type} onClick={(e) => { this.handleConfirm(e) }} />)}
                                                            {(this.state.countMessa > EVENTS[0].count) && (<Input type="checkbox" name={EVENTS[1].type} disabled />)}
                                                            Clicca qui
                                                        </Label>
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <CardTitle>Lista dei volontari per la Santa Messa e Cimitero</CardTitle>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Numschi</th>
                                                                <th>Nome</th>
                                                                <th>Cognome</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.volontariMessa.map(function (user, index) {
                                                                return (
                                                                    <tr key={user.numschi}>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>{user.numschi}</td>
                                                                        <td>{user.firstName}</td>
                                                                        <td>{user.lastName}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="section-panel">
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <CardTitle>Pranzo da Lino</CardTitle>
                                                    <CardSubtitle>Quando? Sabato 5 Settembre, 2020</CardSubtitle>
                                                    <CardSubtitle>Dove? Locanda da Lino di Solighetto</CardSubtitle>
                                                    <CardText>Aiuta a scegliere il menu per la cena. Telefona i membri per sollecitare una risposta sulla prenotazione.
                                                        Presenta durante il pranzo. Prepara un discorso pertinente alla storia della famiglia e leggilo di fronte a tutta la parentela.
                                                    </CardText>
                                                    <CardText>Max Volontari: {EVENTS[2].count}</CardText>
                                                    <FormGroup check>
                                                        <Label check>
                                                            {(this.state.countLino <= EVENTS[2].count) &&
                                                                (<Input type="checkbox" name={EVENTS[2].type} onClick={(e) => { this.handleConfirm(e) }} />)}
                                                            {(this.state.countLino > EVENTS[2].count) && (<Input type="checkbox" name={EVENTS[2].type} disabled />)}
                                                            Clicca qui
                                                        </Label>
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <CardTitle>Lista dei volontari per il Pranzo da Lino</CardTitle>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Numschi</th>
                                                                <th>Nome</th>
                                                                <th>Cognome</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.volontariLino.map(function (user, index) {
                                                                return (
                                                                    <tr key={user.numschi}>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>{user.numschi}</td>
                                                                        <td>{user.firstName}</td>
                                                                        <td>{user.lastName}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="section-panel">
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <CardTitle>Pomeriggio alle Fade</CardTitle>
                                                    <CardSubtitle>Quando? Sabato 5 Settembre, 2020</CardSubtitle>
                                                    <CardSubtitle>Dove? Susegana</CardSubtitle>
                                                    <CardText>Aiuta ad organizzare giochi e concorsi. Aiuta il fotografo a radunare tutti i parenti per la foto di gruppo
                                                    </CardText>
                                                    <CardText>Max Volontari: {EVENTS[3].count}</CardText>
                                                    <FormGroup check>
                                                        <Label check>
                                                            {(this.state.countMussa <= EVENTS[3].count) &&
                                                                (<Input type="checkbox" name={EVENTS[3].type} onClick={(e) => { this.handleConfirm(e) }} />)}
                                                            {(this.state.countMussa > EVENTS[3].count) && (<Input type="checkbox" name={EVENTS[3].type} disabled />)}
                                                            Clicca qui
                                                        </Label>
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <CardTitle>Lista dei volontari per il pomeriggio alla Mussa</CardTitle>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Numschi</th>
                                                                <th>Nome</th>
                                                                <th>Cognome</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.volontariMussa.map(function (user, index) {
                                                                return (
                                                                    <tr key={user.numschi}>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>{user.numschi}</td>
                                                                        <td>{user.firstName}</td>
                                                                        <td>{user.lastName}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="section-panel">
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <CardTitle>Strada del Prosecco</CardTitle>
                                                    <CardSubtitle>Quando? Domenica 6 Settembre, 2020</CardSubtitle>
                                                    <CardSubtitle>Dove? Di fronte alla farmacia Schiratti di Pieve di Soligo</CardSubtitle>
                                                    <CardText>Aiuta ad organizzare il tour della strada del Prosecco. Stampa la mappa del percorso e distribuiscila ai vari parenti.
                                                        Fai da guida al tour. Aiuta ad effettuare le prenotazioni telefonando ai vari posti di ristoro con il numero dei partecipanti.
                                                        Fai da chaffeur ad un parente.
                                                    </CardText>
                                                    <CardText>Max Volontari: {EVENTS[4].count}</CardText>
                                                    <FormGroup check>
                                                        <Label check>
                                                            {(this.state.countProse <= EVENTS[4].count) &&
                                                                (<Input type="checkbox" name={EVENTS[4].type} onClick={(e) => { this.handleConfirm(e) }} />)}
                                                            {(this.state.countProse > EVENTS[4].count) && (<Input type="checkbox" name={EVENTS[4].type} disabled />)}
                                                            Clicca qui
                                                        </Label>
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <CardTitle>Lista dei volontari per la strada del Prosecco</CardTitle>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Numschi</th>
                                                                <th>Nome</th>
                                                                <th>Cognome</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.volontariProse.map(function (user, index) {
                                                                return (
                                                                    <tr key={user.numschi}>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>{user.numschi}</td>
                                                                        <td>{user.firstName}</td>
                                                                        <td>{user.lastName}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <ColoredLine color="#f1efd9" />
                                    <Row>
                                        <Card className='confirmPanel'>
                                            <CardBody>
                                                <CardTitle>
                                                    Dopo aver fatto la scelta degli eventi, inoltra la tua richiesta premendo il bottone di conferma.
                                                </CardTitle>
                                                <CardSubtitle>
                                                    Una email verra' innoltrata agli interessati che ti contatteranno prima del raduno.
                                                    Grazie per il tuo contributo al Raduno 2020!
                                                </CardSubtitle >
                                                <Button className='confirmButton' color="secondary" type="submit">Conferma</Button>
                                            </CardBody>
                                        </Card>
                                    </Row>
                                </Form>
                            </div>
                        );
                    }
                    }
                </Mutation>
            </Container>
        );


    }
}