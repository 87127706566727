import React from 'react';
import {
    Container, Col, Row, CardDeck,
    Card, CardBody, CardTitle, CardImg
} from 'reactstrap';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Spinner from '../components/Spinner';
import ErrorModal from '../components/ErrorModal';
import MiniProfile from '../components/MiniProfile';
import cimitero from '../images/tombaSchiratti.jpg';
import Preghiera from '../pages/Preghiera';
import AuthComponent from '../components/AuthComponent'
import '../styles/InMemoriam.css'
import '../styles/MiniProfile.css'

const GET_ALL_USERS_GQL_QUERY = gql`
    query {
    getUsers {
        numschi
        firstName
        lastName
        photo
        year
        deathYear
    }
}
`;

export default class InMemoriamPage extends AuthComponent {
    constructor(props) {
        super(props);

        this.state = {
            users: "",
            sortedUsers: []
        };
        this.sortData = this.sortData.bind(this);
    }
    sortData(data) {
        //sort users
        var users = JSON.parse(JSON.stringify(data.getUsers));

        this.state.sortedUsers = users.filter(user => user.deathYear >= 2015);
    }
    render() {
        return (
            <Container className="InMemoriam">
                <h4>IN MEMORIAM</h4>
                <h6>In questa pagina ricordiamo con affetto coloro che ci hanno lasciato</h6>
                <Row className="section-panel">
                    <Col>
                        <CardImg top width="100%" height="100%" src={cimitero} alt="In Memoriam" />
                    </Col>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>Preghiera</CardTitle>
                                <Preghiera></Preghiera>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <div>
                    <Query
                        fetchPolicy='no-cache'
                        query={GET_ALL_USERS_GQL_QUERY}
                        onCompleted={(data) => {

                            this.setState({ data: data })
                        }}  >
                        {({ loading, error, data }) => {
                            if (loading) {
                                return (
                                    <Spinner />
                                );
                            } else if (error) {
                                return (
                                    <ErrorModal error={error} />
                                );
                            } else {
                                if (data.getUsers === undefined || (data.getUsers !== undefined && data.getUsers.length === 0)) {
                                    return <div></div>
                                } else {

                                    this.sortData(data);

                                    var list = this.state.sortedUsers.map(function (child, index) {
                                        return (
                                            <Col sm="6">
                                            <MiniProfile key={child.numschi} numschi={child.numschi}></MiniProfile>
                                            </Col>
                                        );
                                    });

                                    return (
                                        <CardDeck>
                                            {list}
                                        </CardDeck>
                                    );
                                }
                            }
                        }}
                    </Query>
                </div>
            </Container>
        );
    }
}