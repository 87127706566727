import React from 'react';
import {
    Button, Form, FormGroup, Label, Input, FormFeedback, Container, CardImg, CardBody, CardText,
} from 'reactstrap';

import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import Spinner from './Spinner';
import ErrorModal from './ErrorModal';
import UpdatePhoto from './UpdatePhoto';
import AuthComponent from './AuthComponent';
import '../styles/UpdateUser.css'
import CommonStore from './CommonStore';

const UPDATE_USER_GQL_MUTATION = gql`
mutation SaveUser($input: UserInput!) {
    saveUser( input: $input ) {
            firstName
            lastName
        }
    }
`;

const GET_USER_GQL_QUERY = gql`
    query getUser($numschi: String!) {
    getUser(numschi: $numschi){
        numschi
        firstName
        lastName
        year
        marriedYear
        deathYear
        profession
        company
        phone
        email       
        street
        city
        zip
        country
        isAdmin
        hasImage
        photo   
    }
}
`;

export default class UpdateUserAdmin extends AuthComponent {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        // this.handleSubmit = this.handleSubmit.bind(this);
        this.inputValid = this.inputValid.bind(this);
        this.exit = this.exit.bind(this);
        this.state = UpdateUserAdmin.emptyState();
        this.numschi = this.props.match.params.id;
        this.state.numschi = this.props.match.params.id;
        this.state.isMounted = false;

        this.toggleUpdatePhoto = this.toggleUpdatePhoto.bind(this);
        this.turnOffModals = this.turnOffModals.bind(this);

        this.state = {
            showUpdatePhoto: false,
        };

    }

    static emptyState() {
        return {
            numschi: '',
            firstName: '',
            lastName: '',
            year: '',
            marriedYear: '',
            deathYear: '',
            profession: '',
            company: '',
            email: '',
            phone: '',
            street: '',
            city: '',
            country: '',
            zip: '',
            isAdmin: '',
            photo: '',

            invalidNuschiText: null,
            invalidFirstNameText: null,
            invalidLastNameText: null,
            invalidYearText: null,
            invalidMarriedYearText: null,
            invalidDeathYearText: null,
            invalidProfessionText: null,
            invalidCompanyText: null,
            invalidEmailText: null,
            invalidPhoneText: null,
            invalidStreetText: null,
            invalidCityText: null,
            invalidCountryText: null,
            invalidZipText: null,
            invalidisAdminText: null,

            submitClicked: false,
            isMounted: false,
        }
    };

    exit() {
        this.setState(UpdateUserAdmin.emptyState());

        this.props.history.goBack();
    }

    componentWillUnmount() {
        this.setState({ isMounted: false })
    }

    componentDidMount() {
        this.setState({ isMounted: true }, () => {
            CommonStore.getApolloClient().query({
                variables: {
                    numschi: this.props.match.params.id
                },
                fetchPolicy: 'no-cache',
                query: GET_USER_GQL_QUERY,
            })
                .then(data => {
                    console.log(data.data);
                    if (this.state.isMounted) {
                        this.setState({
                            numschi: data.data.getUser.numschi,
                            firstName: data.data.getUser.firstName,
                            lastName: data.data.getUser.lastName,
                            year: data.data.getUser.year,
                            marriedYear: data.data.getUser.marriedYear,
                            deathYear: data.data.getUser.deathYear,
                            profession: data.data.getUser.profession,
                            company: data.data.getUser.company,
                            phone: data.data.getUser.phone,
                            email: data.data.getUser.email,
                            street: data.data.getUser.street,
                            city: data.data.getUser.city,
                            zip: data.data.getUser.zip,
                            country: data.data.getUser.country,
                            isAdmin: data.data.getUser.isAdmin,
                            photo: data.data.getUser.photo
                        });
                    }

                    console.log(data.data);

                })
                .catch(error => console.error(error));
        })
    }


    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        var runningState = { ...this.state };
        runningState[name] = value;

        this.inputValid(runningState);

    }

    toggleUpdatePhoto() {
        this.setState({ showUpdatePhoto: !this.state.showUpdatePhoto });
    }

    turnOffModals = () => {
        this.setState({ showUpdatePhoto: false });
    }

    handlePhoto(newPhoto) {
        this.setState({ photo: newPhoto });
    }

    render() {

        const image = "" + this.state.photo;
        const squirrel = "https://storage.googleapis.com/clanschiratti/squirrel.jpg";

        return (
            <div className="UpdateUser">
                    <Mutation mutation={UPDATE_USER_GQL_MUTATION} fetchPolicy='no-cache'
                        onCompleted={(data) => {
                            // save stuff here if you want, or display a success modal...
                            console.log("the result:")
                            console.log(data)
                            this.exit();
                        }}>
                        {(SaveUser, { loading, error }) => {
                            if (loading) return (<Spinner />);
                            return (
                                <div className="UpdateUser">
                                    <Container className="photo-container">
                                        <CardBody>
                                            {(this.state.photo !== undefined && this.state.photo.length !== 0) && (<CardImg top src={image} alt="Foto" />)}
                                            {(this.state.photo === undefined || this.state.photo.length === 0) && (<CardImg top src={squirrel} alt="Foto" />)}
                                            <UpdatePhoto photo={this.state.photo} numschi={this.state.numschi} path={`updateUser/${this.state.numschi}`} showform={this.state.showUpdatePhoto} exitModal={this.turnOffModals} />
                                            <CardText className="overlay-button-edit-picture" onClick={() => { this.toggleUpdatePhoto() }} >Cambia Foto</CardText>
                                        </CardBody>
                                    </Container>
                                     <Container>
                                        {error && <ErrorModal error={error} />}
                                        <Form onSubmit={event => {
                                            event.preventDefault();

                                            this.setState({ submitClicked: true });
                                            var runningState = { ...this.state };
                                            runningState['submitClicked'] = true;
                                            console.log("about to submit");
                                            if (this.inputValid(runningState)) {
                                                // ok, we can submit! let's setup a cool gql mutation
                                                var userInput = {
                                                    input: {
                                                        numschi: this.state.numschi,
                                                        firstName: this.state.firstName,
                                                        lastName: this.state.lastName,
                                                        email: this.state.email,
                                                        isAdmin: this.state.isAdmin,
                                                        password: this.state.password,
                                                        street: this.state.street,
                                                        zip: this.state.zip,
                                                        city: this.state.city,
                                                        company: this.state.company,
                                                        phone: this.state.phone,
                                                        profession: this.state.profession,
                                                        country: this.state.country,
                                                        marriedYear: this.state.marriedYear,
                                                        deathYear: this.state.deathYear,
                                                        year: this.state.year,
                                                        photo: this.state.photo
                                                    }
                                                }

                                                console.log("GQL to submit:");
                                                console.log(userInput);
                                                // uncomment here to submit
                                                SaveUser({
                                                    variables: userInput
                                                });
                                                this.props.history.push("/toolbox");
                                            }
                                        }}

                                        >

                                            <FormGroup>
                                                <Label for="numschi">Numero Schiratti</Label>
                                                <Input onChange={(e) => { this.handleChange(e) }} invalid={this.state.invalidNumschiText ? true : false}
                                                    type="text" name="numschi" id="numschi" placeholder="Numero Schiratti" value={this.state.numschi} />
                                                {this.state.invalidNumschiText &&
                                                    <FormFeedback>{this.state.invalidNumschiText}</FormFeedback>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="firstName">Nome</Label>
                                                <Input onChange={(e) => { this.handleChange(e) }} invalid={this.state.invalidFirstNameText ? true : false}
                                                    type="text" name="firstName" id="firstName" placeholder="Nome" value={this.state.firstName} />
                                                {this.state.invalidFirstNameText &&
                                                    <FormFeedback>{this.state.invalidFirstNameText}</FormFeedback>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="lastName">Cognome</Label>
                                                <Input onChange={(e) => { this.handleChange(e) }} invalid={this.state.invalidLastNameText ? true : false}
                                                    type="text" name="lastName" id="lastName" placeholder="Cognome" value={this.state.lastName} />
                                                {this.state.invalidLastNameText &&
                                                    <FormFeedback>{this.state.invalidLastNameText}</FormFeedback>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="year">Anno di Nascita</Label>
                                                <Input onChange={(e) => { this.handleChange(e) }} invalid={this.state.invalidYearText ? true : false}
                                                    type="number" name="year" id="year" placeholder="Anno di Nascita" value={this.state.year} />
                                                {this.state.invalidYearText &&
                                                    <FormFeedback>{this.state.invalidYearText}</FormFeedback>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="marriedYear">Anno di Matrimonio</Label>
                                                <Input onChange={(e) => { this.handleChange(e) }} invalid={this.state.invalidMarriedYearText ? true : false}
                                                    type="number" name="marriedYear" id="marriedYear" placeholder="Anno di Matrimonio" value={this.state.marriedYear} />
                                                {this.state.invalidMarriedYearText &&
                                                    <FormFeedback>{this.state.invalidMarriedYearText}</FormFeedback>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="deathYear">Anno di Morte</Label>
                                                <Input onChange={(e) => { this.handleChange(e) }} invalid={this.state.invalidDeathYearText ? true : false}
                                                    type="number" name="deathYear" id="deathYear" placeholder="Anno di Morte" value={this.state.deathYear} />
                                                {this.state.invalidDeathYearText &&
                                                    <FormFeedback>{this.state.invalidDeathYearText}</FormFeedback>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="profession">Profession</Label>
                                                <Input onChange={(e) => { this.handleChange(e) }} invalid={this.state.invalidProfessionText ? true : false}
                                                    type="text" name="profession" id="profession" placeholder="Professione" value={this.state.profession} />
                                                {this.state.invalidProfessionText &&
                                                    <FormFeedback>{this.state.invalidProfessionText}</FormFeedback>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="company">Company</Label>
                                                <Input onChange={(e) => { this.handleChange(e) }} invalid={this.state.invalidCompanyText ? true : false}
                                                    type="text" name="company" id="company" placeholder="Company" value={this.state.company} />
                                                {this.state.invalidCompanyText &&
                                                    <FormFeedback>{this.state.invalidCompanyText}</FormFeedback>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="email">Email</Label>
                                                <Input onChange={(e) => { this.handleChange(e) }} invalid={this.state.invalidEmailText ? true : false}
                                                    type="email" name="email" id="email" placeholder="Email" value={this.state.email} />
                                                {this.state.invalidEmailText &&
                                                    <FormFeedback>{this.state.invalidEmailText}</FormFeedback>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="phone">Telefono</Label>
                                                <Input onChange={(e) => { this.handleChange(e) }} invalid={this.state.invalidPhoneText ? true : false}
                                                    type="phone" name="phone" id="phone" placeholder="Telefono" value={this.state.phone} />
                                                {this.state.invalidPhoneText &&
                                                    <FormFeedback>{this.state.invalidPhoneText}</FormFeedback>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="street">Indirizzo</Label>
                                                <Input onChange={(e) => { this.handleChange(e) }} invalid={this.state.invalidStreetText ? true : false}
                                                    type="street" name="street" id="street" placeholder="Indirizzo" value={this.state.street} />
                                                {this.state.invalidStreetText &&
                                                    <FormFeedback>{this.state.invalidStreetText}</FormFeedback>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="city">Citta'</Label>
                                                <Input onChange={(e) => { this.handleChange(e) }} invalid={this.state.invalidCityText ? true : false}
                                                    type="city" name="city" id="city" placeholder="Citta'" value={this.state.city} />
                                                {this.state.invalidCityText &&
                                                    <FormFeedback>{this.state.invalidCityText}</FormFeedback>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="country">Paese</Label>
                                                <Input onChange={(e) => { this.handleChange(e) }} invalid={this.state.invalidCountryText ? true : false}
                                                    type="country" name="country" id="country" placeholder="Paese" value={this.state.country} />
                                                {this.state.invalidCountryText &&
                                                    <FormFeedback>{this.state.invalidCountryText}</FormFeedback>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="zip">Codice Postale</Label>
                                                <Input onChange={(e) => { this.handleChange(e) }} invalid={this.state.invalidZipText ? true : false}
                                                    type="zip" name="zip" id="zip" placeholder="Codice Postale" value={this.state.zip} />
                                                {this.state.invalidZipText &&
                                                    <FormFeedback>{this.state.invalidZipText}</FormFeedback>}
                                            </FormGroup>
                                            <div className="text-center">
                                                <Button color="primary" type="submit">Modifica</Button>
                                            </div>
                                        </Form>
                                    </Container>
                                </div>);
                        }}
                    </Mutation>
            </div>
        );

    }

    inputValid(runningState) {

        if (!runningState.submitClicked) {
            return true;
        }

        // submit has been clicked, so now validate the settings
        var valid = true;

        if (runningState.numschi) {
            this.setState({ invalidNumschiText: null });
        } else {
            valid = false;
            this.setState({ invalidNumschiText: 'Numero Schiratti non valido.' });
        }
        if (runningState.firstName) {
            this.setState({ invalidFirstNameText: null });
        } else {
            valid = false;
            this.setState({ invalidFirstNameText: 'Nome non valido.' });
        }
        if (runningState.lastName) {
            this.setState({ invalidLastNameText: null });
        } else {
            valid = false;
            this.setState({ invalidLastNameText: 'Cognome non valido.' });
        }
        if (runningState.year) {
            this.setState({ invalidYearText: null });
        } else {
            valid = false;
            this.setState({ invalidYearText: 'Data di nascita non valida.' });
        }
        if (runningState.marriedYear) {
            this.setState({ invalidMarriedYearText: null });
        } else {
            valid = false;
            this.setState({ invalidMarriedYearText: 'Data di matrimonio non valida.' });
        }
        if (runningState.deathYear) {
            this.setState({ invalidDeathYearText: null });
        } else {
            valid = false;
            this.setState({ invalidDeathYearText: 'Data di morte non valida.' });
        }
        if (runningState.email) {
            this.setState({ invalidEmailText: null });
        } else {
            valid = false;
            this.setState({ invalidEmailText: 'Indirizzo email non valido.' });
        }
        if (runningState.phone) {
            this.setState({ invalidPhoneText: null });
        } else {
            valid = false;
            this.setState({ invalidPhoneText: 'Numero di telefono non valido.' });
        }
        if (runningState.street) {
            this.setState({ invalidStreetText: null });
        } else {
            valid = false;
            this.setState({ invalidStreetText: 'Indirizzo non valido.' });
        }
        if (runningState.city) {
            this.setState({ invalidCityText: null });
        } else {
            valid = false;
            this.setState({ invalidCityText: 'Nome della citta\' non valido.' });
        }
        if (runningState.country) {
            this.setState({ invalidCountryText: null });
        } else {
            valid = false;
            this.setState({ invalidCountryText: 'Nome del paese non valido.' });
        }
        if (runningState.zip) {
            this.setState({ invalidZipText: null });
        } else {
            valid = false;
            this.setState({ invalidZipText: 'Codice postale non valido.' });
        }

        return valid;
    }
}


