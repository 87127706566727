import React, { Component } from 'react'
import {
    Button
} from 'reactstrap';
export default class PiaCornaPellegrini extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: false 
        }
    }

    showButton = () => {
        this.setState({ expanded: !this.state.expanded })
    }
    render() {
        return (
            <div><span>
                <h6>Elogio per Pia scritto da Carolina, Tita e Bepi</h6>
                    Nata dopo Antonio, Tita ed Emilia, Pia amava ricordare i mille episodi della sua infanzia serena 
                    tra i genitori, gli zii, i fratelli maggiori di cui condivideva giochi ed interessi nella grande 
                    casa aperta all'amicizia ed all'ospitalità. <br />
                    Il distacco da questi affetti negli anni del collegio le furono di grande sofferenza anche se mantenne 
                    poi per tutta la vita rapporti affettuosissimi con le Suore che l'avevano educata. <br />
                    Fu per lei un grosso dolore anche dover lasciare gli studi che avrebbe dovuto continuare per 
                    laurearsi in farmacia. Forse per questo, era sempre felice di lavorare nella farmacia paterna, 
                    anche quando già sposa ci tornava nell'estate alla sua Pieve. <br />
                    Venuti gli anni della guerra, dopo la rotta di Caporetto, fu tra i primi della famiglia ad andarsene 
                    profuga in Toscana presso gli zii Toniolo, portando in salvo le cose più preziose di casa ed i documenti 
                    importanti. Ma tornò poi per prima con il papà, a guerra finita, coadiuvando efficacemente con la sua 
                    capacità di donna pratica che sa far fronte agli eventi. <br />
                    La sua socievolezza e la simpatia che ispirava la portarono spesso ad essere ospite di parenti a Roma e 
                    in Toscana, dove crebbe e si affinò il suo gusto per le cose belle e per i viaggi. <br />
                    Fu proprio a Pisa che conobbe Camillo Corna Pellegrini. Subito conquistato dalle sue doti e dalla sua 
                    bellezza, Camillo le fece una corte assidua ed in brevissimo tempo la portò sposa a Pisogne. <br />
                    Legatissima alla sua famiglia d'origine e alla sua Pieve, Pia ne sentì sempre profondamente il distacco 
                    della lontananza, anche perché la sua natura vivace e portata alla simpatia, faticava ad adattarsi al 
                    riserbo e alla discrezione della gente bresciana, sia negli anni passati a Pisogne che poi in Brescia 
                    stessa. Forse fu questo uno dei motivi che la portarono a trovare la sua realizzazione nella famiglia, 
                    alla quale dedicava tutta se stessa in una profusione di dono che non venne mai meno. <br />
                    Industriosa ed abile, non si lasciava sgomentare nei momenti difficili. Quando la guerra bloccò 
                    l'attività della industria del marito, Pia aiutò il suo Camillo a far fronte alla situazione non soltanto 
                    con l'appoggio del suo affetto, ma anche superando le difficoltà economiche con i lavori nati dalle 
                    sue mani, attirandosi la simpatia degli abitanti della frazione dove la famiglia era sfollata, per la 
                    sua presenza attiva e sempre stimolante. <br />
                    Più tardi, quando tornò un certo benessere, non ne godette tanto per sé, quanto perché le permetteva 
                    di dare una mano alle giovani famiglie dei suoi figli e a persone che ne avevano bisogno e che perciò 
                    le stavano particolarmente a cuore. <br />

                <Button onClick={this.showButton}>leggi il resto..</Button>
                {
                    this.state.expanded && 
                    <div><span>
                    Subito dopo la guerra, Pia ha vissuto con il suo Camillo i giorni della malattia, della speranza, 
                    della sofferenza. Di lui ha raccolto il testamento: "Pensa tu ai nostri figli" e ne ha fatto lo scopo 
                    della sua vita, allargando le sue cure ed il suo affetto ai numerosi nipotini man mano che arrivavano. <br />
                    Per mantenere questi rapporti familiari caratteristica d'altra parte della sua famiglia di origine - 
                    e poiché i suoi figli sposandosi andavano lontano, si è fatta la "nonna volante" come scherzosamente 
                    qualcuno. la soprannominò; e non soltanto per i Figli, ma per i fratelli, i figli dei fratelli, alcune 
                    persone amiche, portatrice sempre dall'uno all'altro di simpatia, di affetto, di scambio di notizie, 
                    di doni, di ricette e consigli. Questo suo amore, questa sollecita comprensione per gli altri, questo 
                    desiderio di "far piacere" alle persone care possono essere considerati l'espressione saliente della 
                    sua fede; una fede che al di là delle pratiche cristiane di cui pur godeva, Pia ha vissuto come intensa 
                    "pietas", la parte più rilevante della eredità spirituale che ci ha lasciato. <br />
                    Gli anni della vecchiaia e forse anche la malattia incombente hanno riportato talvolta Pia all'antica 
                    severità con cui aveva educato i figli ancora piccoli. Ma come allora la severità si era tramutata via 
                    via in una grandissima fiducia così da dar loro autonomia con una liberalità che anticipava i tempi, 
                    così negli ultimi anni si addolciva nei lavori che fiorivano squisiti dalle sue mani per la gioia di 
                    regalarli un po’ a tutti. <br />
                    Il suo gusto per i viaggi e l'amicizia con cui li giustificava, per le cose belle, per i posti nuovi, 
                    l'interesse per ogni novità sono tratti caratteristici della sua attività, di quella gioia di vivere 
                    che tutti noi ricordiamo. <br />
                    Come avete potuto constatare, i funerali a Pisogne sono stati una vera testimonianza di affetti non 
                    solo da dei figli, dei fratelli e dei numerosi nipoti, ma anche della popolazione di Pisogne che 
                    rammentava mamma Pia e tutti i suoi figlioli, così amorevolmente curati. <br />
                    Tutto questo è un breve riassunto della vita della cara nostra sorella e siamo certi che lo gradirete. <br />
                    
                    </span></div>
                }
                </span>
            </div>
        )
    };
}
