import React, { Component } from 'react';
import {
    Modal, ModalHeader, ModalBody, Form, Button,
    FormGroup
} from 'reactstrap';
import { Formik } from 'formik';

import gql from "graphql-tag";
import { Query, Mutation } from "react-apollo";
import Spinner from './Spinner';
import ErrorModal from './ErrorModal';
import ModalSpinner from './ModalSpinner';
import { CheckboxUrl } from "./FormUtil";


const GET_ALL_BACKUPS_GQL_QUERY = gql`
query {
    backups {
      name
      url
    }
  }
`;

const BACKUP_NOW_MUTATION = gql`
mutation {
    backupUsers {
      name
    }
  }
`;

const DELETE_BACKUPS_MUTATION = gql`
mutation DeleteBackups($names: [String!]!) {
    deleteBackups(names: $names)
  }
`;

const GET_ALL_USERS_GQL_QUERY = gql`
    query {
    getUsers {
        numschi
        firstName
        lastName
        year
        marriedYear
        deathYear
        profession
        company
        phone
        email       
        street
        city
        zip
        country
        isAdmin
        hasImage
        photo 
    }
}
`;

export default class ListBackupsModal extends Component {

    constructor(props) {
        super(props);
        this.exit = this.exit.bind(this);
    }
    exit() {
        this.props.exitModal();
    }

    render() {

        const showform = this.props.showform ? true : false;

        return (
            <Modal isOpen={showform} toggle={this.exit}>
                <ModalHeader toggle={this.exit}>Archived Backups of User Records</ModalHeader>
                <ModalBody>
                    <Query
                        query={GET_ALL_BACKUPS_GQL_QUERY}
                    >
                        {({ loading, error, data }) => {
                            if (loading) {
                                return (
                                    <Spinner />
                                );
                            } else if (error) {
                                return (
                                    <ErrorModal error={error} />
                                );
                            } else {
                                if (data.backups === undefined || (data.backups !== undefined && data.backups.length === 0)) {
                                    return <div></div>
                                } else {
                                    var backups = data.backups;

                                    return (
                                        <Mutation
                                            mutation={DELETE_BACKUPS_MUTATION}
                                            refetchQueries={[
                                                { query: GET_ALL_BACKUPS_GQL_QUERY, variables: {} },
                                            ]}
                                        >
                                            {(submitDeleteMutation, { loading, error }) => {
                                                if (loading) {
                                                    return (<ModalSpinner title="Removing archived backups, please wait" />);
                                                }
                                                return (
                                                    <Formik
                                                        initialValues={{
                                                            list: [],
                                                        }}
                                                        onSubmit={(values, { resetForm }) => {
                                                            console.log("Form onSubmit values:");
                                                            console.log(values);

                                                            submitDeleteMutation({
                                                                variables: {
                                                                    names: values.list,
                                                                }
                                                            });
                                                            resetForm();
                                                        }}
                                                    >
                                                        {({
                                                            handleSubmit,
                                                            setFieldValue,
                                                            handleReset,
                                                            isValid,
                                                        }) => (
                                                                <Form onSubmit={handleSubmit}>
                                                                    {[...backups].map((rowData, i) =>
                                                                        <FormGroup key={i} check>
                                                                            <CheckboxUrl name="list" url={rowData.url} value={rowData.name} />
                                                                            {/* <Field url={rowData.url} name="list" value={rowData.name} type={'checkbox'} component={customCheckboxInput} /> */}
                                                                        </FormGroup>
                                                                    )}
                                                                    <FormGroup>
                                                                        <Button color="primary" type='submit' disabled={!isValid}>Delete Selected Archives</Button>
                                                                    </FormGroup>
                                                                </Form>
                                                            )}
                                                    </Formik>);
                                            }}
                                        </Mutation>

                                    );
                                }
                            }
                        }
                        }
                    </Query>

                    <Mutation
                        mutation={BACKUP_NOW_MUTATION}
                        refetchQueries={[
                            { query: GET_ALL_BACKUPS_GQL_QUERY, variables: {} },
                        ]}
                    >
                        {(submitMutation, { loading, error }) => {
                            if (loading) {
                                return (<ModalSpinner title="Archiving user records, please wait" />);
                            }
                            return (
                                <Form
                                    onSubmit={e => {
                                        console.log("backup now submitted");
                                        e.preventDefault();
                                        submitMutation({ variables: {} });
                                    }}
                                >
                                    <b>Create New Archive Now: </b>
                                    <Button type="submit">Backup</Button>
                                </Form>);
                        }}
                    </Mutation>
                    <Mutation
                        mutation={gql`
                                mutation($file: Upload!) {
                                    uploadUsers(file: $file)
                                }
                            `}
                        refetchQueries={[
                            { query: GET_ALL_USERS_GQL_QUERY, variables: {} }
                        ]}
                        onCompleted={(data) => {
                            console.log("the result of users upload:");
                            console.log(data);
                        }}
                    >
                        {(uploadUsers, { loading, error }) => {
                            if (loading) {
                                console.log("loading: upload users");
                                return (<ModalSpinner title="Uploading users, please wait" />);
                            }

                            if (error) {
                                console.log("loading: error on upload users");
                                return (<ErrorModal error={error} />);
                            }

                            return (
                                <div>
                                    {error && <ErrorModal error={error} />}
                                    <b>Restore From Downloaded Archive: </b>
                                    <input
                                        type="file"
                                        required
                                        onChange={({
                                            target: {
                                                validity,
                                                files: [file]
                                            }
                                        }) => validity.valid && uploadUsers({ variables: { file: file } })}
                                    />
                                </div>
                            );
                        }}
                    </Mutation>

                </ModalBody>
            </Modal>
        );

    }

}


