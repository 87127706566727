import React, { Component } from 'react';

import { Card, CardHeader, CardBody, CardFooter } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';

class ModalSpinner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  render() {
    console.log("render: modal spinner");

    return (
      <Modal isOpen={this.state.modal} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>{this.props.title}</ModalHeader>
        <ModalBody>
          <Card style={{ backgroundColor: 'white', borderColor: 'white' }}>
            <CardHeader style={{ backgroundColor: 'white', borderColor: 'white' }}>&nbsp;</CardHeader>
            <CardBody style={{ backgroundColor: 'white', borderColor: 'white' }}><div className="text-center"><FontAwesomeIcon icon={faSpinner} spin /></div></CardBody>
            <CardFooter style={{ backgroundColor: 'white', borderColor: 'white' }}>&nbsp;</CardFooter>
          </Card>
        </ModalBody>
      </Modal>
    )
  }
}

export default ModalSpinner;


