import React, { Component } from 'react'
import { NavLink } from 'reactstrap'

export default class GiuseppeToniolo extends Component {
    render() {
        return (
            <div><span>
Professore di economia politica, fu uno dei maggiori ideologi della politica dei cattolici italiani e uno degli artefici del loro inserimento nella vita pubblica.
Nacque a Treviso, nella parrocchia di Sant'Andrea, il 7 Marzo del 1845, primo di quattro figli di Antonio Toniolo ed Isabella Alessandrini,
una famiglia della buona borghesia veneta.
Giuseppe frequentò le scuole medie nel Collegio di Santa Caterina - poi "Marco Foscarini" - a Venezia. 
Proseguì gli studi alla Facoltà di Giurisprudenza dell'Università di Padova, dove i suoi maestri furono Fedele Lampertico e Angelo Messedaglia.
Si laureò il 21 giugno 1867. Continuò i suoi studi di economia politica che furono brevemente interrotti dalla morte del padre.
Già l'anno seguente fu nominato assistente alla cattedra giuridico-politica dell'Università di Padova; 
nel 1873 conseguì la libera docenza in Economia politica.
Fu dapprima insegnante presso l'Istituto tecnico di Venezia (dal 1874); dopo una breve supplenza del Messadaglia nell'Università di Padova, 
nel 1878 fu chiamato come professore straordinario di Economia politica nell'Università di Modena e Reggio Emilia. 
Il mattino del 4 settembre 1878 Toniolo sposò Maria Schiratti di Pieve di Soligo. Il matrimonio fu facilitato dai fratelli di Maria, Gaetano e Renato Schiratti.
Dalla loro unione nacquero sette figli.
Rimase a Modena solo un anno: nel 1879 ottenne grazie ad un concorso l'insegnamento della stessa disciplina nell'Università di Pisa.
Per capire a fondo il vero contributo del nostro avo, è necessario dare uno sguardo alla società politica in cui si trovò ad operare. 
Dopo la Rivoluzione Francese ed il periodo napoleonico, che avevano sconvolto la Francia e l’intera Europa e dopo il Congresso di Vienna 
del 1815, si auspicò un ritorno all’antico legame fra la Chiesa e la società civile, che l’Illuminismo aveva incominciato a distinguere.
Ma il potere civile, sostenuto dalle dottrine della sovranità nazionale, diventava sempre più autonomo dalla vita religiosa.
Il mutato rapporto fra autorità civile e autorità religiosa, spinse molti cattolici di vari Paesi d’Europa, ad organizzarsi in movimenti di attiva opposizione alla 
nuova realtà politica e il 20 e 21 agosto 1863, fu organizzato a Malines in Belgio, il primo Congresso Cattolico Internazionale, al quale parteciparono le varie 
Associazioni sorte in Europa, tranne l’Italia rappresentata solo da quattro laici e due monsignori.
Questo perché in Italia tutto fu complicato dalla “Questione Romana”, e in particolare dal potere temporale del papato su una parte del territorio italiano, 
rivendicato dal Regno d’Italia costituitosi nel 1862; creando così una frattura nella coscienza di molti cattolici.
I laici italiani erano aggregati in associazioni limitate alle plurisecolari confraternite, con scopi di una particolare devozione religiosa, mutuo aiuto fra soci e 
attuando opere di carità.
Ormai era tempo di un nuovo associazionismo cattolico e nel 1867 in occasione del terzo Congresso di Malines, la prestigiosa rivista gesuita “La Civiltà Cattolica”, 
incitò i cattolici italiani, a formare associazioni, coalizioni, congressi, perché “questi mezzi sono, posto lo stato presente della società, efficacissimi”, 
non si poteva lasciarli agli avversari del cattolicesimo che se ne avvalevano contro.
E già il 29 giugno 1867, sorse la “Società della Gioventù Cattolica Italiana”, primo nucleo della successiva “Azione Cattolica Italiana”; intanto gli eventi politici 
precipitarono con la breccia di Porta Pia a Roma del 29 settembre 1870, la protesta di papa Pio IX che si chiuse in Vaticano; poi nel 1871 l’Italia emise le Leggi delle 
Guarentigie che assicuravano gli onori sovrani al pontefice e il godimento del Vaticano; nel luglio 1871 Roma divenne capitale d’Italia.
L’11 ottobre 1874 i contrasti non erano per niente finiti e il papa con il “non expedit”, vietò ai cattolici di candidarsi o di recarsi alle urne, trasformato 
nel divieto assoluto (non licet) del 29 gennaio 1877.
Dopo l’Azione Cattolica, sorsero in Italia una miriade di società, pie unioni, circoli, opere sociali, con una conseguente dispersione di energie, che resero 
necessaria la costituzione di un organismo coordinatore nel rispetto delle singole autonomie.
Il 26 settembre 1875, durante il secondo Congresso generale dei cattolici italiani, si creò l’”Opera dei Congressi e dei Comitati cattolici”, il cui primo Presidente 
fu Giovanni Acquaderni, fondatore con il conte Mario Fani, dell’Azione Cattolica.
Nel 1882 Giuseppe Toniolo fu nominato professore ordinario nell'Università di Pisa. Toniolo tenne la cattedra di Economia politica fino alla morte. 
Nel 1883 ebbe tra i suoi allievi a Pisa Werner Sombart, che divenne successivamente una delle massime autorità internazionali di economia politica.
Il 29 dicembre 1889 durante un convegno a Padova, venne costituita l’”Unione cattolica per gli studi sociali”, il cui presidente e 
fondatore fu il professor Giuseppe Toniolo, il quale nel 1893, la dotò del periodico “Rivista internazionale di scienze sociali e discipline ausiliarie”.
Ormai si era in un periodo pieno di fermenti politici, religiosi e culturali; il pensiero marxista spostava l’attenzione sulle condizioni delle masse proletarie, 
denunciandone le disagiate condizioni di vita e di lavoro, inoltre in campo economico, le idee di utilitarismo e di liberismo economico, sostenevano dannoso per la 
stabilità, qualunque intervento che potesse influire sull’azione delle componenti macroeconomiche; senza dimenticare che era il periodo della famosa enciclica 
sociale “Rerum Novarum” di papa Leone XIII, con la quale la Chiesa prendeva ufficialmente posizione in merito alla situazione operaia di quel tempo.
Giuseppe Toniolo, elaborò così una sua teoria, personale, sociologica, affermante il prevalere dell’etica e dello spirito cristiano sulle dure leggi dell’economia.
Propose una soluzione del problema sociale, che rifiutava sia l’“individualismo” del sistema capitalistico, che il “collettivismo” esasperato, propagato dal socialismo, 
attraverso la costituzione di corporazioni di padroni e lavoratori, riconosciute dallo Stato.
Nei suoi numerosi scritti, il Toniolo propose varie soluzioni: il riposo festivo, la limitazione delle ore lavorative, la difesa della piccola proprietà, 
la tutela del lavoro delle donne e dei ragazzi.
Dal punto di vista religioso, Giuseppe Toniolo fu fautore di un'azione più decisa dei cattolici in campo sociale, al fine di una loro determinante partecipazione 
all’evoluzione storica di quegli anni, da qui le sue tante fondazioni.

Dal 1894 divenne uno degli animatori del movimento della “democrazia cristiana”, le cui basi furono esposte nel cosiddetto ‘programma di Milano’, con principi e 
proposte per il rinnovamento in senso cristiano della società.
Nel 1897 l’Opera dei Congressi, controllava 588 Casse Rurali, 668 Società Operaie, 708 Sezioni di giovani, una forza consistente, alla cui ombra sorgevano e si 
sviluppavano molte iniziative di forte impegno sociale.
Fondandosi sui suoi studi di storia economica medioevale della Toscana, oppose ai marxisti l’importanza dei fattori etici e spirituali sullo sviluppo dell’economia e 
difese il valore economico-sociale della religione, conciliando così fede e scienza.
Nel 1908 pubblicò il “trattato di economia sociale”, opera fondamentale per l’incidenza che ebbe sul nuovo movimento sociale cattolico italiano all’inizio del Novecento, 
che ben presto, sviluppò il sindacalismo cattolico (detto ‘bianco’ per distinguerlo da quello diretto da ‘rossi’); i cattolici dopo la sospensione del “non expedit” 
parteciperanno in massa alle lezioni del 1913, ottenendo per la prima volta dopo l’Unità d’Italia, una ventina di deputati cattolici.
Oltre alla sua opera fondamentale già citata, Toniolo scrisse: “La democrazia cristiana” (1900); “Il socialismo nella storia della civiltà“ (1902); 
“L’odierno problema sociologico” (1905); “L’unione popolare tra i cattolici d’Italia” (1908).
Degno sposo e padre di famiglia, professore emerito e apprezzato nell’Università, dirigente e fondatore di opere sociali, scrittore fecondo di economia e sociologia, 
cristiano tutto d’un pezzo e fedele alla Chiesa, stimato dai pontefici del suo tempo, amico e consigliere del Beato Bartolo Longo, nella fondazione del Santuario 
e opere annesse di Pompei; morì fra il cordoglio generale, il 7 ottobre 1918 a Pisa.
Le sue spoglie riposano presso il Duomo di Santa Maria Assunta a Pieve di Soligo, in provincia di Treviso.
Il 7 gennaio 1951 fu introdotta la Causa per la sua beatificazione e il 14 giugno 1971 fu emesso il decreto sulle sue virtù con il titolo di ‘venerabile’.
E' stato beatificato a Roma, Basilica San Paolo Fuori le mura, il 29 aprile 2012.
La sua memoria liturgica è stata fissata al 4 settembre, giorno del matrimonio. <br />
<br />
<i>Bibliografia: <br />
<NavLink target="_blank" href="https://it.wikipedia.org/wiki/Giuseppe_Toniolo">https://it.wikipedia.org/wiki/Giuseppe_Toniolo</NavLink>
<NavLink target="_blank" href="https://www.catholic.org/news/saints/story.php?id=46018">https://www.catholic.org/news/saints/story.php?id=46018</NavLink>
<NavLink target="_blank" href="http://www.santiebeati.it/dettaglio/92613">http://www.santiebeati.it/dettaglio/92613</NavLink>
</i><br />
<i>Video: <br />
<NavLink traget="_blank" href="https://www.youtube.com/watch?v=xzWOQ_gimYI">https://www.youtube.com/watch?v=xzWOQ_gimYI</NavLink>
</i>
            </span></div>
        )
    };
}