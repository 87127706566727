import React from 'react';
import {
    Button, Form, FormGroup, Label, Container, CardImg, CardBody,
} from 'reactstrap';

import gql from "graphql-tag";
import { Mutation, Query } from "react-apollo";
import Spinner from './Spinner';
import ErrorModal from './ErrorModal';
import AuthComponent from './AuthComponent';
import '../styles/UpdateUser.css'
import * as yup from "yup";
import { Formik, Field } from 'formik';
import { customTextInputNoTouch, customTextInputWithRef, Checkbox, CheckboxWithRef } from "./FormUtil";


const UPDATE_USER_GQL_MUTATION = gql`
mutation SaveUser($input: UserInput!) {
    saveUser( input: $input ) {
            firstName
            lastName
        }
    }
`;

const YupSchema = yup.object().shape({
    password: yup.string()
        .min(6, "invalida")
        .max(30, "invalida"),
    firstName: yup.string()
        .min(2, "invalido")
        .max(50, "invalido")
        .required("campo obbligatorio"),
    lastName: yup.string()
        .min(2, "invalido")
        .max(50, "invalido")
        .required("campo obbligatorio"),
    year: yup.number()
        .min(1800, "invalido")
        .max(2050, "invalido"),
    marriedYear: yup.number()
        .min(1800, "invalido")
        .max(2050, "invalido"),
    deathYear: yup.number()
        .min(1800, "invalido")
        .max(2050, "invalido"),
    profession: yup.string()
        .min(2, "invalido")
        .max(200, "invalido"),
    company: yup.string()
        .min(2, "invalido")
        .max(200, "invalido"),
    email: yup.string()
        .min(2, "invalido")
        .max(50, "invalido")
        .email("email invalido"),
    street: yup.string()
        .min(2, "invalido")
        .max(100, "invalido"),
    city: yup.string()
        .min(2, "invalido")
        .max(100, "invalido"),
    zip: yup.string()
        .min(2, "invalido")
        .max(20, "invalido"),
    description: yup.string()
        .min(2, "invalido")
        .max(500, "invalido"),
    phone: yup.string()
        .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, 'Number non valido, prova un numero cosi\' +39 xxx xxxxxx, o cosi\' +1 xxx xxxxxxx'),
    terms: yup.boolean()
        .oneOf([true], 'Must Accept Terms and Conditions'),
});

const GET_USER_GQL_QUERY = gql`
    query getUser($numschi: String!) {
    me {
        numschi
    }
    getUser(numschi: $numschi){
        numschi
        firstName
        lastName
        year
        marriedYear
        deathYear
        profession
        company
        phone
        email       
        street
        city
        zip
        country
        description
        isAdmin
        hasImage
        alive
        female
        photo
        lastModifiedBy {
            firstName
            lastName
            numschi
        }
        lastModifiedDate   
    }
}
`;

export default class UpdateUser extends AuthComponent {
    constructor(props) {
        super(props);

        this.exit = this.exit.bind(this);
        this.numschi = this.props.match.params.id;

    }


    exit() {
        this.props.history.goBack();
    }

    render() {
        // var prepend = "";
        // if (process.env.NODE_ENV === 'development') {
        //     prepend = "http://localhost:8080";
        // }
        const squirrel = "https://storage.googleapis.com/clanschiratti/squirrel.jpg";

        return (
            <div className="UpdateUser">

                <Query
                    query={GET_USER_GQL_QUERY}
                    variables={{ numschi: this.numschi }}
                >
                    {({ loading, error, data }) => {
                        if (loading) {
                            return (
                                <Spinner />
                            );
                        } else if (error) {
                            return (
                                <ErrorModal error={error} />
                            );
                        }

                        var queryData = data;
                        console.log("UpdateUser query data:")
                        console.log(queryData)

                        return (<Mutation mutation={UPDATE_USER_GQL_MUTATION}
                            onCompleted={(data) => {
                                // save stuff here if you want, or display a success modal...
                                console.log("the result:")
                                console.log(data)
                                this.exit();
                            }}
                            refetchQueries={[
                                { query: GET_USER_GQL_QUERY, variables: { numschi: this.numschi } },
                            ]}
                        >
                            {(SaveUser, { loading, error }) => {
                                if (loading) return (<Spinner />);
                                return (
                                    <div className="UpdateUser">
                                        <Container className="photo-container">
                                            <CardBody>
                                                {(queryData.getUser.photo !== undefined && queryData.getUser.photo.length !== 0) && (<CardImg top src={queryData.getUser.photo} alt="Foto" />)}
                                                {(queryData.getUser.photo === undefined || queryData.getUser.photo.length === 0) && (<CardImg top src={squirrel} alt="Foto" />)}
                                            </CardBody>
                                        </Container>
                                        <Container>
                                            {error && <ErrorModal error={error} />}


                                            <Formik
                                                initialValues={{
                                                    firstName: queryData.getUser.firstName,
                                                    lastName: queryData.getUser.lastName,
                                                    year: (queryData.getUser.year === -1) ? '' : queryData.getUser.year,
                                                    marriedYear: (queryData.getUser.marriedYear === -1) ? '' : queryData.getUser.marriedYear,
                                                    deathYear: (queryData.getUser.deathYear === -1) ? '' : queryData.getUser.deathYear,
                                                    profession: queryData.getUser.profession === '-' ? '' : queryData.getUser.profession,
                                                    company: queryData.getUser.company === '-' ? '' : queryData.getUser.company,
                                                    phone: queryData.getUser.phone === '-' ? '' : queryData.getUser.phone,
                                                    email: queryData.getUser.email === '-' ? '' : queryData.getUser.email,
                                                    street: queryData.getUser.street === '-' ? '' : queryData.getUser.street,
                                                    city: queryData.getUser.city === '-' ? '' : queryData.getUser.city,
                                                    zip: queryData.getUser.zip === '-' ? '' : queryData.getUser.zip,
                                                    description: queryData.getUser.description === '-' ? '' : queryData.getUser.description,
                                                    country: queryData.getUser.country === '-' ? '' : queryData.getUser.country,
                                                    updatePassword: [],
                                                    password: "",
                                                    terms: [],
                                                }}
                                                validationSchema={YupSchema}
                                                onSubmit={(values, { resetForm }) => {
                                                    console.log("Form onSubmit values:");
                                                    console.log(values);

                                                    SaveUser({
                                                        variables: {
                                                            input: {
                                                                numschi: this.numschi,
                                                                email: values.email,
                                                                street: values.street,
                                                                zip: values.zip,
                                                                description: values.description,
                                                                city: values.city,
                                                                company: values.company,
                                                                phone: values.phone,
                                                                profession: values.profession,
                                                                country: values.country,
                                                                marriedYear: values.marriedYear === '' ? -1 : values.marriedYear,
                                                                deathYear: values.deathYear === '' ? -1 : values.deathYear,
                                                                year: values.year === '' ? -1 : values.year,
                                                                password: values.password,
                                                            }
                                                        }
                                                    });
                                                    // resetForm();
                                                }}>
                                                {({
                                                    handleSubmit,
                                                    handleReset,
                                                    isValid,
                                                    dirty,

                                                }) => (
                                                        <Form onSubmit={handleSubmit}>
                                                            <FormGroup>
                                                                <Label for="firstName">Nome</Label>
                                                                <Field name="firstName" type={'text'} component={customTextInputNoTouch} disabled />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="lastName">Cognome</Label>
                                                                <Field name="lastName" type={'text'} component={customTextInputNoTouch} disabled />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="year">Anno di Nascita</Label>
                                                                <Field name="year" type={'number'} component={customTextInputNoTouch} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="marriedYear">Anno di Matrimonio</Label>
                                                                <Field name="marriedYear" type={'number'} component={customTextInputNoTouch} />
                                                            </FormGroup>
                                                            {!queryData.getUser.alive && <FormGroup>
                                                                <Label for="deathYear">Anno di Morte</Label>
                                                                <Field name="deathYear" type={'number'} component={customTextInputNoTouch} />
                                                            </FormGroup>}
                                                            <FormGroup>
                                                                <Label for="profession">Professione</Label>
                                                                <Field name="profession" type={'text'} component={customTextInputNoTouch} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="company">Ditta</Label>
                                                                <Field name="company" type={'text'} component={customTextInputNoTouch} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="email">Email</Label>
                                                                <Field name="email" type={'email'} component={customTextInputNoTouch} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="phone">Telefono</Label>
                                                                <Field name="phone" type={'text'} component={customTextInputNoTouch} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="street">Indirizzo</Label>
                                                                <Field name="street" type={'text'} component={customTextInputNoTouch} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="city">Citta'</Label>
                                                                <Field name="city" type={'text'} component={customTextInputNoTouch} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="country">Paese</Label>
                                                                <Field name="country" type={'text'} component={customTextInputNoTouch} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="zip">Codice Postale</Label>
                                                                <Field name="zip" type={'text'} component={customTextInputNoTouch} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="description">Descrizione</Label>
                                                                <Field name="description" type={'textarea'} component={customTextInputNoTouch} />
                                                            </FormGroup>
                                                            
                                                            {/* only allow logged in user to change password for him/her self*/}
                                                            { (queryData.me.numschi === queryData.getUser.numschi) &&
                                                            <div>
                                                            <FormGroup check>
                                                                <CheckboxWithRef name="updatePassword" reference="password" value={true} label={'Cambia password'} />
                                                            </FormGroup>

                                                            <FormGroup>
                                                                <Label for="password">Password Nuova</Label>
                                                                <Field name="password" type={'password'} reference="updatePassword" component={customTextInputWithRef} />
                                                            </FormGroup>
                                                            </div>
                                                            }

                                                            <FormGroup check>
                                                                <Checkbox name="terms" value={true} label={'Accetto che i dati personali contenuti in questa scheda saranno visibili a tutti i membri e solo ai membri del Clan Schiratti.'} />
                                                            </FormGroup>

                                                            <FormGroup>
                                                                <div className="text-center">
                                                                    <Button color="secondary" type='submit' disabled={!isValid || !dirty}>Modifica</Button>
                                                                    {'   '}
                                                                    <Button color="secondary" onClick={() => { this.exit() }}>Cancella</Button>
                                                                </div>
                                                            </FormGroup>
                                                        </Form>)}
                                            </Formik>


                                            <br></br>
                                            L'ultima modifica e' stata effettuata da: {queryData.getUser.lastModifiedBy.lastName}, {queryData.getUser.lastModifiedBy.firstName} ({queryData.getUser.lastModifiedBy.numschi})
                                        <br></br>
                                            Data: {new Date(queryData.getUser.lastModifiedDate).toLocaleString()}
                                            <br></br>
                                        </Container>
                                    </div>);
                            }}
                        </Mutation>);
                    }}
                </Query>
            </div>
        );

    }
}


