
import gql from "graphql-tag";

export const GET_ALL_USERS_GQL_QUERY = gql`
query {
    getUsers {
        numschi
        firstName
        lastName
        year
        marriedYear
        deathYear
        profession
        company
        phone
        email       
        street
        city
        zip
        country
        isAdmin
        hasImage
        alive
        female
        photo
        lastModifiedBy {
            firstName
            lastName
            numschi
        }
        lastModifiedDate
    }
}
`;