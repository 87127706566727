import React, { Component } from 'react';
import { Button } from 'reactstrap';
export default class CarolinaSartori extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: false 
        }
    }

    showButton = () => {
        this.setState({ expanded: !this.state.expanded })
    }
    render() {
        return (
            <div><span>
                <h6>Elogio per Carolina, scritto dai suoi fratelli Tita e Bepi</h6>
                    Il 26 luglio dello scorso anno ci ha lasciato, dopo rapido declino all'età di 83 anni, nostro cognato Luigi Sartori; 
                    il 21 dicembre lo ha seguito, all'età di 82 anni, la moglie Carolina Schiratti - nostra sorella -, come se lunghi anni 
                    trascorsi uniti le rendessero impossibile ormai vivere senza di lui.
                    Pensiamo sia quindi giusto e secondo i loro desideri ricordarli insieme, come insieme li abbiamo sempre visti.<br />
                    Luigi Sartori era nato a Vittorio Veneto da numerosa famiglia; con molti sacrifici seguì gli studi fino alla laurea, 
                    in filosofia prima e giurisprudenza poi. La guerra 15-18 lo vide valoroso combattente, ferito e decorato. <br />
                    Alla fine della guerra proseguì la carriera scolastica e sposò Carolina che gli fu compagna affettuosa ed intelligente 
                    per 54 anni. Fu direttore scolastico a Vittorio Veneto, Montebelluna, Marostica, Venezia, godendo sempre, 
                    da chi lo avvicinava, la stima sul suo lavoro serio ed appassionato. In seguito a concorso, fu ispettore scolastico 
                    a Venezia dove rimase fino alla pensione. Nacquero intanto tre figli. <br />
                    Portatosi a Legnano per essere più vicino ai suoi cari, vi continuò ancora l'attività scolastica 
                    come preside di scuole medie superiori con l'impegno e l'amore degli anni più giovani.<br />
                    Raggiunse il grado di colonnello dell'esercito e fu nominato Cavaliere di Vittorio Veneto.<br />
                    Nella sua attività di cultore delle lettere amò scrivere varie poesie e qualche pubblicazione in prosa 
                    di tipo autobiografico.<br />
                    Lo ricordiamo marito sollecito, padre severo ma affettuoso, uomo energico e a volte bizzarro ma anche 
                    profondamente umano, 
                    amante della montagna e delle bellezze, sensibile alle espressioni della cultura classica, esperto nei problemi 
                    della scuola e dell'insegnamento, esigente con sé prima che con gli altri nell'attuazione dei suoi doveri; schivo e 
                    modesto malgrado il suo valore.<br />
                    La vita di Carolina si può riassumere brevemente: nata in una famiglia già numerosa, educata adeguatamente ai tempi, 
                    fece un buon matrimonio ed infine una tranquilla vecchiaia. Ma dietro queste che possono sembrare le vicende più semplici, 
                    tre capisaldi animarono la sua vita in modo non comune ed ora restano il suo messaggio a noi: la sua fede, la mitezza e 
                    la cacità cristiana.<br />
                    La fede: nella formazione culturale presso le suore della Visitazione di San Vito, oltreché nella sua famiglia, 
                    Carolina aveva appreso nella maniera più nitida il messaggio cristiano che poi nella vita quotidiana tradusse e 
                    maturò. Ogni giorno Gigi e Carolina facevano insieme la lettura di testi spirituali: San Francesco di Sales, 
                    l'imitazione di Cristo, ecc.; quasi quotidiana era per loro la recita del Rosario.     
                <Button onClick={this.showButton}>leggi il resto..</Button>
                {
                    this.state.expanded && 
                    <div>    
                    Ma Carolina amava esprimere la fede 
                    anche in un modo gioioso, come ad esempio quando per mettere in risalto la Domenica, oltre che con la partecipazione 
                    alla Santa Messa e alle funzioni, soleva sottolineare la festa con un dolce in tavola, sia pure dolci semplicissimi come 
                    nei giorni piu' tristi del tempo di guerra. <br />
                    La mitezza e l'umiltà furono forse le doti più salienti, manifestate in ogni suo atto sempre generoso ed altruista: 
                    virtù insite in lei per naturale inclinazione, ma che in lei grandemente aumentarono in forza del suo cristianesimo autentico.<br />
                    <br />
                    Pur essendo dotata di memoria attenta e spesso brillante e di una buona preparazione culturale, Carolina non amava mettersi 
                    in mostra; 
                    cercava anzi di tenersi in disparte dai discorsi di cultura che si tenevano nella loro casa paterna o nella sua. 
                    Tale modestia 
                    non le impedì d'altra parte di essere pienamente partecipe di ogni interesse e attività del marito e dei figli, come pure
                    non le impediva di mostrarsi  
                    cortesemente ferma quando non voleva che in sua presenza si pronunciassero parole o si dicessero cose meno che corrette.
                    Accanto al suo Gigi dotato di una forte personalità, Carolina evitò con amore ogni contrasto e non fece mai apparire nella sua 
                    famiglia e nell'educazione dei figli alcun attrito. Nei vari traslochi, vincendo le difficoltà di inserimento, Carolina entrò 
                    sempre nei nuovi ambienti con dolcezza e modestia, senza dare fastidio a nessuno, tesa solo al bene degli altrı.
                    <br />
                    Anche nel laboratorio Missionario di Legnano, dove lavorò fino agli ultimi tempi, tutti la ricordano tanto attiva quanto 
                    semplice e gentile. <br />Per questa stessa concezione cristiana della vita nell'umiltà, Carolina fu una donna che senza rumore, 
                    e malgrado la sua fragilità fisica, mandava avanti casa e famiglia nella forma più corretta e ordinata, così da dare l'impressione 
                    di qualcosa che funzionava egregiamente anche se non si vedeva chi fosse il regista.
                    <br />
                    La carità cristiana: fu da Carolina sentita e vissuta non soltanto verso la sua famiglia e con le amicizie piu' care;
                    dalla mamma aveva imparato 
                    ad essere sensibile ai bisogni dei poveri; come lei, li aiutò sempre con ogni mezzo, cercando soprattutto di essere loro vicina 
                    per portare conforto e solidarietà ai più sofferenti. Per una decina di anni, nella sua parrocchia di Sant'Elena a Venezia, 
                    fu presidente della San Vincenzo, prodigandosi in opere di bene e coordinandole.<br />
                    Non possiamo dimenticare che Carolina - come sua mamma - fu terziaria francescana ed amò grandemente avere 
                    come modello San Francesco del quale produsse in sé il fervente amore di Dio, la semplicità nel modo di vivere, 
                    la bontà nei rapporti con gli altri.<br />
                    Da queste qualità appena accennate ma profondamente vissute, scaturiva per Carolina un equilibrio eccezionale che dava gioia e 
                    serenità a chỉ le stava vicino.<br />
                    Tra le vecchie foto di casa ve n'è una che la mostra il giorno delle nozze, in abito bianco, nel cortile dietro la farmacia di Pieve. 
                    Essa vi appare con un sorriso incerto, quasi timida per il suo futuro. A 55 anni da quella foto, non ci pare troppo dire che con 
                    umiltà, con fede, con volontà e diligenza, Carolina ha assolto tutti i suoi compiti di donna, di sposa e di madre.<br />
                    La pensiamo ora accanto al suo Gigi, a godere con lui il premio di chỉ ha attuato la volontà di Dio con il massimo impegno in 
                    ogni momento della vita. <br />
                    Questi esempi meravigliosi di vita cristiana vissuta, siano di esempio alle future generazioni del Clan.
                    <br />
                    <h6>Ricordi di Maria Grazia Sartori della sua Nonna</h6> 
                    Due cose mi ricordo della Nonna Carolina dalla mia infanzia o preadolescenza:<br />
                    -Le sue favolose “merende” per noi nipoti, a base di biscotti e di una “bibita” dal colore rosso acceso, che destava 
                    qualche sospetto in mia mamma (i coloranti!), ma che a noi nipoti piaceva tantissimo!<br />
                    -Una frase che pronunciava spesso, alla domenica, quando andavamo a pranzo dai nonni, qui a Legnano. 
                    Se mia sorella ed io cominciavamo a battibeccare sui posti a tavola, la frase era: 
                    “Poco spazio si tiene quando ci si vuol bene!”. <br />
                    Ci ho ripensato, in questi giorni, concludendo che è stato un insegnamento prezioso, troppo raramente  messo in pratica; 
                    e una frase davvero sua, sempre pronta a diffondere sentimenti buoni tra di noi.
                    </div>
                }
            </span></div>
        )
    };
}


