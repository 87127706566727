import React from 'react';
import {
    Card, CardBody, CardTitle,
    CardText, Col, Row, Container
} from 'reactstrap';
import AuthComponent from '../components/AuthComponent'
import '../styles/LinkPage.css'

export default class EventsPage extends AuthComponent {

    render() {
        const style = {
            width: '100%',
            height: '100%',
            frameborder: '0',
            border: "0",
            allowfullscreen: true
        }
        return (
            <Container className="LinkPage">
                <h4>PROGRAMMA CLAN SCHIRATTI 2020</h4>
                <h6>Qui sono elencati tutti gli eventi del raduno con data, orario e mappa del luogo</h6>
                <Row className="section-panel">
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>Venerdì 4 Settembre, 2020</CardTitle>
                                <CardTitle>Serata alle Fade della famiglia Ricci</CardTitle>
                                <CardText>Inizio ore 17:00</CardText>
                                <CardText>Cena con porchetta, concorso a quiz su genealogia e storia di famiglia</CardText>
                                <CardText><br></br>Si prega di contribuire al costo con una donazione</CardText>
                                <br></br>
                                <CardText>Le Fade
                                <br></br>Via Cucco, 27, 31058 Susegana TV
                                <br></br>+39 335 822 2104
                                <br></br>lefade.com</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <CardBody>
                                <iframe title="ven417" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1126.4046478953283!2d12.208718487921951!3d45.88048171788441!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4779163f66a904b5%3A0x96fa5257b2b7a!2sLe+Fade!5e0!3m2!1sen!2sit!4v1556004604792!5m2!1sen!2sit"
                                    style={style}>
                                </iframe>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className="section-panel">
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>Sabato 5 Settembre, 2020</CardTitle>
                                <CardTitle>Santa Messa e visita al cimitero</CardTitle>
                                <CardText>Inizio ore 10:30</CardText>
                                <CardText>La Santa Messa verra' celebrata nella Chiesa di San Martino, 
                                    vicino al cimitero di Pieve di Soligo. 
                                    <br></br>Si prega di parcheggiare di fronte al cimitero in via Refrontolo.
                                </CardText>
                                <br></br>
                                <CardText>Chiesa di San Martino
                                    <br></br>31053 Pieve di Soligo TV</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <CardBody>
                                <iframe title="sab510" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2777.7784768978863!2d12.17701105122929!3d45.9060770790061!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4779170bc930a441%3A0x3389ab6a43f23c24!2sChiesa+di+San+Martino%2C+31053+Pieve+di+Soligo+TV!5e0!3m2!1sen!2sit!4v1556008452969!5m2!1sen!2sit"  
                                    style={style}>
                                </iframe>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className="section-panel">
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>Sabato 5 Settembre, 2020</CardTitle>
                                <CardTitle>Pranzo al ristorante “da Lino” di Solighetto</CardTitle>
                                <CardText>Inizio ore 13:00</CardText>
                                <CardText>
                                    Pranzo con tutti i parenti e discorsi dei decani.
                                    <br></br>Si prega di prenotare il pranzo usando il carrellino di questo sito.
                                    <br></br>Alternativamente contattate Giuseppe Schiratti
                                    email: clanschiratti2015@icloud.com
                                    telefono: 0438 980073
                                </CardText>
                                <br></br>
                                <CardText>Locanda da Lino
                                <br></br>31053 Solighetto, Treviso
                                <br></br>+39 0438 82150
                                <br></br>locandadalino.it</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <CardBody>
                                <iframe title="sab513" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2775.7002275046416!2d12.160668951229685!3d45.917302979006415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47791765a048f48f%3A0x260f533939f15094!2sLocanda+da+Lino!5e0!3m2!1sen!2sit!4v1556008726913!5m2!1sen!2sit"
                                    style={style}>
                                </iframe>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className="section-panel">
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>Sabato 5 Settembre, 2020</CardTitle>
                                <CardTitle>Alle Fade</CardTitle>
                                <CardText>Dopo il pranzo nel pomeriggio</CardText>
                                <CardText>
                                    Alle Fade verra' scattata la foto ricordo di tutti i parenti. 
                                    Ci saranno giochi, concorso “Miss Scoiattola” ed anguriata.
                                    <br></br>                                   
                                </CardText>
                                <br></br>
                                <CardText>Le Fade
                                <br></br>Via Cucco, 27, 31058 Susegana TV
                                <br></br>+39 335 822 2104
                                <br></br>lefade.com</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <CardBody>
                            <iframe title="sab5pom" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2776.454243433461!2d12.164465051229222!3d45.90222727900606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4779179e7dbe31ed%3A0xf81f54c84795e763!2sVia+Gaetano+Schiratti%2C+107%2C+31053+Pieve+di+Soligo+TV!5e0!3m2!1sen!2sit!4v1556010116105!5m2!1sen!2sit"
                                    style={style}>
                                </iframe>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className="section-panel">
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>Domenica 6 Settembre, 2020</CardTitle>
                                <CardTitle>La Strada del Prosecco</CardTitle>
                                <CardText>Inizio ore 10:00</CardText>
                                <CardText>
                                    Giovanni Schiratti ci portera' a percorrere la strada del Prosecco con soste 
                                    in osterie e vari luoghi storici.
                                    Si prega di arrivare puntuali al parcheggio di fronte alla farmacia Schiratti, da
                                    dove iniziera' la gita. Si consiglia di condividere le macchine.                                   
                                </CardText>
                                <br></br>
                                <CardText>Farmacia Schiratti
                                <br></br>Piazza Balbi Valier, 6, 
                                <br></br>31053 Pieve di Soligo TV
                                <br></br>Giovanni: +39 368 287 100</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <CardBody>
                                <iframe title="dom610" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2776.5533594979443!2d12.171322151229132!3d45.900245279006015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477917a197710d57%3A0x90fb061275f3e436!2sFarmacia+Schiratti!5e0!3m2!1sen!2sit!4v1556011081131!5m2!1sen!2sit"
                                    style={style}>
                                </iframe>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* Programma
:
- 
sabato 5 settembre:
- ore 10:30: Santa Messa
- ore 11:00: visita in cimitero
- ore 13:00: pranzo al ristorante “da Lino” di Solighetto
- dopo pranzo: alla Mussa per foto ricordo, giochi, concorso “Miss Scoiattola” ed
anguriata
- la sera, eventi a scelta ed a gruppi, da decidere
domenica 6 settembre:
- in mattinata, gita sulla strada del Prosecco
Pernottamenti
Le prenotazioni vanno fatte direttamente da chi verrà; allego un un’elenco degli
alberghi di Pieve di Soligo e dintorni; in quel periodo, i prezzi partono dai 55 euro a
notte.
Nel 2010, per la prima volta, non è stato allestito un campeggio; se a qualcuno
interessasse prego di farmelo sapere nel giro di una settimana; se vi sarà un
numero di interessati sufficiente, verificherò se si può organizzare.
Pranzo
Il pranzo di sabato sarà da Lino a Solighetto; siamo riusciti a mantenere lo stesso
prezzo di cinque e di dieci anni fa, e cioè € 27,00 per gli adulti ed € 17,00 per i
bambini; avrei bisogno di sapere entro maggio quante persone verranno al pranzo.
Piatto ricordo e libro del Clan
Prego di comunicarmi, sempre entro maggio, quante persone desiderano
partecipare al pranzo del 5 settembre e quanti vogliono il piatto ricordo ed il libro
del Clan.
Per libro e piatto il costo dipenderà naturalmente dal numero di prenotazioni
(comunque dovrebbero essere circa 15 euro per il piatto e circa 5 euro per il libro).
Il libro del Clan contiene due sezioni principali: l’albero genealogico ed un
annuario con informazioni e contatti su ciascun nucleo familiare.
Per aggiornare l’albero genealogico ho bisogno che mi vengano comunicate le
variazioni che ci sono state negli ultimi cinque anni; quindi matrimoni e nascite,
ovvero separazioni e decessi, con le date; da quest’anno vorrei inserire nell’albero
genealogico anche le fotografie dei membri; chi desidera che appaia la propria foto
dovrebbe inviarmene una, in qualsiasi formato.
Per aggiornare l’annuario, ho poi bisogno, sempre entro maggio, di conoscere le
novità che riguardano ogni gruppo; per comodità ti invio la scheda dei nuclei
familiari del tuo gruppo come appariva dell'annuario del 2010; per aggiornare ed
integrare i dati avrò bisogno di conoscere:
- la composizione di ogni nucleo familiare convivente e l'indirizzo;
- per i componenti del nucleo, occupazione, eventuali interessi ed i contatti (numeri
di telefono ed indirizzi email od altro).
Da quest’anno, però, nell’annuario ci saranno solo coloro che forniranno dati
per l’aggiornamento o che chiederanno espressamente di essere nell’annuario;
quindi, anche se non ci fosse stata alcuna variazione rispetto al 2010, prego
comunque tutti i capifamiglia (od i singoli) di confermare che vogliono essere
presenti nell’annuario del Clan; se da qualcuno non riceverò nuovi dati o
comunque una richiesta di essere presente nell’annuario, intenderò che
preferisce non esservi e non lo inserirò nell’annuario.
Contatti
Prenotazioni ed informazioni possono essere spedite per posta a questo indirizzo:
Giuseppe Schiratti
piazza Balbi Valier, 5
31053 Pieve di Soligo
oppure inviate per email all'indirizzo:
clanschiratti2015@icloud.com
In ogni caso, potete chiamare al numero 0438 980073 in orario di ufficio. */}
            </Container>
        );
    }
}