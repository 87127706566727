import React, { Component } from 'react';
import {
    NavLink, Navbar
} from 'reactstrap';
import '../styles/SiteSwitcher.css';
import { AUTH_TOKEN, CURR_NUMSCHI, IS_ADMIN } from '../constants'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

class SiteSwitcher extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        this.loginCheck = this.loginCheck.bind(this);
        var whichLink = window.location.pathname
        if(whichLink ==="/raduno" || whichLink ==="/lodging" 
        || whichLink ==="/volunteer" || whichLink ==="/booking") {
            this.state = {
                home: "",
                raduno: "selectedLink",
            };
        } else {
            this.state = {
                home: "selectedLink",
                raduno: "",
            };
        }
        
    }
    loginCheck() {
        console.log("render, loginCheck");
        let sessionCookie = this.props.cookies.get('jsauth');
        if (sessionCookie === undefined) {
            console.log("session cookie is undefined, go to /login page");
            localStorage.clear();
            return false;
        }

        var json = atob(sessionCookie);
        console.log("session json from server:")
        console.log(json);

        var value = JSON.parse(json);
        // console.log(value);

        var expiration = new Date(value.expiration);
        // console.log(expiration.toLocaleString());

        var now = new Date();
        // console.log(now.toLocaleString());

        if (expiration < now) {
            console.log("session cookie has expired, go to /login page");
            localStorage.clear();
            return false;
        }

        localStorage.setItem(AUTH_TOKEN, sessionCookie);
        localStorage.setItem(CURR_NUMSCHI, value.numschi);
        localStorage.setItem(IS_ADMIN, value.isAdmin);

        return true;
    }

    render() {

        return (
            <div className="SiteSwitcher">
                {(this.loginCheck()) && (<Navbar bsstyle="tabs">
                    <NavLink href="/home" name="home"
                        className={this.state.home} >
                        Clan Schiratti
                    </NavLink>
                    <NavLink href="/raduno" name="raduno"
                        className={this.state.raduno} >
                        Raduno 2021
                    </NavLink>
                </Navbar>)}
            </div>
        );
    }
}
export default withCookies(SiteSwitcher);