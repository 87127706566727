import React from 'react';

import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Row, Col,
    Button, Container
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import {
    Link
} from 'react-router-dom'
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Spinner from './Spinner';
import ErrorModal from './ErrorModal';
import MiniProfile from './MiniProfile';
import ChildrenProfile from './ChildrenProfile';
import UpdatePhoto from './UpdatePhoto';
import '../styles/Profile.css';
import AuthComponent from './AuthComponent';
import squirrel from '../images/squirrel.png';

const GET_USER_GQL_QUERY = gql`
  query getUser($numschi: String!) {
    getUser(numschi: $numschi){
        numschi
        parentNumschi
        firstName
        lastName
        year
        marriedYear
        deathYear
        profession
        company
        phone
        email       
        street
        city
        zip
        country
        description
        isAdmin
        photo
        canEdit
    }
  }
`;

export default class Profile extends AuthComponent {
    constructor(props) {
        super(props);
        this.props = props;

        this.toggleUpdatePhoto = this.toggleUpdatePhoto.bind(this);
        this.turnOffModals = this.turnOffModals.bind(this);

        this.state = {
            showUpdatePhoto: false,
        };
    }

    toggleUpdatePhoto() {
        this.setState({ showUpdatePhoto: !this.state.showUpdatePhoto });
    }

    turnOffModals = () => {
        this.setState({ showUpdatePhoto: false });
    }

    render() {
        // this numschi will be a member or partner
        this.numschi = this.props.match.params.id

        console.log("numschi:" + this.numschi + ";partner:" + this.partner + ";childrenNumschi:" + this.children);
        return (
            <Container className="Profile">
                <Query
                    key={this.numschi}
                    // fetchPolicy='no-cache'
                    query={GET_USER_GQL_QUERY} variables={{ numschi: this.numschi }}
                    onCompleted={(data) => {
                        console.log("Profile onCompleted");
                        console.log(data.getUser);
                    }}  >
                    {({ loading, error, data }) => {
                        if (loading) {
                            return (
                                <Spinner />
                            );
                        } else if (error) {
                            return (
                                <ErrorModal error={error} />
                            );
                        } else {
                            console.log("Profile.js data.getUser:")
                            console.log(data.getUser)
                            if (data.getUser === null || data.getUser === undefined) {
                                this.props.history.push('/login');
                                alert("L'utente e' inesistente.");
                                return <div></div>;
                            }
                            // var prepend = "";
                            // if (process.env.NODE_ENV === 'development') {
                            //     prepend = "http://localhost:8080";
                            // }
                            // const image = prepend + "/serve/?blobKey=" + data.getUser.photo;
                            const image = "" + data.getUser.photo;

                            return (
                                <div>
                                    <Row className="section-panel">
                                        <Col sm="6">
                                            <Card>
                                                <CardBody>
                                                    {data.getUser.parentNumschi &&
                                                        <Link color="btn btn-light" to={`/profile/${data.getUser.parentNumschi}`} className="inactive">
                                                            <Button><b>Suo Genitore <FontAwesomeIcon icon={faAngleUp} /></b></Button>
                                                        </Link>}
                                                    {data.getUser.photo.length !== 0 && (<CardImg top src={image} alt="Foto" />)}
                                                    {data.getUser.photo.length === 0 && (<CardImg top src={squirrel} alt="Foto" />)}

                                                    {data.getUser.canEdit &&
                                                        (<UpdatePhoto photo={data.getUser.photo} numschi={data.getUser.numschi} partner={this.partner} path={`profile/${data.getUser.numschi}`} showform={this.state.showUpdatePhoto} exitModal={this.turnOffModals} />)}
                                                    {data.getUser.canEdit &&
                                                        (<Button onClick={() => { this.toggleUpdatePhoto() }} ><b>Carica Foto</b></Button>)}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col sm="6">
                                            <Card>
                                                <CardBody>
                                                    <CardTitle>
                                                        {data.getUser.firstName} {data.getUser.lastName} &nbsp; &nbsp; &nbsp; {data.getUser.numschi}</CardTitle>
                                                    <CardSubtitle>{data.getUser.profession}</CardSubtitle>
                                                    <CardText>{data.getUser.company}</CardText>
                                                    <CardText>{(data.getUser.year === -1) ? "" : data.getUser.year}{(data.getUser.deathYear === -1) ? "" : " - " + data.getUser.deathYear}</CardText>
                                                    <CardText>{data.getUser.phone}</CardText>
                                                    <CardText>{data.getUser.email}</CardText>
                                                    <CardText>{data.getUser.street}</CardText>
                                                    <CardText>{data.getUser.city}</CardText>
                                                    <CardText>{data.getUser.zip}</CardText>
                                                    <CardText>{data.getUser.country}</CardText>
                                                    <CardText type="textArea" >{data.getUser.description}</CardText>
                                                    {data.getUser.canEdit &&
                                                        <Link to={`/updateUser/${data.getUser.numschi}`} className="inactive"><Button><b>Modifica</b></Button></Link>
                                                    }
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="section-panel">
                                        <Col sm="6">
                                            {data.getUser.numschi === "6" &&
                                                <MiniProfile numschi={"60a"} isPartner={false}></MiniProfile>}
                                            {data.getUser.numschi === "6" &&
                                                <MiniProfile numschi={"60b"} isPartner={false}></MiniProfile>}
                                            {data.getUser.numschi !== "6" &&
                                                <MiniProfile numschi={this.numschi} isPartner={true}></MiniProfile>}
                                        </Col>
                                        <Col sm="6">
                                            <ChildrenProfile numschi={this.numschi} ></ChildrenProfile>
                                        </Col>
                                    </Row>
                                </div>
                            );
                        }
                    }
                    }
                </Query>
            </Container>
        );
    }
}


