import React from 'react';
import {
    Card, CardImg, CardBody, Container,
    CardTitle, CardSubtitle, Row
} from 'reactstrap';
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import Spinner from './Spinner';
import ErrorModal from './ErrorModal';
import '../styles/MiniProfile.css';
import AuthComponent from './AuthComponent';
import squirrel from '../images/squirrel.png';

const GET_USER_GQL_QUERY = gql`
  query getUser($numschi: String!, $isPartner: Boolean!) {
    getUser(numschi: $numschi, isPartner: $isPartner){
        numschi
        firstName
        lastName
        year
        deathYear
        photo   
    }
  }
`;

export default class MiniProfile extends AuthComponent {

    render() {
        const numschi = "" + this.props.numschi;
        const isPartner = this.props.isPartner ? true : false;
        return (
            <div>
                <Query
                    query={GET_USER_GQL_QUERY} variables={{ numschi, isPartner }}
                    onCompleted={(data) => {
                        console.log("MiniProfile onCompleted:");
                        console.log(data.getUser);
                    }}  >
                    {({ loading, error, data }) => {
                        if (loading) {
                            return (
                                <Spinner />
                            );
                        } else if (error) {
                            return (
                                <ErrorModal error={error} />
                            );
                        } else {
                            if (data.getUser === null || data.getUser === undefined) {
                                console.log("ChildrenProfile render no data");
                                return <div></div>;
                            } else {
                                // var prepend = "";
                                // if (process.env.NODE_ENV === 'development') {
                                //     prepend = "http://localhost:8080";
                                // }
                                const image = "" + data.getUser.photo;

                                return (
                                    <Container className="MiniProfile">
                                        <Row>
                                            {(numschi === "60a" || numschi === "60b" || this.props.isPartner) && (
                                                <CardBody>
                                                    <CardTitle className="partnerTitle">Partner</CardTitle>
                                                </CardBody>)}
                                            {(this.props.num === 0) && (
                                                <CardBody>
                                                    <CardTitle className="childrenTitle">Figli</CardTitle>
                                                </CardBody>)}
                                        </Row>
                                        <Row>
                                            <Card>
                                                <Link to={`/profile/${data.getUser.numschi}`}>
                                                    <CardBody>
                                                        <CardTitle>{data.getUser.numschi}</CardTitle>

                                                        {data.getUser.photo.length !== 0 && (<CardImg top width="100%" src={image} alt="Foto" />)}
                                                        {data.getUser.photo.length === 0 && (<CardImg top width="100%" src={squirrel} alt="Foto" />)}

                                                        <CardTitle>{data.getUser.firstName} {data.getUser.lastName}</CardTitle>
                                                        <CardSubtitle>{data.getUser.year !== -1 ? "" + data.getUser.year : ""}{data.getUser.deathYear !== -1 ? " - " + data.getUser.deathYear : ""}</CardSubtitle>
                                                    </CardBody>
                                                </Link>
                                            </Card>
                                        </Row>
                                    </Container>
                                );
                            }
                        }
                    }}
                </Query>
            </div>
        );
    }

}


