import React from 'react';
import AuthComponent from './AuthComponent';
import {
    CardImg, Container, Modal, ModalBody, ModalHeader
} from 'reactstrap';
import { Mutation } from "react-apollo";
import ModalSpinner from './ModalSpinner';
import ErrorModal from './ErrorModal';
import ReactTimeout from 'react-timeout'
const gql = require('graphql-tag')

export const GET_USER_GQL_QUERY = gql`
  query getUser($numschi: String!) {
    getUser(numschi: $numschi){
        numschi
        firstName
        lastName
        year
        marriedYear
        deathYear
        profession
        company
        phone
        email       
        street
        city
        zip
        country
        isAdmin
        photo   
    }
  }
`;

class UpdatePhoto extends AuthComponent {
    constructor(props) {
        super(props);
        this.exit = this.exit.bind(this);
        this.state = {showProgress: false };
    }
    exit() {
        this.setState({ showProgress: false })
        this.props.exitModal();
    }
    render() {
        const image = "" + this.props.photo;
        const squirrel = "https://storage.googleapis.com/clanschiratti/squirrel.jpg";
        const showform = this.props.showform ? true : false;
        const numschi = "" + this.props.numschi;

        if (showform && this.state.showProgress) {
            return (<ModalSpinner title="Sta aggiornando"/>);
        }

        return (
            <Modal className="UpdatePhoto" isOpen={showform} toggle={this.exit}>
                <ModalBody>
                    <ModalHeader toggle={this.exit}></ModalHeader>
                    <Container>
                        {(this.props.photo !== undefined && this.props.photo.length !== 0) && (<CardImg top src={image} alt="Foto" />)}
                        {(this.props.photo === undefined || this.props.photo.length === 0) && (<CardImg top src={squirrel} alt="Foto" />)}
                    </Container>
                    <Container>
                        <Mutation
                            mutation={gql`
                                mutation($numschi: String!, $file: Upload!) {
                                    uploadFile(numschi: $numschi, file: $file)
                                }
                            `}
                            refetchQueries={[
                                { query: GET_USER_GQL_QUERY, variables: { numschi: numschi } }
                            ]}
                            onCompleted={(data) => {
                                console.log("the result of photo upload:");
                                console.log(data);
                                console.log("exit in 3 seconds");
                                this.props.setTimeout(this.exit, 3000);
                                this.setState({ showProgress: true })

                            }}
                        >
                            {(uploadPhoto, { loading, error }) => {
                                if (loading) {
                                    console.log("loading: upload photo");
                                    return (<ModalSpinner title="Sta caricando"/>);
                                }

                                return (
                                    <div>
                                        {error && <ErrorModal error={error} />}

                                        <input
                                            type="file"
                                            required
                                            onChange={({
                                                target: {
                                                    validity,
                                                    files: [file]
                                                }
                                            }) => validity.valid && uploadPhoto({ variables: { numschi: numschi, file: file } })}
                                        />
                                    </div>
                                );
                            }}
                        </Mutation>
                    </Container>
                </ModalBody>
            </Modal>
        )
    }
}

export default ReactTimeout(UpdatePhoto);
