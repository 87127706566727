import React, { Component } from 'react'
import {
    Button
} from 'reactstrap';
export default class GiuseppeSchiratti extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: false
        }
    }
    showButton = () => {
        this.setState({ expanded: !this.state.expanded })
    }
    render() {
        return (
            <div><span>
                <h6>Tratto dai I RICORDI DI NINO CORRÀ - Milano, 4-2-1993</h6>
                Lo zio Bepi era un personaggio che non so se riuscirò a descrivere in poche parole.
                Ci provo perché per lui avevo un grande affetto.
                La sua caratteristica base era il dinamismo! E' una caratteristica anche di altri Schiratti.
                Mia mamma ne aveva anche una buona dose. A me piace pensare di averne ereditato un po'.
                Con questo dinamismo in corpo, da scatenare assolutamente,
                faceva sempre tanto e di tutto. <br />
                Mia mamma, che lo amava moltissimo, ci raccontava le imprese giovanili di questo suo fratello,
                il più piccolo degli Schiratti, e con una bella differenza di età da lei; anche le zie Salvioni,
                    che lo chiamavano Beppino, ricordavano le stesse cose che sono diventate per noi "storiche".<br />
                Ricordo il suo rientro a Pieve a cavallo dopo la guerra del '15-'18. Era stato chiamato alle
                armi a 17 anni (classe 1899) ed era stato di cavalleria. I salti effettuati col suo cavallo in
                piazza a Pieve dalla Loggia venivano descritti come imprese altamente rischiose; l'esame di maturità lo
                superò per meriti di guerra con ben poco studio.
                L'Università la limitò ai minimi due anni per poter ereditare la farmacia del nonno.
                Ricordo anche l'acquisto della moto e l'incidente molto grave che ne conseguì (con la assistenza
                    della mia mamma in ospedale).<br />
                Dal punto di vista del suo lavoro, ricordo le attività imprenditoriali, oltre alla farmacia;
                con i Sartori di Vittorio Veneto nel settore dei bachi da seta e quindi l'iniziativa nei prodotti
                chimici per l'enologia. Più che necessità economiche, queste attività credo gli fossero necessarie
                per la sua poca voglia a restare relegato in farmacia. In farmacia dal punto
                è stato dapprima aiutato dalla prima moglie, la povera zia Nives, poi da aiutanti stipendiati, infine
                    dal figlio Francesco.<br />
                Con la voglia di fare che aveva, e con le curiosità che pure aveva, non poteva che essere tra i primi
                in tutte le novità che si sono verificate dagli anni '20 ad oggi. Ha avuto tra i primi l'automobile a
                Pieve (poi fedeltà a Fiat); è stato tra i primi ad andare a sciare, ed io con le
                cuginette Lucia, Gina, Elena ho fatto le prime sciate con lui; è stato tra i primi a portare la famiglia,
                che cresceva ad ogni anno - 10 figli- in villeggiatura; ha comprato tra i primi una
                seconda casa a S. Boldo; ha avuto tra i primi una tenda ed ha portato così la sua famiglia in giro per
                l'Europa; ha avuto passione di collezionista tra le più varie; ha voluto e potuto vedere tutto il mondo
                    in numerosi viaggi. <br />
                Mia mamma, unica sua sorella che abitasse come lui a Pieve, era orgogliosa di quello che faceva lo zio
                e assolutamente non invidiosa. Non avevamo d'altronde le stesse possibilità economiche, ma non ricordo
                che non fare quello che faceva lo zio pesasse molto alla mamma. Andava spesso in casa e farmacia Schiratti
                a curiosare su quello che succedeva ed era molto contenta dei successi del fratello. Erano visite che
                avevano un senso quando c'era viva la
                nonna Maria, ma che poi erano proprio motivate dal fatto che si sentiva molto Schiratti.
                Anche nell'interesse della Comunità e per gli altri, il fare dello zio è stato continuo ed
                    eccezionale fino alla sua morte. <br />
                <Button onClick={this.showButton}>leggi il resto..</Button>
                {
                    this.state.expanded &&
                    <div>

                        Amava molto il suo paese e per Pieve si è prodigato
                        dedicandosi anche alla vita pubblica; per un po' di tempo è stato vicesindaco..
                        Questo nella più pura tradizione della vecchia famiglia Schiratti che di cosa pubblica si era sempre interessata.
                        Nel Municipio di Pieve c'è un busto marmoreo che ricorda il bisnonno Antonio, sindaco per trent'anni;
                        ed a Pieve c'è pure una <i>Via Schiratti</i> a ricordare un vecchio zio promotore di attività varie tra cui
                        la Latteria Sociale. <br />
                        Lo zio Bepi era cattolico di fede assoluta come mia mamma. <br />
                        Altre iniziative dello zio, da noi conosciute meglio perché descritte in opuscoli e libretti divulgativi
                        che ci inviava a Natale e perché più recenti, sono state: il patrocinio delle <i>Strade del vino</i>
                        (prime in Italia dopo le esperienze francesi), l'enoteca di casa sua, molto da centinaia di visitatori;
                        la Casa di Riposo (impegno apprezzata pesante e notevolissimo); l'interesse per la scuola di
                        disegno, i reduci, gli ex-combattenti, ecc... Non era mai entrato nelle attività sportive perché a Pieve
                        credo fossero,e siano, in mano laica.<br />
                        L'iniziativa però a noi più cara è stata l'invenzione del Clan. Dal 1960, ogni cinque anni ci siamo
                        ritrovati a Pieve tutti i parenti discendenti da Tommaso Schiratti. E lui era l'anima di tutta l'organizzazione,
                        insistendo per avere tutti, perché avrebbe avuto successo solo con tutti.
                        E siamo, mi pare 160 persone. Non so se questa iniziativa senza lui potrà ancora realizzarsi; c'è da sperare
                        nei figli Antonio e Francesco che sono rimasti a Pieve.<br />
                        Quindi, quella dello zio è stata una grande attività a fin di bene e di affetto, come è stato ricordato nella predica durante
                        la cerimonia funebre. Anche se una molla di ambizione personale ci deve pure essere stata! Chiaro
                        che coinvolgeva chi gli si presentava a tiro. Per noi ragazzi Corrà che abitavamo a Pieve c'erano sempre lavoretti da fare,
                        archivi da mettere in ordine, etichette da attaccare, per
                        cui qualche volta stavamo al largo e lo consideravamo un po' rompiballe.<br />
                        Con mio papà credo che lo zio abbia tentato più volte il coinvolgimento nelle sue iniziative, però, dato il carattere di papà,
                        con pochi risultati.<br />
                        Mio papà apprezzava lo zio Bepi per il suo altruismo, la sua disponibilità ed anche bontà, però lo giudicava un po' superficiale.<br />
                        Con le zie Salvioni, che aiutavano lo zio nei librettini che scriveva, facevano delle mezze critiche e mi ricordo qualche risatina...<br />
                        L'atteggiamento del papà era forse dettato, come dice mio fratello Ugo, che assomiglia abbastanza allo zio, da una certa incapacità
                        a muoversi con lo stesso dinamismo.<br />
                        Lo zio Bepi è stato colpito da alcune gravi disgrazie nella sua vita: la perdita del primo figlio maschio di un anno che era mio coetaneo,
                        la perdita della prima moglie, la perdita della Gina. Ha sopportato con grande fede queste batoste tremende.<br />
                        E' stato anche fortunato nel secondo matrimonio con la zia Lina che lo ha assecondato in tutte le sue attività molto intelligentemente.
                        Con la zia aveva un grande legame mio papà.<br />
                        Non so se da quanto scritto ne viene la eccezionalità della figura dello zio, ma questo suo molto agitarsi a fin di bene
                        non lo trovo in nessun altro parente, amico e conoscente. Quindi dispiace molto averlo perso, in particolare a me che,
                        essendo vissuto fino alla conclusione della Università a Pieve, a Pieve sono ancora molto legato; e lui rappresentava molto
                        in questo mio legame con il paese natio.
                        <p />
                        <h6>Articolo scritto da Raffaele Gentile</h6>
                        I miei ricordi del dott. Giuseppe Schiratti sono recenti. Ne rivedo ancor oggi, con gli occhi della mente,
                        a poche settimane dalla sua scomparsa, la figura alta ed asciutta, il volto un po' scavato dall'età, gli occhi mobilissimi.
                        A 82 anni suonati, aveva un'attività multiforme; dalla sua mente mai sazia, simile ad un vulcano benigno, le iniziative
                        scaturivano a catena e si concretavano grazia alla sua indomabile costanza.<br />
                        Non vi era idea che, nella sua finalità abbracciasse la “sua” Pieve di Soligo od il Quartier del Piave, non lo vedesse subito
                        in prima fila a dar consigli, a fornire il suo preziose appoggio materiale e di esperienza.<br />
                        Quante realizzazioni in tanti lustri di vita feconda recano scolpito il nome di Giuseppe Schlratti?
                        Molle, tante, quasi pietre miliari della vita di un uomo che ha sapulo sempre generosamente dare e ancora
                        dare seguendo una tradizione familiare che si perde nel tempo…<br />
                        Ricordo che i suoi occhi brillavano di intima felicità, quando in uno dei nostri ultimi incontri di lavoro
                        (stavamo gettando le basi per una nuova pubblicazione del dott. Giuseppe Schiratti dedicata alla Casa di riposo per
                        anziani di Pieve di Soligo dalla fondazione ai giorni nostri) mi parlava dei suoi progetti futuri; il suo animo e la
                        sua mente non albergavano nel corpo di un anziano e quest’ultimo stentava a rimanere al passo con le iniziative 
                        a getto che egli sfornava.<br />
                        Parlava, parlava il dott. Giuseppe Schiratti e le parole fluivano dalle sue labbra come acqua di fonte d’alta montagna che,
                        man mano che scende sbalzellando tra le rocce, diffonde la ineguagliabile melodia del Creato.
                        Ed io me ne rimanevo zitto ad ascoltarlo: mi sembrava un’eresia interromperlo. Alle vote il suo fluire si arrestava.
                        Mi guardava un po’ stupito, forse preso dal pensiero di avermi annoiato. Un pensiero che però lo doveva afferrare soltanto
                        per qualche istante perché subito dopo riprendeva il filo sospeso del discorso.<br />
                        Religioso, amava la famiglia in tutte le sue manifestazioni e la sua presenza era un punto di riferimento, quasi un faro di
                        luce e di esempio, per la moglie ed i figli. Ora Gluseppe Schiratti non è più. Se n'è andato quasi all'improvviso,
                        quando la primavera cominciava a riscaldare l'aria delle sue colline, quando “lui” aveva ancora tanto da fare.
                        Ricordo ancora le pacate conversazioni sulle origini della sua famiglia, sulla sua enoteca, dove aveva catalogato
                        i vini piu’ pregiati delle Tre Venezie con precisi riferimenti ai luoghi di produzione; ricordo il suo orgoglio
                        per la raccolta dei cappelli che aveva acquistato in ogni parte del mondo dove lo aveva condotto la sua sete di
                        conoscere sempre cose nuove; ricordo infine la sua ultima iniziativa di raccogliere in un museo gli arnesi che
                        erano serviti alla gente dei campi nel corso dei secoli. E qualche cosa aveva fatto: gli è mancato il tempo di
                        rendere pubblica questa sua originale idea, di “reclamizzarla”, come egli stesso soleva dire - perché dai casolari
                        più sperduti affluissero al museo gli arnesi più antichi.<br />
                        E quanto, quanto si è prodigate per le strade del vino bianco e del vino rosso… e
                        ultimamente, si rammaricava perché lo “spirito” dell'iniziativa era “saltato”, perché i turisti non frequentavano più le “botteghe del vino”.
                        Ma era soltanto rammarico perché egli era sicuro che nulla di quello che era stata seminato sarebbe andato perduto.
                        Giuseppe Schiratti non è più, ma il suo spirito è più vivo che mai nel ricordo di quanti lo conobbero,
                        di quanti ebbero la fortuna di avvicinarlo e di apprezzarne le doti di grande umiltà e di altruismo.
                        Cosi ml place ricordarlo: la figura alta e asciutta, il volto un po’ scavato dall'età, gli occhi mobilissimi e con un grande cuore.
                    </div>
                }
            </span></div>
        )
    };
}