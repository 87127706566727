import React from 'react';
import {
    Row, Col, Container,
    Card, CardImg, CardBody, CardTitle, CardText, Navbar, NavLink
} from 'reactstrap';
import AuthComponent from '../components/AuthComponent';
import '../styles/FamilyTreePage.css';
import scrollToComponent from 'react-scroll-to-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'

import TitaSchiratti from '../docs/TitaSchiratti.js';
import EmiliaCorra from '../docs/EmiliaCorra.js';
import PiaCornaPellegrini from '../docs/PiaCornaPellegrini.js';
import CarolinaSartori from '../docs/CarolinaSartori.js';
import TeresinaPolla from '../docs/TeresinaPolla.js';
import GiuseppeSchiratti from '../docs/GiuseppeSchiratti.js';

import Tita from '../images/TitaSchiratti/fullsize.png';
import Emilia from '../images/EmiliaCorra/fullsize.png';
import Carolina from '../images/CarolinaSartori/fullsize.png';
import Pia from '../images/PiaCornaPellegrini/fullsize.png';
import Teresina from '../images/TeresinaPolla/fullsize.png';
import Giuseppe from '../images/GiuseppeSchiratti/fullsize.png';

//foto di Emilia
import ritrattoE from '../images/EmiliaCorra/ritratto.jpg';
import famigliaE from '../images/EmiliaCorra/famiglia.jpg';
import figliE from '../images/EmiliaCorra/figli.jpg';
//foto di pia
import ritrattoP from '../images/PiaCornaPellegrini/ritratto.jpg';
import coppiaP from '../images/PiaCornaPellegrini/coppia.jpg';
import famigliaP from '../images/PiaCornaPellegrini/famiglia.jpg';
import figliP from '../images/PiaCornaPellegrini/figli.jpg';
//foto Carolina
import ritrattoC from '../images/CarolinaSartori/ritratto.jpg';
//foto di teresina
import ritrattoT from '../images/TeresinaPolla/ritratto.jpg';
import coppiaT from '../images/TeresinaPolla/coppia.jpg';
import famigliaT from '../images/TeresinaPolla/famiglia.jpg';
//foto di giuseppe
import ritrattoG from '../images/GiuseppeSchiratti/ritratto.jpeg';
import coppiaG from '../images/GiuseppeSchiratti/coppia.jpeg';
import coppiaGLina from '../images/GiuseppeSchiratti/coppiaGLina.jpg';
import famigliaG from '../images/GiuseppeSchiratti/famiglia1.jpeg';
import famiglia2G from '../images/GiuseppeSchiratti/famiglia2.jpeg';
import figliG from '../images/GiuseppeSchiratti/figli.jpg';

export default class GrandParentsPage extends AuthComponent {
    render() {
        return (
            <Container className="LinkPage">
                <div className='Top' ref={(div) => { this.TopSection = div; }}></div>
                <h4>I NOSTRI NONNI SCHIRATTI</h4>
                <h6>
                    <span>I nonni Schiratti erano i capostipidi di tutti noi.
                        Da loro e dai loro consorti, siamo nati tutti noi.<br />
                        Il Clan Schiratti e' composto da tutti gli appartenenti di sangue ed acquisiti di ognuna delle loro famiglie.
                        I fratelli Schiratti, dal piu' vecchio al piu' giovane, erano:
                    </span>
                </h6>
                <Navbar bsstyle="tabs" style={{ justifyContent: 'flex-start' }}>
                    <NavLink
                        className="scrolltoTita"
                        onClick={() => scrollToComponent(this.TitaSection, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}>
                        Giovanni Battista Schiratti
                    </NavLink>
                    <NavLink
                        className="scrolltoEmilia"
                        onClick={() => scrollToComponent(this.EmiliaSection, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}>
                        Emilia Schiratti in Corrà
                    </NavLink>
                    <NavLink
                        className="scrolltoPia"
                        onClick={() => scrollToComponent(this.PiaSection, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}>
                        Pia Schiratti in Corna Pellegrini
                    </NavLink>
                    <NavLink
                        className="scrolltoCarolina"
                        onClick={() => scrollToComponent(this.CarolinaSection, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}>
                        Carolina Schiratti in Sartori
                    </NavLink>
                    <NavLink
                        className="scrolltoTeresina"
                        onClick={() => scrollToComponent(this.TeresinaSection, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}>
                        Teresina Schiratti in Polla
                    </NavLink>
                    <NavLink
                        className="scrolltoGiuseppe"
                        onClick={() => scrollToComponent(this.GiuseppeSection, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}>
                        Giuseppe Schiratti
                    </NavLink>
                </Navbar>
                <Row>
                    <Row className="grandparents-row">
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardImg top width="100%" src={Tita} alt="Card image cap" />
                            </Card>
                        </Col>
                        <Col sm={{ size: 8, offset: 0.1 }}>
                            <Card>
                                <CardBody>
                                    <div className='Tita' ref={(div) => { this.TitaSection = div; }}>
                                        <NavLink className="scrolltoTop" onClick={() => scrollToComponent(this.TopSection, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}>
                                            Ritorna <FontAwesomeIcon icon={faAngleUp} />
                                        </NavLink>
                                        <CardTitle>GIOVANNI BATTISTA SCHIRATTI</CardTitle>
                                        <CardText><TitaSchiratti></TitaSchiratti></CardText>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="grandparents-row">
                        <Col sm={{ size: 8 }}>
                            <Card>
                                <CardBody>
                                    <div className='Emilia' ref={(div) => { this.EmiliaSection = div; }}>
                                        <NavLink className="scrolltoTop" onClick={() => scrollToComponent(this.TopSection, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}>
                                            Ritorna <FontAwesomeIcon icon={faAngleUp} />
                                        </NavLink>
                                        <CardTitle>EMILIA SCHIRATTI IN CORRÀ</CardTitle>
                                        <CardText><EmiliaCorra></EmiliaCorra></CardText>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto', offset: 0.1 }}>
                            <Card>
                                <CardImg top width="100%" src={Emilia} alt="Emilia" />
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto' }}>
                            <Card style={{width:"50%"}}>
                                <CardBody>
                                    <CardTitle>Emilia Corrà</CardTitle>
                                    <CardImg top width="100%" src={ritrattoE} alt="Emilia" />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Famiglia Corrà</CardTitle>
                                    <CardText>Nanny, Ugo, Amalia, Maria Laura, Luisella, Sebastiano(Nino)</CardText>
                                    <CardText>Emilio, Ugo Junior, Tjibbo, Emilia, Francesco(Checchi), Vittorio, Francesco, Laura</CardText>
                                    <CardImg top width="100%" src={famigliaE} alt="Famiglia Corrà" />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Famiglia Ugo Corrà</CardTitle>
                                    <CardText>Natale 2015</CardText>
                                    <CardImg top width="100%" src={figliE} alt="Figli Corrà" />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="grandparents-row">
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardImg top width="100%" src={Pia} alt="Card image cap" />
                            </Card>
                        </Col>
                        <Col sm={{ size: 8, offset: 0.1 }}>
                            <Card>
                                <CardBody>
                                    <div className='Pia' ref={(div) => { this.PiaSection = div; }}>
                                        <NavLink className="scrolltoTop" onClick={() => scrollToComponent(this.TopSection, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}>
                                            Ritorna <FontAwesomeIcon icon={faAngleUp} />
                                        </NavLink>
                                        <CardTitle>PIA SCHIRATTI IN CORNA PELLEGRINI</CardTitle>
                                        <CardText><PiaCornaPellegrini></PiaCornaPellegrini></CardText>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Pia Corna Pellegrini</CardTitle>
                                    <CardImg top width="100%" src={ritrattoP} alt="Pia Corna Pellegrini" />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Pia e Camillo Corna Pellegrini</CardTitle>
                                    <CardImg top width="100%" src={coppiaP} alt="Coppia Corna Pellegrini" />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Maria Teresa, Maria Rosa, Piero, Ginevra</CardTitle>
                                    <CardTitle>Camillo, Margherita, Pia, Giacomo</CardTitle>
                                    <CardImg top width="100%" src={famigliaP} alt="Famiglia Corna Pellegrini" />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardBody>
                                    <CardTitle> Maria Rosa Polettini, Piero Corna Pellegrini</CardTitle>
                                    <CardTitle>Margherita Radaelli, Maria Teresa Zanettin, Ginevra Brandani, Giacomo Corna Pellegrini</CardTitle>
                                    <CardImg top width="100%" src={figliP} alt="Figli Corna Pellegrini" />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="grandparents-row">
                        <Col sm={{ size: 8 }}>
                            <Card>
                                <CardBody>
                                    <div className='Carolina' ref={(div) => { this.CarolinaSection = div; }}>
                                        <NavLink className="scrolltoTop" onClick={() => scrollToComponent(this.TopSection, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}>
                                            Ritorna <FontAwesomeIcon icon={faAngleUp} />
                                        </NavLink>
                                        <CardTitle>CAROLINA SCHIRATTI IN SARTORI</CardTitle>
                                        <CardText><CarolinaSartori></CarolinaSartori></CardText>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto', offset: 0.1 }}>
                            <Card>
                                <CardImg top width="100%" src={Carolina} alt="Carolina Sartori" />
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Carolina Sartori</CardTitle>
                                    <CardImg top src={ritrattoC} alt="Carolina Sartori" />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="grandparents-row">
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardImg top width="100%" src={Teresina} alt="Card image cap" />
                            </Card>
                        </Col>
                        <Col sm={{ size: 8, offset: 0.1 }}>
                            <Card>
                                <CardBody>
                                    <div className='Teresina' ref={(div) => { this.TeresinaSection = div; }}>
                                        <NavLink className="scrolltoTop" onClick={() => scrollToComponent(this.TopSection, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}>
                                            Ritorna <FontAwesomeIcon icon={faAngleUp} />
                                        </NavLink>
                                        <CardTitle>TERESINA SCHIRATTI IN POLLA</CardTitle>
                                        <CardText><TeresinaPolla></TeresinaPolla></CardText>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardTitle>Teresina Polla</CardTitle>
                                <CardBody>
                                    <CardImg top width="100%" src={ritrattoT} alt="Ritratto Teresina Polla" />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Teresina e Ruggero Polla</CardTitle>
                                    <CardImg top width="100%" src={coppiaT} alt="Coppia Polla" />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Ruggero, Giovanni, Maria, Teresina, Agnese</CardTitle>
                                    <CardImg top width="100%" src={famigliaT} alt="Famiglia Polla" />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="grandparents-row">
                        <Col sm={{ size: 8 }}>
                            <Card>
                                <CardBody>
                                    <div className='Giuseppe' ref={(div) => { this.GiuseppeSection = div; }}>
                                        <NavLink className="scrolltoTop" onClick={() => scrollToComponent(this.TopSection, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}>
                                            Ritorna <FontAwesomeIcon icon={faAngleUp} />
                                        </NavLink>
                                        <CardTitle>GIUSEPPE SCHIRATTI</CardTitle>
                                        <CardText><GiuseppeSchiratti></GiuseppeSchiratti></CardText>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto', offset: 0.1 }}>
                            <Card>
                                <CardImg top width="100%" src={Giuseppe} alt="Giuseppe Schiratti" />
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Giuseppe Schiratti</CardTitle>
                                    <CardImg top width="100%" src={ritrattoG} alt="Coppia Schiratti" />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Giuseppe Schiratti e Nives Zanier</CardTitle>
                                    <CardImg top width="100%" src={coppiaG} alt="Coppia Schiratti" />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Giuseppe Schiratti e Elena De Vittor (Nonna Lina)</CardTitle>
                                    <CardImg top width="100%" src={coppiaGLina} alt="Coppia Schiratti" />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Nives, Elena, Giuseppe, Emilietta, Teresina, Luisa, Mariolina, Lucia</CardTitle>
                                    <CardImg top width="100%" src={famigliaG} alt="Famiglia Schiratti" />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Franco, Giuseppe, Antonio, Teresina, Nives</CardTitle>
                                    <CardTitle>Lucia, Luisa, Elena, Mariolina</CardTitle>
                                    <CardImg top width="100%" src={famiglia2G} alt="Famiglia Schiratti" />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={{ size: 'auto' }}>
                            <Card>
                                <CardBody>
                                    <CardTitle>Mariolina Schiratti, Giorgio Vaglieri, Mariuccia Pavan,
                                            Romano Ricci, Livio Allegri, Emilietta Schiratti, Guido Salvagnini,
                                    Carla Belluco, Anna Schiratti</CardTitle>
                                    <CardTitle>Luisa Schiratti, Antonio Schiratti, Franco Schiratti,
                                    Lucia Schiratti, Elena Schiratti, Cleto Zonin, Cristina Schiratti</CardTitle>
                                    <CardImg top width="100%" src={figliG} alt="Card image cap" />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Row>
            </Container>
        );
    }
}