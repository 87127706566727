import React from 'react'
import AuthComponent from '../components/AuthComponent'
import {
  Container,
  Card, CardBody,
  CardTitle, CardSubtitle,
  InputGroup, InputGroupAddon, InputGroupText, Input
} from 'reactstrap';
import '../styles/Statistics.css';

import { VictoryPie } from 'victory';

const familyColor = ["FFFFFF", "#E38627", "#C13C37", "#6A2135", "#447295", "#2D5067", "#999999"];
const ancestors = ['Tita', 'Emilia', 'Pia', 'Carolina', 'Teresina', 'Giuseppe']

export default class StatFamilyPie extends AuthComponent {

  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      count: [0, 0, 0, 0, 0, 0],
      countAlive: [0, 0, 0, 0, 0, 0],
      total: 0,
      totalAlive: 0,
      totalD: 0,
      pieData: [
        { x: '0', y: 100, label: '  ' },
        { x: '1', y: 0, label: ancestors[0] },
        { x: '2', y: 0, label: ancestors[1] },
        { x: '3', y: 0, label: ancestors[2] },
        { x: '4', y: 0, label: ancestors[3] },
        { x: '5', y: 0, label: ancestors[4] },
        { x: '6', y: 0, label: ancestors[5] }
      ]
    }

    this.handleClick = this.handleClick.bind(this);
    this.processData = this.processData.bind(this);
    this.processData(this.props.users);
  }

  processData(users) {
    for (var i = 0; i < users.length; i++) {
      let ancestor = users[i].numschi[0] - 1;
      this.state.count[ancestor]++;
      this.state.total++;
      if (users[i].alive) {
        this.state.countAlive[ancestor]++;
        this.state.totalAlive++;
      }
    }
  }

  componentDidMount() {
    this.setState({
      pieData: [
        { x: '0', y: 0, label: '  ' },
        { x: '1', y: this.state.count[0]},
        { x: '2', y: this.state.count[1]},
        { x: '3', y: this.state.count[2]},
        { x: '4', y: this.state.count[3]},
        { x: '5', y: this.state.count[4]},
        { x: '6', y: this.state.count[5]}
      ],
      totalD: this.state.total
    })
  }

  handleClick(event) {
    if (event === undefined) return;
    const target = event.target;
    if (target.name === "senzaDeceduti") {
      if (target.checked === true) {
        this.setState({
          pieData: [
            { x: '0', y: 0, label: '  ' },
            { x: '1', y: this.state.countAlive[0]},
            { x: '2', y: this.state.countAlive[1]},
            { x: '3', y: this.state.countAlive[2]},
            { x: '4', y: this.state.countAlive[3]},
            { x: '5', y: this.state.countAlive[4]},
            { x: '6', y: this.state.countAlive[5]}
          ],
          totalD: this.state.totalAlive
        })
      } else {
        this.setState({
          pieData: [
            { x: '0', y: 0, label: '  ' },
            { x: '1', y: this.state.count[0]},
            { x: '2', y: this.state.count[1]},
            { x: '3', y: this.state.count[2]},
            { x: '4', y: this.state.count[3]},
            { x: '5', y: this.state.count[4]},
            { x: '6', y: this.state.count[5]}
          ],
          totalD: this.state.total
        })
      }
    }
  }

  render() {
    return (
      <Container className="Statistics">
        <Card>
          <CardBody>
            <CardTitle>Quanti siamo nelle varie famiglie? In totale siamo {this.state.totalD} </CardTitle>
            <CardSubtitle>Distribuzione di parenti nelle sei discendenze</CardSubtitle>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <Input addon onClick={(e) => { this.handleClick(e) }} type="checkbox" name="senzaDeceduti" aria-label="Checkbox per senza deceduti" />
                </InputGroupText>
              </InputGroupAddon>
              <InputGroupText>Senza i deceduti</InputGroupText>
            </InputGroup>
            <VictoryPie
              animate={{duration: 1000, easing: "circle"}}
              startAngle={90}
              endAngle={450}
              colorScale={familyColor}
              data={this.state.pieData}
              labelRadius={180}
              labels={({ datum }) => String(ancestors[parseInt(datum.x)-1]) +':'+ String(Math.round(datum.y)) +', ' + String(Math.round(datum.y / this.state.total * 100)) +'%'}
            />
          </CardBody>
        </Card>
      </Container>
    )
  }
}